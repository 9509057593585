import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {

    gamificationLoading: false,
    gamification: null,
    gamificationError: false,

   

}

//gamification
const gamificationStart = (state, action) => {
    return updateObject(state, { gamificationError: false, gamificationLoading: true });

}

const gamificationSuccess = (state, action) => {

    return updateObject(state, { gamification: action.data, gamificationLoading: false  });

}

const gamificationFail = (state, action) => {
    return updateObject(state, { gamificationError: action.error });

}


const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GAMIFICATION_START:
            return gamificationStart(state, action)
        case actionTypes.GAMIFICATION_SUCCESS:
            return gamificationSuccess(state, action)
        case actionTypes.GAMIFICATION_FAIL:
            return gamificationFail(state, action)

       

        default:
            return state;
    }
}

export default reducer;
