import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Card, Modal, Result } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/images/valpi.png';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../Resusable/Notification/index';

import './signin.css';

const ValpiSignIn = (props) => {
    const [loading, setLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)

    const handleOk = e => {
        setShowMessage(false);


    };

    const handleCancel = e => {
        setShowMessage(false);
        props.history.push('/');


    };
    const onFinish = values => {
        setLoading(true)
        let credentials = {};
        let serachStr = '';
        if (props.location.search) {
            serachStr = props.location.search;
            let [nothing, resultStr] = serachStr.split('?');
            let [assistkey] = resultStr.split('/');

            assistkey = assistkey.replace('assist_key=', '')

            credentials = {
                email: values.email,
                password: values.password,
                auth: 'citymaas',
                assistkey: assistkey,
            };
        } 

        props.onValpiAuth(credentials, res => {
            if (res.status === 200) {
                setShowMessage(true)
                setLoading(false)

            } else if (res.status === 777) {
                Notification('info', res.message);
                setLoading(false)


            } else {
                Notification('error', res.message);
                setLoading(false)

            }
        });
    };


    return (
        <div className='sign-in-bg'>
            <div className='sign-in-div'>
                <div className='sign-in-img'>
                   
                    <Card className='sign-in-card'>
                    <div style={{display:'flex', width:'100%', justifyContent:'center', marginBottom:20}}>
                        <img src={logo} width={120} alt='' />
                    </div>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            labelAlign='left'
                            layout='vertical'
                        >
                            <Form.Item
                                label='Email'
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                label='Password'
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>

                                <Link className="login-form-forgot" to="/forgotPassword">
                                    Forgot password
                                </Link>
                            </Form.Item>

                            <Form.Item>
                                <div className='sign-in-div  mr-b-10' >
                                    <Button type="primary" htmlType="submit" className="sign-in-button" loading={loading}>
                                        Log in
                                </Button>
                                </div>


                            </Form.Item>
                        </Form>
                    </Card>

                    <Modal
                        visible={showMessage}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        <Result
                            status="success"
                            title="Successfully Validated"
                            subTitle="Please use your app to start your travel"
                            extra={[
                                <Button type="link" key="console" href='https://www.citymaas.io/white-label-platform/'>
                                   Visit our Website
                                </Button>,
                            ]}
                        />
                    </Modal>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.email !== null,



    }
}
const mapDispatchToProps = dispatch => {
    return {
        onValpiAuth: (credentials, res) => dispatch(actions.authValpi(credentials, res)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ValpiSignIn);