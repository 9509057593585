import React, { useState } from 'react';
import { Form, Input, Button, Radio, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/images/citymaasai1.png';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../Resusable/Notification/index';
import { withRouter } from 'react-router-dom'

import './signin.css';

const ResetPassword = (props) => {
    const [loading, setLoading] = useState(false)
    const [bus, setBus] = useState('Individual')

    const onFinish = values => {
        setLoading(true)

        const credentials = {
            email: values.email,
            password: values.password,
            bus:bus
        };

        props.resetProfile(credentials, res => {
            if (res.status === 200) {
                props.history.push('/sign-in');
                setLoading(false)


            } else if (res.status === 777) {
                Notification('error', res.message);
                setLoading(false)



            } else {
                Notification('error', res.message);
                setLoading(false)

            }
        });
    };

    const onChangeBusiness = e => {
        console.log('radio checked', e.target.value);
        setBus(e.target.value)
      }
    return (
        <div className='sign-in-bg'>
            <div className='sign-in-div'>
                <div className='sign-in-img'>
                    <div style={{ width: '500px', height: '270px' }}>
                        <img src={logo} width={500} alt='' />
                    </div>
                    <Card className='sign-in-card'>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true, email: props.location.state.email }}
                            onFinish={onFinish}
                            labelAlign='left'
                            layout='vertical'
                        >
                            <Form.Item
                                label='Email'
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                label='Password'
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item
                                label='Please select your status'
                            >
                            <Radio.Group onChange={onChangeBusiness} value={bus}>
                                <Radio value='Individual'>Individual</Radio>
                                <Radio value='Business'>Business</Radio>
                            </Radio.Group>
                            </Form.Item>

                            <Form.Item>
                                <div className='sign-in-div  mr-b-10' >
                                    <Button type="primary" htmlType="submit" className="sign-in-button" loading={loading}>
                                        Reset Password
                                </Button>
                                </div>
                                Don't have an account? <Link to="/sign-up">Sign Up!</Link>

                                <div>
                                    Or <Link to="/">Go Back</Link>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.email !== null,


    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetProfile: (credentials, res) => dispatch(actions.resetProfile(credentials, res))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));