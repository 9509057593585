import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    dataCollectionLoading: false,
    dataCollection: null,
    dataCollectionError: false,

    disability: []

}

const dataCollectionStart = (state, action) => {
    return updateObject(state, { dataCollectionError: false, dataCollectionLoading: true });

}

const dataCollectionSuccess = (state, action) => {

    return updateObject(state, { dataCollection: action.data });

}

const dataCollectionFail = (state, action) => {
    return updateObject(state, { dataCollectionError: action.error });

}


//


const setDisability = (state, action) => {
    return updateObject(state, { disability: action.data });

}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.DATA_COLLECTION_START:
            return dataCollectionStart(state, action)
        case actionTypes.DATA_COLLECTION_SUCCESS:
            return dataCollectionSuccess(state, action)
        case actionTypes.DATA_COLLECTION_FAIL:
            return dataCollectionFail(state, action)

        case actionTypes.SET_DISABILITY:
            return setDisability(state, action)
        default:
            return state;
    }
}

export default reducer;