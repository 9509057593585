
import * as actionTypes from './actionTypes';
import axios from 'axios';
import config from '../../config';
import querystring from 'querystring';

export const profileStart = (data) => {

    return {
        type: actionTypes.PROFILE_START,
        data: data
    };
}

export const profileSuccess = (data) => {
    return {
        type: actionTypes.PROFILE_SUCCESS,
        data: data
    }
}

export const profileFail = (error) => {
    return {
        type: actionTypes.PROFILE_FAIL,
        error: error
    }
}

export const profile = (obj, callback) => {
    return dispatch => {
        dispatch(profileStart(true));
        const authData = {
            data: {
                email: obj.email,
                password: obj.password,
                user_mobile: obj.phoneInput,
                name_of_user: obj.name,
                source: 'citymaas',
                age: obj.age,
                emergency_contact: obj.emerConInput,
                user_disability:obj.dis,
            }}

      
        axios.post(`${config.userURL}/profileupdate`, authData)
            .then(response => {
                dispatch(profileStart(true));
                dispatch(profileSuccess(response.data));
                dispatch(profileStart(false));

                callback(response.data);
            })
            .catch(err => {
                console.log(err);
                dispatch(profileFail(err));
                callback({ status: 2 });
                dispatch(profileStart(false));


            })
    }
}


//get user



export const getProfileFailure = (error) => {
    return {
        type: actionTypes.GET_PROFILE_FAILURE,
        error: error
    }
}

export const getProfileSuccess = (data) => {
    return {
        type: actionTypes.GET_PROFILE_SUCCESS,
        data: data
    }
}

export const getProfileLoading = (data) => {
    return {
        type: actionTypes.GET_PROFILE_LOADING,
        data: data
    };
}

export const getProfile = (callback) => {
    return (dispatch) => {
        dispatch(getProfileLoading(true));
        const authData = {
            data :{
            email: sessionStorage.getItem('email'),
        }
        }

        return axios.post(`${config.adminApi}/citymaas/website/profilefetch`, authData
        )
            .then((response) => {
                dispatch(getProfileSuccess(response.data.data));
                dispatch(getProfileLoading(true));
                callback(response.data);
                dispatch(getProfileLoading(false));
                sessionStorage.setItem('user_disability', response.data.data.user_disability);

            })
            .catch((err) => {
                    dispatch(getProfileLoading(false));
                    dispatch(getProfileFailure(err));
                    callback(false);
            })
    }
}


//get businesss


//get user



export const getProfileBusFailure = (error) => {
    return {
        type: actionTypes.GET_PROFILE_BUS_FAILURE,
        error: error
    }
}

export const getProfileBusSuccess = (data) => {
    return {
        type: actionTypes.GET_PROFILE_BUS_SUCCESS,
        data: data
    }
}

export const getProfileBusLoading = (data) => {
    return {
        type: actionTypes.GET_PROFILE_BUS_LOADING,
        data: data
    };
}

export const getProfileBus = (callback) => {
    return (dispatch) => {
        dispatch(getProfileBusLoading(true));
       
        var bodyFormData = new FormData();
        bodyFormData.set('business_email', sessionStorage.getItem('email'));
        return axios.post(`${config.adminApi}/business/profilefetch`, bodyFormData 
        )
            .then((response) => {
                dispatch(getProfileBusSuccess(response.data.data));
                dispatch(getProfileBusLoading(true));
                callback(response.data);
                dispatch(getProfileBusLoading(false));
            })
            .catch((err) => {
                    dispatch(getProfileBusLoading(false));
                    dispatch(getProfileBusFailure(err));
                    callback(false);
            })
    }
}


//gamm profile





export const getProfileGammFailure = (error) => {
    return {
        type: actionTypes.GET_PROFILE_GAMM_FAILURE,
        error: error
    }
}

export const getProfileGammSuccess = (data) => {
    return {
        type: actionTypes.GET_PROFILE_GAMM_SUCCESS,
        data: data
    }
}

export const getProfileGammLoading = (data) => {
    return {
        type: actionTypes.GET_PROFILE_GAMM_LOADING,
        data: data
    };
}

export const getProfileGamm = (callback) => {
    return (dispatch) => {
        dispatch(getProfileGammLoading(true));
        const authData = {
            data :{
            user_email: sessionStorage.getItem('email'),
        }
        }

        return axios.post(`${config.adminApi}/usergamificationfetch`, authData
        )
            .then((response) => {
                dispatch(getProfileGammSuccess(response.data.data));
                dispatch(getProfileGammLoading(true));
                callback(response.data);
                dispatch(getProfileGammLoading(false));
                sessionStorage.setItem('user_disability', response.data.data.user_disability);

            })
            .catch((err) => {
                    dispatch(getProfileGammLoading(false));
                    dispatch(getProfileGammFailure(err));
                    callback(false);
            })
    }
}



//chart


export const getChartFailure = (error) => {
    return {
        type: actionTypes.GET_CHART_FAILURE,
        error: error
    }
}

export const getChartSuccess = (data) => {
    return {
        type: actionTypes.GET_CHART_SUCCESS,
        data: data
    }
}

export const getChartLoading = (data) => {
    return {
        type: actionTypes.GET_CHART_LOADING,
        data: data
    };
}

export const getChart = (obj, callback) => {
    return (dispatch) => {
        dispatch(getChartLoading(true));
        var bodyFormData = new FormData();
        bodyFormData.set('business_email', sessionStorage.getItem('email'));
        bodyFormData.append('location_category', obj);

        return axios.post(`${config.adminApi}/business/chartingmetrics`, bodyFormData
        )
            .then((response) => {
                dispatch(getChartSuccess(response.data.data));
                dispatch(getChartLoading(true));
                callback(response.data);
                dispatch(getChartLoading(false));
              
            })
            .catch((err) => {
                    dispatch(getChartLoading(false));
                    dispatch(getChartFailure(err));
                    callback(false);
            })
    }
}


//bus profile update


export const busProfileStart = (data) => {

    return {
        type: actionTypes.BUS_PROFILE_START,
        data: data
    };
}

export const busProfileSuccess = (data) => {
    return {
        type: actionTypes.BUS_PROFILE_SUCCESS,
        data: data
    }
}

export const busProfileFail = (error) => {
    return {
        type: actionTypes.BUS_PROFILE_FAIL,
        error: error
    }
}

export const busProfile = (objForm, callback) => {
    return dispatch => {
        dispatch(busProfileStart(true));
      
        axios.post(`${config.adminApi}/business/profileupdate`, objForm, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {
                dispatch(busProfileStart(true));
                dispatch(busProfileSuccess(response.data));
                dispatch(busProfileStart(false));

                callback(response.data);
            })
            .catch(err => {
                console.log(err);
                dispatch(busProfileFail(err));
                callback({ status: 2 });
                dispatch(busProfileStart(false));


            })
    }
}


//chart


export const getGBusFailure = (error) => {
    return {
        type: actionTypes.GET_GBUS_FAILURE,
        error: error
    }
}

export const getGBusSuccess = (data) => {
    return {
        type: actionTypes.GET_GBUS_SUCCESS,
        data: data
    }
}

export const getGBusLoading = (data) => {
    return {
        type: actionTypes.GET_GBUS_LOADING,
        data: data
    };
}

export const getGBus = (callback) => {
    return (dispatch) => {
        dispatch(getGBusLoading(true));
        
        return axios.get(`${config.adminApi}/business/listing`
        )
            .then((response) => {
                dispatch(getGBusSuccess(response.data));
                dispatch(getGBusLoading(true));
                callback(response.data);
                dispatch(getGBusLoading(false));
              
            })
            .catch((err) => {
                    dispatch(getGBusLoading(false));
                    dispatch(getGBusFailure({ message: err.response.data.message} ));
                    callback(false);
            })
    }
}

