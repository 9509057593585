import React, { useState, useEffect } from 'react';
import { Redirect, Route, Switch, BrowserRouter as Router, withRouter } from 'react-router-dom';
import App from './App';
import SignUp from './components/SignUp/SignUp'
import SignIn from './components/SignIn/SignIn'
import Logout from './components/SignIn/Logout/Logout'
import ForgotPassword from './components/ForgotPassword/ForgotPassword';
import ResetPassword from './components/SignIn/ResetPassword'
import ValpiSignIn from './components/SignIn/ValpiSignIn';
import ValpiResetPassword from './components/SignIn/ValpiResetPassword';

const Routes = (props) => {

    let routes = (
        <Switch>
            <Route exact path='/logout' component={Logout} />
            <Route exact path='/sign-up' component={SignUp} />
            <Route exact path="/sign-in" component={SignIn} />
            <Route exact path="/valpi/sign-in" component={ValpiSignIn} />
            <Route exact path="/forgotPassword" component={ForgotPassword} />
            <Route exact path='/reset-profile' render={({ match }) => <ResetPassword match={match} />}/>
            <Route exact path='/valpi/reset-profile' render={({ match }) => <ValpiResetPassword match={match} />}/>

            <Route path="/" component={App} />



            <Redirect to="/signin" />
        </Switch>

    )

    


    return (
        <Router>
            {routes}
        </Router>
    )
}

export default withRouter(Routes)