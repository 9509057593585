import * as actionTypes from "./actionTypes";
import axios from "axios";
import config from "../../config";

//get

export const getLocationFailure = (error) => {
  return {
    type: actionTypes.GET_LOCATION_FAILURE,
    error: error,
  };
};

export const getLocationSuccess = (data) => {
  return {
    type: actionTypes.GET_LOCATION_SUCCESS,
    data: data,
  };
};

export const getLocationLoading = (data) => {
  return {
    type: actionTypes.GET_LOCATION_LOADING,
    data: data,
  };
};

export const getLocation = (data, callback) => async (dispatch) => {
  dispatch(getLocationLoading(true));
  try {
    if (data.split(".").length - 1 === 2) {
      let what3words = await axios.get(
        `${config.what3wordstoCords}&words=${data}&format=json`
      );

      let mapboxapi = await axios.get(
        `${config.baseApiUrl}/geocoding/v5/mapbox.places/${what3words.data.coordinates.lat},${what3words.data.coordinates.lng}.json?access_token=${config.token}`
      )
      console.log(mapboxapi)
      let place_name = mapboxapi.data && mapboxapi.data.features[0].place_name
      let result = {...what3words.data, place_name}

      dispatch(getLocationSuccess(result));
      dispatch(getLocationLoading(true));
      callback(result);
      dispatch(getLocationLoading(false));
    } else {
      const response = await axios.get(
        `${config.baseApiUrl}/geocoding/v5/mapbox.places/${data}.json?types=address,country,region,postcode,district,place,locality,neighborhood,poi&proximity=-0.118092,51.509865&access_token=${config.token}&autocomplete=true`
      );

      let promises = response.data.features.map((cord) => {
        console.log(cord.geometry.coordinates);
        return axios.get(
          `${config.what3wordstoWords}&coordinates=${cord.geometry.coordinates[1]},${cord.geometry.coordinates[0]}&language=en&format=json`
        );
      });

      let result = [];

      Promise.all(promises).then((res) => {
        result = response.data.features.map((cord, index) => {
          let word = res[index].data.words;
          return { ...cord, word };
        });

        callback(result);

        dispatch(getLocationSuccess(result));
        dispatch(getLocationLoading(true));
        dispatch(getLocationLoading(false));
      });
    }
  } catch (e) {
    dispatch(getLocationLoading(false));
    dispatch(getLocationFailure(e));
    callback(false);
  }
};

//get buss

export const getBusFailure = (error) => {
  return {
    type: actionTypes.GET_BUS_FAILURE,
    error: error,
  };
};

export const getBusSuccess = (data) => {
  return {
    type: actionTypes.GET_BUS_SUCCESS,
    data: data,
  };
};

export const getBusLoading = (data) => {
  return {
    type: actionTypes.GET_BUS_LOADING,
    data: data,
  };
};

export const getBus = (data, callback) => {
  return (dispatch) => {
    dispatch(getBusLoading(true));
    return axios
      .get(
        `${config.adminApi}/get_nearest_bus_stations?lat=${data.lat}&lon=${data.lng}`
      )
      .then((response) => {
        dispatch(getBusSuccess(response.data));
        dispatch(getBusLoading(true));
        callback(response.data);
        dispatch(getBusLoading(false));
      })
      .catch((err) => {
        dispatch(getBusLoading(false));
        dispatch(getBusFailure(err));
        callback(false);
      });
  };
};

//get train

export const getTrainFailure = (error) => {
  return {
    type: actionTypes.GET_TRAIN_FAILURE,
    error: error,
  };
};

export const getTrainSuccess = (data) => {
  return {
    type: actionTypes.GET_TRAIN_SUCCESS,
    data: data,
  };
};

export const getTrainLoading = (data) => {
  return {
    type: actionTypes.GET_TRAIN_LOADING,
    data: data,
  };
};

export const getTrain = (data, callback) => {
  return (dispatch) => {
    dispatch(getTrainLoading(true));
    return axios
      .get(
        `${config.adminApi}/train_accessibility_layer?lat=${data.lat}&lon=${data.lng}`
      )
      .then((response) => {
        dispatch(getTrainSuccess(response.data));
        dispatch(getTrainLoading(true));
        callback(response.data);
        dispatch(getTrainLoading(false));
      })
      .catch((err) => {
        dispatch(getTrainLoading(false));
        dispatch(getTrainFailure(err));
        callback(false);
      });
  };
};

//get poi

export const getPoiFailure = (error) => {
  return {
    type: actionTypes.GET_POI_FAILURE,
    error: error,
  };
};

export const getPoiSuccess = (data) => {
  return {
    type: actionTypes.GET_POI_SUCCESS,
    data: data,
  };
};

export const getPoiLoading = (data) => {
  return {
    type: actionTypes.GET_POI_LOADING,
    data: data,
  };
};

export const getPoi = (data, callback) => {
  return (dispatch) => {
    if (data.category) {
      dispatch(getPoiLoading(true));
      var bodyFormData = new FormData();
      bodyFormData.set("latitude", data.lat);
      bodyFormData.append("longitude", data.lng);
      return axios
        .post(`${config.adminApi}/categorylayer`, bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response) => {
          dispatch(getPoiSuccess(response.data));
          dispatch(getPoiLoading(true));
          callback(response.data);
          dispatch(getPoiLoading(false));
        })
        .catch((err) => {
          dispatch(getPoiLoading(false));
          dispatch(getPoiFailure(err));
          callback(false);
        });
    } else {
      dispatch(getPoiLoading(true));
      return axios
        .get(
          `${config.adminApi}/get_place_info?lat=${data.lat}&lon=${data.lng}`
          // return axios.get(`https://7cea66600db5.ngrok.io/get_place_info?lat=${data.lat}&lon=${data.lng}`,
        )
        .then((response) => {
          dispatch(getPoiSuccess(response.data));
          dispatch(getPoiLoading(true));
          callback(response.data);
          dispatch(getPoiLoading(false));
        })
        .catch((err) => {
          dispatch(getPoiLoading(false));
          dispatch(getPoiFailure(err));
          callback(false);
        });
    }
  };
};

//saved placess

export const addSavedFailure = (error) => {
  return {
    type: actionTypes.ADD_SAVED_FAILURE,
    error: error,
  };
};

export const addSavedSuccess = (data) => {
  return {
    type: actionTypes.ADD_SAVED_SUCCESS,
    data: data,
  };
};

export const addSavedLoading = (data) => {
  return {
    type: actionTypes.ADD_SAVED_LOADING,
    data: data,
  };
};

export const addSaved = (data, callback) => {
  return (dispatch) => {
    dispatch(addSavedLoading(true));
    return axios
      .post(`${config.adminApi}/savedpalces`, data)
      .then((response) => {
        dispatch(addSavedSuccess(response.data.message));
        dispatch(addSavedLoading(true));
        callback(response.data.status_code);
        dispatch(addSavedLoading(false));
      })
      .catch((err) => {
        dispatch(addSavedLoading(false));
        dispatch(addSavedFailure(err));
        callback(false);
      });
  };
};

// work home

export const addWorkHomeFailure = (error) => {
  return {
    type: actionTypes.ADD_WORKHOME_FAILURE,
    error: error,
  };
};

export const addWorkHomeSuccess = (data) => {
  return {
    type: actionTypes.ADD_WORKHOME_SUCCESS,
    data: data,
  };
};

export const addWorkHomeLoading = (data) => {
  return {
    type: actionTypes.ADD_WORKHOME_LOADING,
    data: data,
  };
};

export const addWorkHome = (data, callback) => {
  return (dispatch) => {
    dispatch(addWorkHomeLoading(true));
    return axios
      .post(`${config.adminApi}/setworkhomeloc`, data)
      .then((response) => {
        dispatch(addWorkHomeSuccess(response.data.message));
        dispatch(addWorkHomeLoading(true));
        callback(response.data.status_code);
        dispatch(addWorkHomeLoading(false));
      })
      .catch((err) => {
        dispatch(addWorkHomeLoading(false));
        dispatch(addWorkHomeFailure(err));
        callback(false);
      });
  };
};

// search

export const addSearchFailure = (error) => {
  return {
    type: actionTypes.ADD_SEARCH_FAILURE,
    error: error,
  };
};

export const addSearchSuccess = (data) => {
  return {
    type: actionTypes.ADD_SEARCH_SUCCESS,
    data: data,
  };
};

export const addSearchLoading = (data) => {
  return {
    type: actionTypes.ADD_SEARCH_LOADING,
    data: data,
  };
};

export const addSearch = (data, callback) => {
  return (dispatch) => {
    dispatch(addSearchLoading(true));
    return axios
      .post(`${config.adminApi}/searchhistory`, data)
      .then((response) => {
        dispatch(addSearchSuccess(response.data.message));
        dispatch(addSearchLoading(true));
        callback(response.data.status_code);
        dispatch(addSearchLoading(false));
      })
      .catch((err) => {
        dispatch(addSearchLoading(false));
        dispatch(addSearchFailure(err));
        callback(false);
      });
  };
};

//
export const welcome = () => {
  return {
    type: actionTypes.WELCOME,
  };
};

export const noWelcome = () => {
  return (dispatch) => {
    dispatch(welcome());
  };
};

//
export const accessSearch = (data) => {
  return {
    type: actionTypes.SAVE_SEARCH,
    data: data,
  };
};

export const saveSearch = (data) => {
  return (dispatch) => {
    dispatch(accessSearch(data));
  };
};

//
export const accessSearchA = (data) => {
  return {
    type: actionTypes.SAVE_SEARCH_A,
    data: data,
  };
};

export const saveSearchA = (data) => {
  return (dispatch) => {
    dispatch(accessSearchA(data));
  };
};

//
export const accessSearchB = (data) => {
  return {
    type: actionTypes.SAVE_SEARCH_B,
    data: data,
  };
};

export const saveSearchB = (data) => {
  return (dispatch) => {
    dispatch(accessSearchB(data));
  };
};

//get train

export const getDLRFailure = (error) => {
  return {
    type: actionTypes.GET_DLR_FAILURE,
    error: error,
  };
};

export const getDLRSuccess = (data) => {
  return {
    type: actionTypes.GET_DLR_SUCCESS,
    data: data,
  };
};

export const getDLRLoading = (data) => {
  return {
    type: actionTypes.GET_DLR_LOADING,
    data: data,
  };
};

export const getDLR = (data, callback) => {
  return (dispatch) => {
    dispatch(getDLRLoading(true));
    return axios
      .get(
        `${config.adminApi}/get_nearest_dlr_under_over?lat=${data.lat}&lon=${data.lng}`
      )
      .then((response) => {
        dispatch(getDLRSuccess(response.data));
        dispatch(getDLRLoading(true));
        callback(response.data);
        dispatch(getDLRLoading(false));
      })
      .catch((err) => {
        dispatch(getDLRLoading(false));
        dispatch(getDLRFailure(err));
        callback(false);
      });
  };
};

// record visit

export const setRecordFailure = (error) => {
  return {
    type: actionTypes.SET_RECORD_FAILURE,
    error: error,
  };
};

export const setRecordSuccess = (data) => {
  return {
    type: actionTypes.SET_RECORD_SUCCESS,
    data: data,
  };
};

export const setRecordLoading = (data) => {
  return {
    type: actionTypes.SET_RECORD_LOADING,
    data: data,
  };
};

export const setRecord = (obj) => {
  return (dispatch) => {
    dispatch(setRecordLoading(true));

    return axios
      .post(`${config.adminApi}/user/recordvisit`, obj, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch(setRecordSuccess(response.data.message));
        dispatch(setRecordLoading(true));
      })
      .catch((err) => {
        dispatch(setRecordLoading(false));
        dispatch(setRecordFailure(err));
      });
  };
};

//get business

export const getBUSINESSFailure = (error) => {
  return {
    type: actionTypes.GET_BUSINESS_FAILURE,
    error: error,
  };
};

export const getBUSINESSSuccess = (data) => {
  return {
    type: actionTypes.GET_BUSINESS_SUCCESS,
    data: data,
  };
};

export const getBUSINESSLoading = (data) => {
  return {
    type: actionTypes.GET_BUSINESS_LOADING,
    data: data,
  };
};

export const getBUSINESS = (callback) => {
  return (dispatch) => {
    dispatch(getBUSINESSLoading(true));
    return axios
      .get(`${config.adminApi}/business/businesslayer`, {
        headers: { "Content-Type": "application/json" },
      })
      .then((response) => {
        dispatch(getBUSINESSSuccess(response.data));
        dispatch(getBUSINESSLoading(true));
        callback(response.data);
        dispatch(getBUSINESSLoading(false));
      })
      .catch((err) => {
        dispatch(getBUSINESSLoading(false));
        dispatch(getBUSINESSFailure(err));
        callback(false);
      });
  };
};

//search category

export const getSearchCatFailure = (error) => {
  return {
    type: actionTypes.GET_SEARCH_CAT_FAILURE,
    error: error,
  };
};

export const getSearchCatSuccess = (data) => {
  return {
    type: actionTypes.GET_SEARCH_CAT_SUCCESS,
    data: data,
  };
};

export const getSearchCatLoading = (data) => {
  return {
    type: actionTypes.GET_SEARCH_CAT_LOADING,
    data: data,
  };
};

export const getSearchCat = (data, callback) => {
  return (dispatch) => {
    var bodyFormData = new FormData();
    bodyFormData.set("search_term", data);

    dispatch(getSearchCatLoading(true));
    return axios
      .post(`${config.adminApi}/business/searchbycategory`, bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        dispatch(getSearchCatSuccess(response.data));
        dispatch(getSearchCatLoading(true));
        callback(response.data);
        dispatch(getSearchCatLoading(false));
      })
      .catch((err) => {
        dispatch(getSearchCatLoading(false));
        dispatch(getSearchCatFailure(err));
        callback(false);
      });
  };
};

// get coords to words what3words

//get buss

export const getCordToWordsFailure = (error) => {
    return {
      type: actionTypes.GET_CORDTOWORDS_FAILURE,
      error: error,
    };
  };
  
  export const getCordToWordsSuccess = (data) => {
    return {
      type: actionTypes.GET_CORDTOWORDS_SUCCESS,
      data: data,
    };
  };
  
  export const getCordToWordsLoading = (data) => {
    return {
      type: actionTypes.GET_CORDTOWORDS_LOADING,
      data: data,
    };
  };
  
  export const getCordToWords = (data, callback) => {
    return (dispatch) => {
      dispatch(getCordToWordsLoading(true));
      return axios.get(
        `${config.what3wordstoWords}&coordinates=${data}&language=en&format=json`
      )
        .then((response) => {
          dispatch(getCordToWordsSuccess(response.data));
          dispatch(getCordToWordsLoading(true));
          callback(response.data);
          dispatch(getCordToWordsLoading(false));
        })
        .catch((err) => {
          dispatch(getCordToWordsLoading(false));
          dispatch(getCordToWordsFailure(err));
          callback(false);
        });
    };
  };