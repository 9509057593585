import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {

    singUpLoading: false,
    singUp: null,
    singUpError: false,

    email: sessionStorage.getItem('email'),
    error: null,
    loading: false,

    forgotPasswordLoading: false,
    forgotPassword: null,
    forgotPasswordError: false,

    resetPasswordLoading: false,
    resetPassword: null,
    resetPasswordError: false,

    resetProfileLoading: false,
    resetProfile: null,
    resetProfileError: false,

}

//signup
const singUpStart = (state, action) => {
    return updateObject(state, { singUpError: false, singUpLoading: true });

}

const singUpSuccess = (state, action) => {

    return updateObject(state, { singUp: action.data });

}

const singUpFail = (state, action) => {
    return updateObject(state, { singUpError: action.error });

}


//sign in

const authStart = (state, action) => {
    return updateObject(state, { error: null, loading: action.data });

}


const authSuccess = (state, action) => {
    return updateObject(state, { email: action.email, loading: false });

}

const authFail = (state, action) => {
    return updateObject(state, { error: action.error, loading: false });

}

const authLogout = (state, action) => {
    return updateObject(state, { email: null, loading: false })
}


//signup
const forgotPasswordStart = (state, action) => {
    return updateObject(state, { forgotPasswordError: false, forgotPasswordLoading: true });

}

const forgotPasswordSuccess = (state, action) => {

    return updateObject(state, { forgotPassword: action.data });

}

const forgotPasswordFail = (state, action) => {
    return updateObject(state, { forgotPasswordError: action.error });

}

//signup
const resetPasswordStart = (state, action) => {
    return updateObject(state, { resetPasswordError: false, resetPasswordLoading: true });

}

const resetPasswordSuccess = (state, action) => {

    return updateObject(state, { resetPassword: action.data });

}

const resetPasswordFail = (state, action) => {
    return updateObject(state, { resetPasswordError: action.error });

}

//signup
const resetProfileStart = (state, action) => {
    return updateObject(state, { resetProfileError: false, resetProfileLoading: true });

}

const resetProfileSuccess = (state, action) => {

    return updateObject(state, { resetProfile: action.data, resetProfileLoading: false });

}

const resetProfileFail = (state, action) => {
    return updateObject(state, { resetProfileError: action.error });

}

const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.SIGN_UP_START:
            return singUpStart(state, action)
        case actionTypes.SIGN_UP_SUCCESS:
            return singUpSuccess(state, action)
        case actionTypes.SIGN_UP_FAIL:
            return singUpFail(state, action)

        case actionTypes.AUTH_START:
            return authStart(state, action)
        case actionTypes.AUTH_SUCCESS:
            return authSuccess(state, action)
        case actionTypes.AUTH_FAIL:
            return authFail(state, action)
        case actionTypes.AUTH_LOGOUT:
            return authLogout(state, action)

        case actionTypes.FORGOT_PASSWORD_START:
            return forgotPasswordStart(state, action)
        case actionTypes.FORGOT_PASSWORD_SUCCESS:
            return forgotPasswordSuccess(state, action)
        case actionTypes.FORGOT_PASSWORD_FAIL:
            return forgotPasswordFail(state, action)

        case actionTypes.RESET_PASSWORD_START:
            return resetPasswordStart(state, action)
        case actionTypes.RESET_PASSWORD_SUCCESS:
            return resetPasswordSuccess(state, action)
        case actionTypes.RESET_PASSWORD_FAIL:
            return resetPasswordFail(state, action)

        case actionTypes.RESET_PROFILE_START:
            return resetProfileStart(state, action)
        case actionTypes.RESET_PROFILE_SUCCESS:
            return resetProfileSuccess(state, action)
        case actionTypes.RESET_PROFILE_FAIL:
            return resetProfileFail(state, action)

        default:
            return state;
    }
}

export default reducer;