import React, { useState, useEffect } from 'react';
import {
    PageHeader,
    Card,
    Avatar,
    Form,
    Input,
    Button,
    Checkbox,
    Row,
    Col,
    Spin,
    InputNumber,
} from 'antd';
import { UserOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import wheelchair from '../../assets/images/wheelchair.png';
import blind from '../../assets/images/blind.png';
import block from '../../assets/images/block.png';
import brain from '../../assets/images/brain.png';
import hear from '../../assets/images/hear.png';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../Resusable/Notification/index';
import ColoredCard from '../Resusable/Card/ColoredCard'
import baron from '../../assets/images/baron.png'
import duke from '../../assets/images/duke.png'
import earl from '../../assets/images/earl.png'
import king from '../../assets/images/king.png'
import kingsguard from '../../assets/images/kingsguard.png'
import knight from '../../assets/images/knight.png'
import lord from '../../assets/images/lord.png'
import marquis from '../../assets/images/marquis.png'
import nightswatch from '../../assets/images/nightswatch.png'
import prince from '../../assets/images/prince.png'
import soldier from '../../assets/images/soldier.png'
import unsullied from '../../assets/images/unsullied.png'
import viscount from '../../assets/images/soldier.png'
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GApageView} from '../../utils/index';

import './profile.css';

const Profile = (props) => {
    const [phoneInput, setPhoneInput] = useState('');
    const [emerConInput, setEmerCon] = useState('');
    const [data, setData] = useState([]);
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [age, setAge] = useState('');
    const [dis, setDis] = useState([]);
    const [password, setpassword] = useState('');
    const [name, setname] = useState('');
    const [updateLoading, setUpdateLoading] = useState(false);

    const [points, setPoints] = useState('');
    const [level, setLevel] = useState('');
    const [badge, setBadge] = useState('');
    const [imgSrc, setImgSrc] = useState('')

    useEffect(() => {
        GApageView(props.match.path); 

        fetch();
    }, [])

    const fetch = () => {
        setLoading(true)
        props.getProfile(res => {
            if (!res) {
                setLoading(false)

            } else {
                let disability = [...res.data.user_disability];
                let preferences = [...dis]
                let finalArray = disability.concat(preferences)
                setData(res.data);
                setEmail(res.data.email);
                setPhoneInput(res.data.user_mobile)
                setEmerCon(res.data.emergency_contact)
                setAge(res.data.user_age)
                setname(res.data.name_of_user)
                // setpassword(res.data.password)
                setLoading(false)
                setDis(finalArray)
                form.setFieldsValue({
                    emergency_contact
                        : res.data.emergency_contact,

                        user_mobile: res.data.user_mobile
                    
                });

            }
        })

        props.getProfileGamm(res => {
            if (!res) {

            } else {
                setBadge(res.data.user_badge);
                setLevel(res.data.user_level);
                setPoints(res.data.user_points);
                if (res.data.user_level && res.data.user_level === 'Unsullied') {
                    setImgSrc(unsullied)
                } else if (res.data.user_level && res.data.user_level === 'Nights Watch') {
                    setImgSrc(nightswatch)

                } else if (res.data.user_level && res.data.user_level === 'Soldier') {
                    setImgSrc(soldier)

                } else if (res.data.user_level && res.data.user_level === 'Royal knight') {
                    setImgSrc(knight)

                } else if (res.data.user_level && res.data.user_level === 'Kingsguard') {
                    setImgSrc(kingsguard)

                } else if (res.data.user_level && res.data.user_level === 'Untitled lord') {
                    setImgSrc(lord)

                } else if (res.data.user_level && res.data.user_level === 'Land lord') {
                    setImgSrc(lord)

                }

            }
        })
    }

    const formItemLayout = {
        // labelCol: { span: 24 , offset: 4 },
        wrapperCol: { span: 20, offset: 0 },
    };
    const tailFormItemLayout = {
        wrapperCol: {
            xs: {
                span: 24,
                offset: 0,
            },
            sm: {
                span: 20,
                offset: 10,
            },
        },
    };

    const onChange = (name, event) => {
        if (name === 'email') {
            setEmail(event.target.value)
        } else if (name === 'age') {
            setAge(event.target.value)
        } else if (name === 'name') {
            setname(event.target.value)
        } else if (name === 'phoneInput') {
            setPhoneInput(event)
        } else if (name === 'emerConInput') {
            setEmerCon(event)
        } else if (name === 'password') {
            setpassword(event.target.value)
        }
    }

    const checkHandler = (checkedValue) => {
        let disability = [];
        disability.push(checkedValue);
        setDis(disability)
    }

    const handleProfile = values => {
        console.log('Received values of form: ', values);
        let data = {};
        if (password.length > 0) {
            data = {
                email: email || props.getProfileSuccess && props.getProfileSuccess.email,
                age: age,
                name: name,
                phoneInput: phoneInput,
                emerConInput: emerConInput,
                dis: dis,
                password: password
            }
        } else {
            data = {
                email: email || props.getProfileSuccess && props.getProfileSuccess.email,
                age: age,
                name: name,
                phoneInput: phoneInput,
                emerConInput: emerConInput,
                dis: dis,
            }
        }

        setUpdateLoading(true)
        props.profile(data, res => {
            if (res.status === 2) {
                setUpdateLoading(false)
                Notification('error', 'Something went wrong');

            } else {
                setUpdateLoading(false)
                let disability = [...res.data.user_disability];
                // let finalArray = disability.concat(preferences)
                setData(res.data);
                setEmail(res.data.email);
                setPhoneInput(res.data.user_mobile)
                setEmerCon(res.data.emergency_contact)
                setAge(res.data.user_age)
                setname(res.data.name_of_user)
                setLoading(false)
                setDis(disability)
                setpassword(res.data.password)
                Notification('success', 'Profile updated successfully');


            }
        })

    };
    const [form] = Form.useForm();

    return (
        <>
            <PageHeader
                className='Pageheader-prf'
                title={`Profile`}
                ghost
            />



            <div className="div-badge" >
                <div className='sm-div' >
                    <ColoredCard color='#7266ba' icon='badge' header='Badge' data={badge} />
                </div>

                <div className='sm-div' >
                    <ColoredCard color='#42a5f6' icon='points' header='Points' data={points} />
                </div>

                <div className='sm-div' >
                    <ColoredCard color='#f75d81' icon='level' header='Level' data={level} level={level} />
                </div>

            </div>






            <Card className='card-style-prof'>
                {loading ?
                    <div className='spining-pr'><Spin size="large" /></div>
                    :
                    <Form
                        form={form}
                        name="validate_other"
                        labelAlign='left'
                        layout='vertical'
                    >
                        <div className='profile-pic'>
                            <img height={180} src={imgSrc} />
                        </div>
                        <Form.Item
                            name="name_of_user"
                            label="Name"
                        >

                            <Input defaultValue={props.getProfileSuccess && props.getProfileSuccess.name_of_user} value={data.name_of_user} onChange={(event) => onChange('name', event)} />
                        </Form.Item>
                        <Form.Item
                            name="email"
                            label="E-mail"
                        >

                            <Input defaultValue={props.getProfileSuccess && props.getProfileSuccess.email} value={data.email} />
                        </Form.Item>

                        <Form.Item
                            label='Password'
                            name="password"
                        >
                            <Input.Password
                                // defaultValue={props.getProfileSuccess && props.getProfileSuccess.password}
                                value={data.password}
                                onChange={(event) => onChange('password', event)}
                                type="password"
                                placeholder="Update Password"
                            />
                        </Form.Item>

                        <Form.Item
                            name="user_mobile"
                            label="Mobile Number"
                            rules={[
                                {
                                    message: 'Please input your mobile number!',
                                },
                            ]}

                        >
                            <PhoneInput
                                country={'gb'}
                                value={phoneInput}
                                // defaultValue={props.getProfileSuccess && props.getProfileSuccess.user_mobile}

                                // className='input-field' 
                                onChange={(event) => onChange('phoneInput', event)}
                                inputProps={{ minLength: 15 }}

                            />

                        </Form.Item>

                        <Form.Item
                            name="emergency_contact"
                            label="Emergency Contact"
                            rules={[
                                {
                                    message: 'Please input your mobile number!',
                                },
                            ]}
                        >
                            <PhoneInput
                                country={'gb'}
                                value={emerConInput}
                                // defaultValue={props.getProfileSuccess && props.getProfileSuccess.emergency_contact}
                                onChange={(event) => onChange('emerConInput', event)}
                                inputProps={{ minLength: 15 }}

                            />
                            {/* <Input
                                type='number'
                                value={data.emergency_contact}
                                defaultValue={props.getProfileSuccess && props.getProfileSuccess.emergency_contact}
                                onChange={(event) => onChange('emerConInput', event)}
                                inputProps={{ minLength: 15 }}
                            /> */}
                        </Form.Item>



                        <Form.Item
                            name="user_age"
                            label='Age'
                            rules={[
                                {
                                    message: 'Please input your valid age!',
                                    whitespace: true,
                                },
                            ]}
                        >
                            <Input type='number' min={1} defaultValue={props.getProfileSuccess && props.getProfileSuccess.user_age} value={data.user_age} onChange={(event) => onChange('age', event)} />
                        </Form.Item>

                        <div className='disability-div'>
                            <p className='para-dis'>Disability Preferences</p>
                        </div>
                        <Checkbox.Group
                            onChange={checkHandler}
                            defaultValue={data.user_disability && data.user_disability[0]}

                        >
                            <Row>
                                <Col span={24}>
                                    <span><img src={wheelchair} alt='' width={16} height={16} /></span>&nbsp;&nbsp;
                                        <Checkbox value='wheelchair' style={{ lineHeight: '32px' }}>
                                        Wheelchair Users
                                        </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <span><img src={blind} alt='' width={16} height={16} /></span>&nbsp;&nbsp;
                                        <Checkbox value="visual" style={{ lineHeight: '32px' }}>
                                        Visually Impaired
                                 </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <span><img src={hear} alt='' width={16} height={16} /></span>&nbsp;&nbsp;
                                        <Checkbox value="hear" style={{ lineHeight: '32px' }}>
                                        Hearing Impaired
                                     </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <span><img src={brain} alt='' width={16} height={16} /></span>&nbsp;&nbsp;
                                        <Checkbox value="autism" style={{ lineHeight: '32px' }}>
                                        Autism
                                      </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <span><img src={block} alt='' width={16} height={16} /></span>&nbsp;&nbsp;
                                        <Checkbox value="none" style={{ lineHeight: '32px' }}>
                                        None
                                      </Checkbox>
                                </Col>
                            </Row>
                        </Checkbox.Group>

                        <Form.Item {...tailFormItemLayout}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                className='button-primary width-200'
                                loading={updateLoading}
                                onClick={() => handleProfile()}>
                                Save
                       </Button>
                        </Form.Item>


                    </Form>}


            </Card>
        </>
    )
}

const mapStateToProps = state => {
    return {
        getProfileLoading: state.profileReducer.getProfileLoading,
        getProfileSuccess: state.profileReducer.getProfileSuccess,
        profileLoading: state.profileReducer.profileLoading


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getProfile: (res) => dispatch(actions.getProfile(res)),
        profile: (data, res) => dispatch(actions.profile(data, res)),
        getProfileGamm: (res) => dispatch(actions.getProfileGamm(res)),

    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
