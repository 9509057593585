import React, { Component } from 'react';
import ReactMapboxGl, { Marker, ScaleControl, ZoomControl, Popup, Layer, Feature, GeoJSONLayer } from 'react-mapbox-gl';
import styled from 'styled-components';
import { token } from '../../config';
import locateIcon from '../../assets/images/locate.svg';
import locateB from '../../assets/images/locateB.svg';
import * as MapboxGL from 'mapbox-gl';
import { CloseOutlined } from '@ant-design/icons';
import './navigation.css'
import NavCardInfo from './NavCardInfo'
import GreenpoiURl from '../../assets/images/Ellipse4.png';
import RedpoiURl from '../../assets/images/Inaccessible.svg';
import OrangepoiURl from '../../assets/images/PartiallyAccessible.svg';
import PurplepoiURL from '../../assets/images/PredictionShape.svg';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

const layoutLayer = { 'icon-image': 'londonPoi' }
const image = new Image();
image.src = locateIcon;
image.alt = 'alt';
const imageA = ['londonPoi', image]

const layoutLayerBus = { 'icon-image': 'londonBus' }
const imageBus = new Image();
imageBus.src = locateB;
imageBus.alt = 'busalt';
const imagesBus = ['londonBus', imageBus]
const polyline = require('polyline');




const Map = ReactMapboxGl({
    accessToken: token,
    renderWorldCopies: true
});

const Container = styled.div`
  position: relative;
  height: 100% !importat;
  flex: 1
  `
const StyledPopup = styled.div`
 background: white;
 color: #3f618c;
 font-weight:400;
 padding: 5px;
 border-radius: 2px
 `
const linePaint = MapboxGL.LinePaint = {
    'line-color': 'black',
    'line-width': 5,
};
const linePaint_bus = MapboxGL.LinePaint = {
    'line-color': '#e75480',
    'line-width': 5,
};
const linePaint_train = MapboxGL.LinePaint = {
    'line-color': 'brown',
    'line-width': 5,
};
const linePaint_tube = MapboxGL.LinePaint = {
    'line-color': '#0000A0',
    'line-width': 5,
};
const linePaint_tram = MapboxGL.LinePaint = {
    'line-color': 'orange',
    'line-width': 5,
};
const linePaint_walking = MapboxGL.LinePaint = {
    'line-color': 'black',
    'line-width': 5,
    'line-dasharray': [0, 2]

};
const lineLayout = {
    'line-join': 'round',
    'line-cap': 'round',

};

const layoutLayerGreen = { 'icon-image': 'londonPoiG' }
const imageG = new Image();
imageG.src = GreenpoiURl;
imageG.alt = 'alt';
const imagePOI = ['londonPoiG', imageG]

const layoutLayerGreenB = { 'icon-image': 'londonPoiGB' }
const imageGB = new Image();
imageGB.src = GreenpoiURl;
imageGB.alt = 'alt';
const imagePOIGB = ['londonPoiG', imageGB]

const layoutLayerOrange = { 'icon-image': 'londonPoiO' }
const imageO = new Image();
imageO.src = OrangepoiURl;
imageO.alt = 'alt';
const OrangeimagePOI = ['londonPoiO', imageO]

const layoutLayerRed = { 'icon-image': 'londonPoiR' }
const imageR = new Image();
imageR.src = RedpoiURl;
imageR.alt = 'alt';
const RedimagePOI = ['londonPoiR', imageR]

const layoutLayerPurple = { 'icon-image': 'londonPoiP' }
const imageP = new Image();
imageP.src = PurplepoiURL;
imageP.alt = 'alt';
const PurpleimagePOI = ['londonPoiP', imageP]


const polygonPaint = {
    'fill-color': '#6F788A',
    'fill-opacity': 0.7
};

const multiPolygonPaint = {
    'fill-color': 'red',
    'fill-opacity': 0.5
};
const train_arr = ['dlr', 'national-rail', 'overground', 'tflrail']
const google_train_arr = ['RAIL', 'METRO_RAIL', 'MONORAIL', 'HEAVY_RAIL', 'COMMUTER_TRAIN', 'SPEED_TRAIN']
const google_bus_arr = ['BUS', 'INTERCITY_BUS', 'TROLLEYBUS']


class NavigationMap extends Component {
    state = {
        center: [-0.09, 53.505],
        zoom: [12],
        centerA: [-0.09, 53.505],
        userPopup: false,
        google_userPopup: false,
        routePopup: false,
        google_routePopup: false,
        coord: [],
        google_coord: [],
        mode: '',
        google_mode: '',
        summary: '',
        google_summary: '',
        Tfl_legs: [],
        Google_legs: [],
        poi: [],
        pPoi: [],



    }

    onStyleLoad = (map) => {
        const { onStyleLoad } = this.props;
        return onStyleLoad && onStyleLoad(map)
    }


    markerClick = (leg, legs, val) => {

        this.setState({
            // zoom: [19],
            // leg: [...leg],
            coord: [val.lngLat.lng, val.lngLat.lat],
            mode: leg.mode.name,
            summary: leg.instruction.summary,
            Tfl_legs: legs,
            userPopup: true,
            routePopup: true,
            google_userPopup: false,
            google_routePopup: false
        });
    };

    google_markerClick = (step, steps, val) => {
        this.setState({
            // zoom: [19],
            // leg: [...leg],
            google_coord: [val.lngLat.lng, val.lngLat.lat],
            google_mode: step.transit.line.vehicle.type,
            google_summary: step.instructions,
            Google_legs: steps,
            google_userPopup: true,
            google_routePopup: true,
            userPopup: false,
            routePopup: false,
        });
    };

    google_markerClick_walking = (step, steps, val) => {
        this.setState({
            // zoom: [19],
            // leg: [...leg],
            google_coord: [val.lngLat.lng, val.lngLat.lat],
            google_mode: step.travel_mode,
            google_summary: step.instructions,
            Google_legs: steps,
            google_userPopup: true,
            google_routePopup: true
        });
    };

    closeCard = (route) => {
        if (route == "TFL") {
            this.setState({ routePopup: false })
        }
        else {
            this.setState({ google_routePopup: false })
        }
    }


    markerClickPoi = (poi, val) => {
        if(this.props.isAuthenticated){
            if(sessionStorage.getItem('role') === 'user'){

         let data = {
            "starting_location_name" : this.props.valueA && this.props.valueA.label,
            "starting_location_latitude": this.props.latA && this.props.latA,
            "starting_location_longitude": this.props.lngA && this.props.lngA,
            "dest_location_name": this.props.valueB && this.props.valueB.label,
            "dest_location_latitude": this.props.latB && this.props.latB,
            "dest_location_longitude": this.props.lngB && this.props.lngB



         }
      
            var bodyFormData = new FormData();
            bodyFormData.set('user_email', sessionStorage.getItem('email'));
            bodyFormData.append('location_latitude', poi.access_lat);
            bodyFormData.append('location_longitude', poi.access_long);
            bodyFormData.append('location_id', poi.place_id);
            bodyFormData.append('location_category', poi.category);
            bodyFormData.append('user_disability', JSON.stringify((this.props.getProfileSuccess.user_disability && this.props.getProfileSuccess.user_disability).flat()));
            bodyFormData.append('user_visit_time', Math.round((new Date()).getTime() / 1000));
            bodyFormData.append('interaction_source', 'navigation');
            bodyFormData.append('journey_details', JSON.stringify(data));



      
            
          this.props.setRecord(bodyFormData)
      
          } else {
            let data = {
                "starting_location_name" : this.props.valueA && this.props.valueA.label,
                "starting_location_latitude": this.props.latA && this.props.latA,
                "starting_location_longitude": this.props.lngA && this.props.lngA,
                "dest_location_name": this.props.valueB && this.props.valueB.label,
                "dest_location_latitude": this.props.latB && this.props.latB,
                "dest_location_longitude": this.props.lngB && this.props.lngB
    
    
    
             }
      
            var bodyFormData = new FormData();
          
            bodyFormData.set('user_email', sessionStorage.getItem('email'));
            bodyFormData.append('location_latitude', poi.access_lat);
            bodyFormData.append('location_longitude', poi.access_long);
            bodyFormData.append('location_id', poi.place_id);
            bodyFormData.append('location_category', poi.category);
            bodyFormData.append('user_visit_time', Math.round((new Date()).getTime() / 1000));
            bodyFormData.append('user_disability', JSON.stringify((this.props.disability && this.props.disability).flat()))
            bodyFormData.append('interaction_source', 'navigation');
            bodyFormData.append('journey_details', JSON.stringify(data));


      
      
          this.props.setRecord(bodyFormData)
          }
        }
        this.setState({
            poi: [poi],
            pPoi: [],


        });
    };

    markerClickPurple = (poi, val) => {
        if (this.props.isAuthenticated) {

            if (sessionStorage.getItem('role') === 'user') {

                let data = {
                    "starting_location_name" : this.props.valueA && this.props.valueA.label,
                    "starting_location_latitude": this.props.latA && this.props.latA,
                    "starting_location_longitude": this.props.lngA && this.props.lngA,
                    "dest_location_name": this.props.valueB && this.props.valueB.label,
                    "dest_location_latitude": this.props.latB && this.props.latB,
                    "dest_location_longitude": this.props.lngB && this.props.lngB
        
        
        
                 }
              var bodyFormData = new FormData();
              bodyFormData.set('user_email', sessionStorage.getItem('email'))
              bodyFormData.append('location_latitude', poi.latitude);
              bodyFormData.append('location_longitude', poi.longitude);
              bodyFormData.append('location_id', poi.place_id);
              bodyFormData.append('location_category', poi.place_category);
              bodyFormData.append('user_visit_time', Math.round((new Date()).getTime() / 1000));
              bodyFormData.append('user_disability', JSON.stringify((this.props.getProfileSuccess.user_disability && this.props.getProfileSuccess.user_disability).flat()));
              bodyFormData.append('interaction_source', 'navigation');
              bodyFormData.append('journey_details', JSON.stringify(data));


      
      
              this.props.setRecord(bodyFormData)
      
            } else {
                let data = {
                    "starting_location_name" : this.props.valueA && this.props.valueA.label,
                    "starting_location_latitude": this.props.latA && this.props.latA,
                    "starting_location_longitude": this.props.lngA && this.props.lngA,
                    "dest_location_name": this.props.valueB && this.props.valueB.label,
                    "dest_location_latitude": this.props.latB && this.props.latB,
                    "dest_location_longitude": this.props.lngB && this.props.lngB
        
        
        
                 }
              var bodyFormData = new FormData();
              bodyFormData.set('user_email', sessionStorage.getItem('email'))
      
              // bodyFormData.append('business_email', sessionStorage.getItem('email'));
              // bodyFormData.append('business_name', this.props.getProfileBusSuccess && this.props.getProfileBusSuccess.business_name);
              // bodyFormData.append('business_id', this.props.getProfileBusSuccess && this.props.getProfileBusSuccess.business_contact_phone);
              bodyFormData.append('location_latitude', poi.latitude);
              bodyFormData.append('location_longitude', poi.longitude);
              bodyFormData.append('location_id', poi.place_id);
              bodyFormData.append('location_category', poi.place_category);
              bodyFormData.append('user_visit_time', Math.round((new Date()).getTime() / 1000));
              bodyFormData.append('user_disability', JSON.stringify((this.props.disability && this.props.disability).flat()))
              bodyFormData.append('interaction_source', 'navigation');
              bodyFormData.append('journey_details', JSON.stringify(data));

                
              this.props.setRecord(bodyFormData)
            }
      
          }
        this.setState({
            poi: [],
            pPoi: [poi],


        });
    };



    render() {
        const {
            google_summary,
            summary,
            mode,
            google_mode,
            coord,
            google_coord,
            centerB,
            centerA,
            center,
            zoom,
            userPopup,
            routePopup,
            Tfl_legs,
            google_userPopup,
            google_routePopup,
            Google_legs,
            poi,
            pPoi
        } = this.state
        const positionA = [parseFloat(this.props.lngA) || -0.09, parseFloat(this.props.latA) || 53.505]
        const positionB = [parseFloat(this.props.lngB), parseFloat(this.props.latB)]
        const { journey, fullJourney, journeyGoogle, PpoiDataA, searchB, searchA } = this.props

        console.log('PpoiDataA')
        console.log(PpoiDataA)
        return (
            <Container>
                <Map
                    style={{
                        "version": 8,
                        "name": "Raster Tiles",
                        "sources": {
                            "yourTileLayer": {
                                "type": "raster",
                                "tiles": [
                                    "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
                                    "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
                                    "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                ],
                                "tileSize": 256
                            }
                        },
                        "layers": [{
                            "id": "yourTileLayer",
                            "type": "raster",
                            "source": "yourTileLayer",
                            "paint": {
                                "raster-fade-duration": 100
                            }
                        }]
                    }}
                    containerStyle={{
                        height: '86vh',
                        width: '100%'
                    }}
                    onStyleLoad={this.onStyleLoad}
                    // center={this.props.searchB ? positionB : positionA}
                    fitBounds={searchB && searchA ? [positionA, positionB] : this.props.Nav_here ? [[this.props.lngA, this.props.latA], [this.props.lngB, this.props.latB]] : this.props.searchA ? [positionA, positionA] : this.props.searchB ? [positionB, positionB] : [[-0.136439, 51.507359], [-0.136439, 51.507359]]}
                    // fitBoundsOptions={{ maxZoom: 24, minZoom: 0, padding: { top: 20, bottom: 25, left: 15, right: 5 } }}
                    // zoom={zoom}



                >

                    <Layer type="symbol" id="poi" layout={layoutLayerRed} images={[RedimagePOI]}>
                        {Object.keys(this.props.RpoiDataA && this.props.RpoiDataA).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.RpoiDataA[val].access_long, this.props.RpoiDataA[val].access_lat]}

                            onClick={this.markerClickPoi.bind(this, this.props.RpoiDataA[val])}
                            onMouseEnter={this.markerClickPoi.bind(this, this.props.RpoiDataA[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>


                    <Layer type="symbol" id="ppoi" layout={layoutLayerPurple} images={[PurpleimagePOI]}>
                        {Object.keys(this.props.PpoiDataA && this.props.PpoiDataA).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.PpoiDataA[val].longitude, this.props.PpoiDataA[val].latitude]}
                            onClick={this.markerClickPurple.bind(this, this.props.PpoiDataA[val])}
                            onMouseEnter={this.markerClickPurple.bind(this, this.props.PpoiDataA[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>


                    <Layer type="symbol" id="gpoi" layout={layoutLayerGreen} images={[imagePOI]}>
                        {Object.keys(this.props.GpoiDataA && this.props.GpoiDataA).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.GpoiDataA[val].access_long, this.props.GpoiDataA[val].access_lat]}

                            onClick={this.markerClickPoi.bind(this, this.props.GpoiDataA[val])}
                            onMouseEnter={this.markerClickPoi.bind(this, this.props.GpoiDataA[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>


                    <Layer type="symbol" id="Opoi" layout={layoutLayerOrange} images={[OrangeimagePOI]}>
                        {Object.keys(this.props.OpoiDataA && this.props.OpoiDataA).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.OpoiDataA[val].access_long, this.props.OpoiDataA[val].access_lat]}
                            onClick={this.markerClickPoi.bind(this, this.props.OpoiDataA[val])}
                            onMouseEnter={this.markerClickPoi.bind(this, this.props.OpoiDataA[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>










                    <Layer type="symbol" id="poi" layout={layoutLayerRed} images={[RedimagePOI]}>
                        {Object.keys(this.props.RpoiDataB && this.props.RpoiDataB).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.RpoiDataB[val].access_long, this.props.RpoiDataB[val].access_lat]}

                            onClick={this.markerClickPoi.bind(this, this.props.RpoiDataB[val])}
                            onMouseEnter={this.markerClickPoi.bind(this, this.props.RpoiDataB[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>


                    <Layer type="symbol" id="ppoi" layout={layoutLayerPurple} images={[PurpleimagePOI]}>
                        {Object.keys(this.props.PpoiDataB && this.props.PpoiDataB).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.PpoiDataB[val].longitude, this.props.PpoiDataB[val].latitude]}
                            onClick={this.markerClickPurple.bind(this, this.props.PpoiDataB[val])}
                            onMouseEnter={this.markerClickPurple.bind(this, this.props.PpoiDataB[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>


                    <Layer type="symbol" id="gpoi" layout={layoutLayerGreenB} images={[imagePOIGB]}>
                        {Object.keys(this.props.GpoiDataB && this.props.GpoiDataB).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.GpoiDataB[val].access_long, this.props.GpoiDataB[val].access_lat]}
                            onClick={this.markerClickPoi.bind(this, this.props.GpoiDataB[val])}
                            onMouseEnter={this.markerClickPoi.bind(this, this.props.GpoiDataB[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>


                    <Layer type="symbol" id="Opoi" layout={layoutLayerOrange} images={[OrangeimagePOI]}>
                        {Object.keys(this.props.OpoiDataB && this.props.OpoiDataB).map((val, index) => (<Feature
                            key={val}
                            coordinates={[this.props.OpoiDataB[val].access_long, this.props.OpoiDataB[val].access_lat]}
                            onClick={this.markerClickPoi.bind(this, this.props.OpoiDataB[val])}
                            onMouseEnter={this.markerClickPoi.bind(this, this.props.OpoiDataB[val])}
                            onMouseLeave={() => this.setState({ poi: [] })}
                        />
                        )
                        )}
                    </Layer>


                    <Layer type="symbol" id="A" layout={layoutLayer} images={[imageA]}>
                        <Feature
                            key={1}
                            coordinates={[parseFloat(this.props.lngA), parseFloat(this.props.latA)]}
                        />
                    </Layer>

                    <Layer type="symbol" id="B" layout={layoutLayerBus} images={[imagesBus]}>
                        <Feature
                            key={3}
                            coordinates={[parseFloat(this.props.lngB), parseFloat(this.props.latB)]}
                        />
                    </Layer>
                    <ZoomControl position='bottom-left' />



                    {poi.length > 0 && (
                        <Popup coordinates={[poi[0].access_long, poi[0].access_lat]}>
                            <StyledPopup>
                                <div className='cross'>
                                    <div>Category: {poi[0].category === 'undefined' ? 'Not available' : poi[0].category}</div>
                                    <CloseOutlined onClick={() => this.setState({ poi: [] })} />
                                </div>
                                <div>
                                    Description: {poi[0].description}
                                </div>
                                <div>
                                    Wheelchair Accessibility: {poi[0].accessibility_cat && poi[0].accessibility_cat === 'wheelchair' && poi[0].accessibility_value === true ? 'Yes' :
                                        poi[0].accessibility_cat === 'partial_wheelchair' && poi[0].accessibility_value === true ? 'Partially' : 'No'}
                                </div>
                            </StyledPopup>
                        </Popup>
                    )}

                    {pPoi.length > 0 && (
                        <Popup coordinates={[pPoi[0].longitude, pPoi[0].latitude]}>
                            <StyledPopup>
                                <div className='cross'>
                                    <div>Category: {pPoi[0].place_category === 'undefined' ? 'Not available' : pPoi[0].place_category}</div>
                                    <CloseOutlined onClick={() => this.setState({ pPoi: [] })} />
                                </div>
                                <div>
                                    Description: {pPoi[0].description}
                                </div>
                            </StyledPopup>
                        </Popup>
                    )}


                    {journeyGoogle && journeyGoogle.map(route => {
                        return route.legs[0].steps && route.legs[0].steps.map(step => {
                            if (step.travel_mode == "WALKING") {
                                return <Layer type="line" layout={lineLayout} paint={linePaint_walking}>

                                    <Feature
                                        key={4}
                                        coordinates={polyline.decode(step.encoded_lat_lngs).map(arr => arr.reverse())}
                                        onClick={this.google_markerClick_walking.bind(this, step, route.legs[0].steps)}

                                    />

                                </Layer>
                            }
                            else if (google_bus_arr.indexOf(step.transit.line.vehicle.type) > -1) {
                                return <Layer type="line" layout={lineLayout} paint={linePaint_bus}>

                                    <Feature
                                        key={4}
                                        coordinates={polyline.decode(step.encoded_lat_lngs).map(arr => arr.reverse())}
                                        onClick={this.google_markerClick.bind(this, step, route.legs[0].steps)}

                                    />

                                </Layer>
                            }
                            else if (google_train_arr.indexOf(step.transit.line.vehicle.type) > -1) {
                                return <Layer type="line" layout={lineLayout} paint={linePaint_train}>

                                    <Feature
                                        key={4}
                                        coordinates={polyline.decode(step.encoded_lat_lngs).map(arr => arr.reverse())}
                                        onClick={this.google_markerClick.bind(this, step, route.legs[0].steps)}

                                    />

                                </Layer>
                            }
                            else if (step.transit.line.vehicle.type == 'SUBWAY') {
                                return <Layer type="line" layout={lineLayout} paint={linePaint_tube}>

                                    <Feature
                                        key={4}
                                        coordinates={polyline.decode(step.encoded_lat_lngs).map(arr => arr.reverse())}
                                        onClick={this.google_markerClick.bind(this, step, route.legs[0].steps)}

                                    />

                                </Layer>
                            }
                            else if (step.transit.line.vehicle.type == 'TRAM') {
                                return <Layer type="line" layout={lineLayout} paint={linePaint_tram}>

                                    <Feature
                                        key={4}
                                        coordinates={polyline.decode(step.encoded_lat_lngs).map(arr => arr.reverse())}
                                        onClick={this.google_markerClick.bind(this, step, route.legs[0].steps)}

                                    />

                                </Layer>
                            }
                            else {
                                return <Layer type="line" layout={lineLayout} paint={linePaint}>

                                    <Feature
                                        key={4}
                                        coordinates={polyline.decode(step.encoded_lat_lngs).map(arr => arr.reverse())}
                                        onClick={this.google_markerClick.bind(this, step, route.legs[0].steps)}

                                    />

                                </Layer>



                            }


                        })

                    })



                    }

                    {

                        fullJourney && fullJourney.map(value => {
                            return value.legs && value.legs.map(leg => {

                                if (leg.mode.name === "walking") {
                                    return <Layer type="line" layout={lineLayout} paint={linePaint_walking}>

                                        <Feature
                                            key={4}
                                            coordinates={leg.path.lineString && JSON.parse(leg.path.lineString).map(arr => arr.reverse())}
                                            onClick={this.markerClick.bind(this, leg, value.legs)}

                                        />
                                    </Layer>
                                }
                                if (train_arr.indexOf(leg.mode.name) > -1) {
                                    return <Layer type="line" layout={lineLayout} paint={linePaint_train}>

                                        <Feature
                                            key={4}
                                            coordinates={leg.path.lineString && JSON.parse(leg.path.lineString).map(arr => arr.reverse())}
                                            onClick={this.markerClick.bind(this, leg, value.legs)}

                                        />
                                    </Layer>
                                }
                                if (leg.mode.name === "tram") {
                                    return <Layer type="line" layout={lineLayout} paint={linePaint_tram}>

                                        <Feature
                                            key={4}
                                            coordinates={leg.path.lineString && JSON.parse(leg.path.lineString).map(arr => arr.reverse())}
                                            onClick={this.markerClick.bind(this, leg, value.legs)}

                                        />
                                    </Layer>
                                }
                                if (leg.mode.name === "tube") {
                                    return <Layer type="line" layout={lineLayout} paint={linePaint_tube}>

                                        <Feature
                                            key={4}
                                            coordinates={leg.path.lineString && JSON.parse(leg.path.lineString).map(arr => arr.reverse())}
                                            onClick={this.markerClick.bind(this, leg, value.legs)}

                                        />
                                    </Layer>
                                }
                                if (leg.mode.name === "bus" && leg.path.lineString) {

                                    return <Layer type="line" layout={lineLayout} paint={linePaint_bus}>

                                        <Feature
                                            key={4}
                                            coordinates={JSON.parse(leg.path.lineString).map(arr => arr.reverse())}
                                            onClick={this.markerClick.bind(this, leg, value.legs)}

                                        />
                                    </Layer>
                                }
                                else {
                                    return <Layer type="line" layout={lineLayout} paint={linePaint}>

                                        <Feature
                                            key={4}
                                            coordinates={leg.path.lineString && JSON.parse(leg.path.lineString).map(arr => arr.reverse())}
                                            onClick={this.markerClick.bind(this, leg, value.legs)}
                                        />
                                    </Layer>
                                }
                            })
                        })
                    }

                    {userPopup && (
                        <Popup coordinates={coord}>
                            <StyledPopup>
                                <div className='cross'>
                                    <div>Mode: {mode}</div>
                                    <CloseOutlined onClick={() => this.setState({ userPopup: false })} />
                                </div>


                                <div>
                                    Summary: {summary}
                                </div>
                            </StyledPopup>
                        </Popup>
                    )}
                    {google_userPopup && (
                        <Popup coordinates={google_coord}>
                            <StyledPopup>
                                <div className='cross'>
                                    <div>Mode: {google_mode}</div>
                                    <CloseOutlined onClick={() => this.setState({ google_userPopup: false })} />
                                </div>


                                <div>
                                    Summary: {google_summary}
                                </div>
                            </StyledPopup>
                        </Popup>
                    )}
                </Map>
                {routePopup && (

                    <NavCardInfo routeType="TFL" routeInfo={Tfl_legs} close={() => this.closeCard('TFL')} />

                )}
                {google_routePopup && (

                    <NavCardInfo routeType="GOOGLE" routeInfo={Google_legs} close={() => this.closeCard('GOOGLE')} />

                )}
            </Container>
        )
    }
}

const mapStateToProps = state => {
    return {
    
        isAuthenticated: state.authReducer.email !== null,
        gamificationLoading: state.gamificationReducer.gamificationLoading,
        disability: state.dataCollectionReducer.disability,
        getProfileSuccess: state.profileReducer.getProfileSuccess,
        getProfileBusSuccess: state.profileReducer.getProfileBusSuccess
  
    }
  }
  const mapDispatchToProps = dispatch => {
    return {
      setRecord: (data) => dispatch(actions.setRecord(data)),
  
    }
  }

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationMap))