import React, { Fragment, useState, useEffect } from 'react';
import { PageHeader, Select, Spin, Card, Dropdown, Tooltip, Button } from 'antd';
import NavigationMap from './NavigationMap';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import NavGeneralCard from './NavGeneralCard';
import CM from '../../assets/images/tfl.png'
import GM from '../../assets/images/googleMaps.svg'
import {
    ArrowUpOutlined
  } from '@ant-design/icons';
import './navigation.css'
import { GApageView} from '../../utils/index';


const { Option } = Select;



const Navigation = (props) => {
    const [dataA, setDataA] = useState([]);
    const [fetchingA, setFetchingA] = useState(false);
    const [valueA, setValueA] = useState([]);
    const [searchA, setSearchA] = useState(false);
    const [latA, setLatA] = useState(0);
    const [lngA, setLngA] = useState(0);
    const [autoSearchA, setAutoSearchA] = useState(false);

    const [dataB, setDataB] = useState([]);
    const [fetchingB, setFetchingB] = useState(false);
    const [valueB, setValueB] = useState([]);
    const [searchB, setSearchB] = useState(false);
    const [latB, setLatB] = useState(0);
    const [lngB, setLngB] = useState(0);
    const [autoSearchB, setAutoSearchB] = useState(false);


    const [journey, setJourney] = useState([])
    const [full_journey, setFullJourney] = useState([])
    const [google_journey, setGoogleJourney] = useState([])
    const [visibilityTFL, setTFLVisibility] = useState(false);
    const [TFLRouteCard, setTFLRouteCard] = useState(false);
    const [GOOGLERouteCard, setGOOGLERouteCard] = useState(false);
    const [visibilityGoogle, setGoogleVisibility] = useState(false);
    const [Nav_here, setNav_here] = useState(false);

    const [defaultV, setDefaultV] = useState(false);



    const [poiDataA, setPoiDataA] = useState([]);
    const [GpoiDataA, setGPoiDataA] = useState([])
    const [OpoiDataA, setOPoiDataA] = useState([])
    const [RpoiDataA, setRPoiDataA] = useState([])
    const [PpoiDataA, setPPoiDataA] = useState([])


    const [poiDataB, setPoiDataB] = useState([]);
    const [GpoiDataB, setGPoiDataB] = useState([])
    const [OpoiDataB, setOPoiDataB] = useState([])
    const [RpoiDataB, setRPoiDataB] = useState([])
    const [PpoiDataB, setPPoiDataB] = useState([])



    useEffect(() => {
        GApageView(props.location.pathname); 

        automaticFetch()
        props.history.replace({
            pathname: `/navigation`,
            state: {}
        })
    }, [props.location.state && props.location.state.label])

    useEffect(() => {
        if (props.isAuthenticated) {
            props.getProfile(res => {
                if (!res) {

                } else {

                }
            })

            props.getProfileGamm(res => {
                if (!res) {

                } else {

                }
            })

        }
    }, [])


    useEffect(() => {
        if (valueA.length === 0 && props.accessSearchA.key && props.accessSearchA.key.length > 0) {
            handleChangeA(props.accessSearchA)
        } if (valueB.length === 0 && props.accessSearchB.key && props.accessSearchB.key.length > 0) {
            handleChangeB(props.accessSearchB)
        }
    }, [])

    const automaticFetch = () => {
        let valueLabel = {}
        let label = '';
        let value = '';
        if (props.location.state && props.location.state.value) {
            setAutoSearchB(true)
            setAutoSearchA(true)
            let lat = props.location.state && props.location.state.value && props.location.state.value.split(",");
            setLatB(lat[1]);
            setLngB(lat[0]);
            setDataB([]);
            setNav_here(true);
            setFetchingB(false)
            setValueB(props.location.state)
            setSearchB(true);
            fetchUserB(props.location.state && props.location.state.value);

            navigator.geolocation.getCurrentPosition((position) => {
                let val = `${position.coords.longitude}, ${position.coords.latitude}`

                props.getLocation(val, res => {
                    if (!res) {

                    } else {
                        const results = [];
                        res.map(val => {
                            results.push({
                                loc: val.center,
                                title: val.place_name
                            })
                        })

                        label = results[0].title;
                        value = val;

                        valueLabel = [
                            label,
                            value,
                        ]


                        setLatA(results[0].loc[1]);
                        setLngA(results[0].loc[0]);
                        setDataA([]);
                        setFetchingA(false)
                        setSearchA(true);
                        fetchUserA(val);
                        setValueA(valueLabel)


                    }
                })
            })
        }
    }

    const fetchUserA = value => {
        setValueA(valueA)
        setDataA([]);
        setFetchingA(true)


        props.getLocation(value, res => {
            if (!res) {
                setDataA([]);
                setFetchingA(false)
                setSearchA(false)

            } else {
                const results = [];
                res.map(val => {
                    results.push({
                        loc: val.center,
                        title: val.place_name
                    })
                })
                setDataA(results);
                setFetchingA(false)
                setSearchA(false)

            }

        })

    };

    const handleChangeA = (value) => {
        let lat = value && value.key.split(",");
        setLatA(lat[1]);
        setLngA(lat[0]);
        setSearchA(true);

        setDataA([]);
        setFetchingA(false)
        setValueA(value)
        props.saveSearchA(value);
        const position = { lat: lat[1], lng: lat[0] }

        props.getPoi(position, res => {
            if (!res) {

            } else {
                setPoiDataA(res);
                let green = [];
                let red = [];
                let orange = [];
                let purple = [];

                console.log(props.disability);
                if (props.isAuthenticated) {
                    props.getProfileSuccess &&
                        props.getProfileSuccess.user_disability &&
                        props.getProfileSuccess.user_disability.map(disPref => {
                            if (Object.values(disPref).indexOf('wheelchair') >= 0) {
                                Object.keys(res.place_infos).map(val => {
                                    if (res.place_infos[val].accessibility_cat) {
                                        if (res.place_infos[val].accessibility_cat === 'wheelchair' && res.place_infos[val].accessibility_value === true) {
                                            green.push(res.place_infos[val])
                                        } else if (res.place_infos[val].accessibility_cat === 'partial_wheelchair' && res.place_infos[val].accessibility_value === true) {
                                            orange.push(res.place_infos[val])

                                        } else {
                                            red.push(res.place_infos[val])
                                        }
                                    }
                                });
                                Object.keys(res.historical_preds).map(val => {
                                    purple.push(res.historical_preds[val])

                                });
                                Object.keys(res.knn_current_preds).map(val => {
                                    purple.push(res.knn_current_preds[val])

                                });
                            } if (Object.values(disPref).indexOf('autism') >= 0) {
                                Object.keys(res.place_infos).map(val => {
                                    if (res.place_infos[val].accessibility_cat) {
                                        if (res.place_infos[val].accessibility_cat === 'autism' && res.place_infos[val].accessibility_value === true) {
                                            green.push(res.place_infos[val])
                                        } else if (res.place_infos[val].accessibility_cat === 'partial_autism' && res.place_infos[val].accessibility_value === true) {
                                            orange.push(res.place_infos[val])

                                        } else {
                                            red.push(res.place_infos[val])
                                        }
                                    }
                                });
                                Object.keys(res.historical_preds).map(val => {
                                    purple.push(res.historical_preds[val])

                                });
                                Object.keys(res.knn_current_preds).map(val => {
                                    purple.push(res.knn_current_preds[val])

                                });


                            }

                        }

                        )

                } else {
                    props.disability && props.disability.map(disPref => {
                        if (Object.values(disPref).indexOf('wheelchair') >= 0) {
                            Object.keys(res.place_infos).map(val => {
                                if (res.place_infos[val].accessibility_cat) {
                                    if (res.place_infos[val].accessibility_cat === 'wheelchair' && res.place_infos[val].accessibility_value === true) {
                                        green.push(res.place_infos[val])
                                    } else if (res.place_infos[val].accessibility_cat === 'partial_wheelchair' && res.place_infos[val].accessibility_value === true) {
                                        orange.push(res.place_infos[val])

                                    } else {
                                        red.push(res.place_infos[val])
                                    }
                                }
                            });
                            Object.keys(res.historical_preds).map(val => {
                                purple.push(res.historical_preds[val])

                            });
                            Object.keys(res.knn_current_preds).map(val => {
                                purple.push(res.knn_current_preds[val])

                            });
                        } if (Object.values(disPref).indexOf('autism') >= 0) {

                            Object.keys(res.place_infos).map(val => {
                                if (res.place_infos[val].accessibility_cat) {
                                    if (res.place_infos[val].accessibility_cat === 'autism' && res.place_infos[val].accessibility_value === true) {
                                        green.push(res.place_infos[val])
                                    } else if (res.place_infos[val].accessibility_cat === 'partial_autism' && res.place_infos[val].accessibility_value === true) {
                                        orange.push(res.place_infos[val])

                                    } else {
                                        red.push(res.place_infos[val])
                                    }
                                }
                            });
                            Object.keys(res.historical_preds).map(val => {
                                purple.push(res.historical_preds[val])

                            });
                            Object.keys(res.knn_current_preds).map(val => {
                                purple.push(res.knn_current_preds[val])

                            });
                        }

                    })
                }
                console.log('purple')
                console.log(purple)

                setGPoiDataA(green);
                setOPoiDataA(orange);
                setRPoiDataA(red)
                setPPoiDataA(purple)

            }
        })


    };

    const onChangeA = value => {
        setValueA(value)
        setNav_here(false);


    }

    const handleSearchA = () => {
        setSearchA(false);

    }


    const fetchUserB = value => {
        setValueB(value)
        setDataB([]);
        setFetchingB(true)


        props.getLocation(value, res => {
            if (!res) {
                setDataB([]);
                setFetchingB(false)
                setSearchB(false)

            } else {
                const results = [];
                res.map(val => {
                    results.push({
                        loc: val.center,
                        title: val.place_name
                    })
                })
                setDataB(results);
                setFetchingB(false)
                setSearchB(false)

            }

        })

    };

    const handleChangeB = (value) => {
        let lat = value && value.key.split(",");
        console.log(lat);
        setLatB(lat[1]);
        setLngB(lat[0]);
        setDataB([]);
        setFetchingB(false)
        setValueB(value)
        setSearchB(true);
        props.saveSearchB(value);
        handleTFl('default');


        const position = { lat: lat[1], lng: lat[0] }

        props.getPoi(position, res => {
            if (!res) {

            } else {
                setPoiDataB(res);
                let green = [];
                let red = [];
                let orange = [];
                let purple = [];

                console.log(props.disability);
                if (props.isAuthenticated) {
                    props.getProfileSuccess &&
                        props.getProfileSuccess.user_disability &&
                        props.getProfileSuccess.user_disability.map(disPref => {
                            if (Object.values(disPref).indexOf('wheelchair') >= 0) {
                                Object.keys(res.place_infos).map(val => {
                                    if (res.place_infos[val].accessibility_cat) {
                                        if (res.place_infos[val].accessibility_cat === 'wheelchair' && res.place_infos[val].accessibility_value === true) {
                                            green.push(res.place_infos[val])
                                        } else if (res.place_infos[val].accessibility_cat === 'partial_wheelchair' && res.place_infos[val].accessibility_value === true) {
                                            orange.push(res.place_infos[val])

                                        } else {
                                            red.push(res.place_infos[val])
                                        }
                                    }
                                });
                                Object.keys(res.historical_preds).map(val => {
                                    purple.push(res.historical_preds[val])

                                });
                                Object.keys(res.knn_current_preds).map(val => {
                                    purple.push(res.knn_current_preds[val])

                                });
                            } if (Object.values(disPref).indexOf('autism') >= 0) {
                                Object.keys(res.place_infos).map(val => {
                                    if (res.place_infos[val].accessibility_cat) {
                                        if (res.place_infos[val].accessibility_cat === 'autism' && res.place_infos[val].accessibility_value === true) {
                                            green.push(res.place_infos[val])
                                        } else if (res.place_infos[val].accessibility_cat === 'partial_autism' && res.place_infos[val].accessibility_value === true) {
                                            orange.push(res.place_infos[val])

                                        } else {
                                            red.push(res.place_infos[val])
                                        }
                                    }
                                });
                                Object.keys(res.historical_preds).map(val => {
                                    purple.push(res.historical_preds[val])

                                });
                                Object.keys(res.knn_current_preds).map(val => {
                                    purple.push(res.knn_current_preds[val])

                                });


                            }

                        }

                        )

                } else {
                    props.disability && props.disability.map(disPref => {
                        if (Object.values(disPref).indexOf('wheelchair') >= 0) {
                            Object.keys(res.place_infos).map(val => {
                                if (res.place_infos[val].accessibility_cat) {
                                    if (res.place_infos[val].accessibility_cat === 'wheelchair' && res.place_infos[val].accessibility_value === true) {
                                        green.push(res.place_infos[val])
                                    } else if (res.place_infos[val].accessibility_cat === 'partial_wheelchair' && res.place_infos[val].accessibility_value === true) {
                                        orange.push(res.place_infos[val])

                                    } else {
                                        red.push(res.place_infos[val])
                                    }
                                }
                            });
                            Object.keys(res.historical_preds).map(val => {
                                purple.push(res.historical_preds[val])

                            });
                            Object.keys(res.knn_current_preds).map(val => {
                                purple.push(res.knn_current_preds[val])

                            });
                        } if (Object.values(disPref).indexOf('autism') >= 0) {

                            Object.keys(res.place_infos).map(val => {
                                if (res.place_infos[val].accessibility_cat) {
                                    if (res.place_infos[val].accessibility_cat === 'autism' && res.place_infos[val].accessibility_value === true) {
                                        green.push(res.place_infos[val])
                                    } else if (res.place_infos[val].accessibility_cat === 'partial_autism' && res.place_infos[val].accessibility_value === true) {
                                        orange.push(res.place_infos[val])

                                    } else {
                                        red.push(res.place_infos[val])
                                    }
                                }
                            });
                            Object.keys(res.historical_preds).map(val => {
                                purple.push(res.historical_preds[val])

                            });
                            Object.keys(res.knn_current_preds).map(val => {
                                purple.push(res.knn_current_preds[val])

                            });
                        }

                    })
                }
                console.log('purple')
                console.log(purple)

                setGPoiDataB(green);
                setOPoiDataB(orange);
                setRPoiDataB(red)
                setPPoiDataB(purple)

            }
        })





    };

    const onChangeB = value => {
        setValueB(value)
        setAutoSearchB(false)
        setNav_here(false);

    }

    const handleSearchB = () => {
        setSearchB(false);

    }

    const handleTFl = (event = '') => {
        setTFLRouteCard(false);
        setGOOGLERouteCard(false);
        var accessibilityPreference;
        var disability_arr;
        if (props.isAuthenticated && sessionStorage.getItem('user_disability')) {
            disability_arr =props.getProfileSuccess && props.getProfileSuccess.user_disability && props.getProfileSuccess.user_disability[0]
        }
        else if (props.disability) {
            disability_arr = props.disability[0]
        }

        if (disability_arr && disability_arr.indexOf('wheelchair') > -1) {
            accessibilityPreference = 'noSolidStairs,stepFreeToPlatform,stepFreeToVehicle,noEscalators'
        }
        else if (disability_arr && disability_arr.indexOf('visual') > -1) {
            accessibilityPreference = 'noSolidStairs,noEscalators'
        }
        else {
            accessibilityPreference = ''
        }

        setTFLVisibility(true);
        setGoogleVisibility(false);
        if (google_journey) {
            setGoogleJourney(null)
        }
        var data;
        if (event != '' ) {
            if (event === 'default'){
            setDefaultV(true)
                data = {
                    'latA': latA,
                    'lngA': lngA,
                    'latB': latB,
                    'lngB': lngB,
                    'journeyPreference': 'leastinterchange',
                    'accessibilityPreference': accessibilityPreference
                }
            } else {
                setDefaultV(false)

            data = {
                'latA': latA,
                'lngA': lngA,
                'latB': latB,
                'lngB': lngB,
                'journeyPreference': event.target.value,
                'accessibilityPreference': accessibilityPreference
            }}
        } 
        else {
            data = {
                'latA': latA,
                'lngA': lngA,
                'latB': latB,
                'lngB': lngB,
                'journeyPreference': event,
                'accessibilityPreference': accessibilityPreference
            }
        }


        props.getTFL(data, res => {
            if (!res) {

            } else {
                let array = []
                let finalArray = []
                if (res.journeys[0].legs) {
                    for (let leg in res.journeys[0].legs) {
                        array = JSON.parse(res.journeys[0].legs[leg].path.lineString).map(arr => arr.reverse())
                        finalArray = finalArray.concat(array)

                    }

                    setFullJourney(res.journeys)
                    setJourney(res.journeys[0].legs)
                    setTFLRouteCard(true);

                }

            }
        })
    }

    const handleGOOGLE = (event = '') => {
        setTFLRouteCard(false);
        setGOOGLERouteCard(false);
        if (full_journey) {
            // remove TFl journey
            setFullJourney(null)
            setJourney(null)
        }
        var data;
        if (event != '') {
            data = {
                'latA': latA,
                'lngA': lngA,
                'latB': latB,
                'lngB': lngB,
                'routingPreference': event.target.value

            }
        }
        else {
            data = {
                'latA': latA,
                'lngA': lngA,
                'latB': latB,
                'lngB': lngB,
                'routingPreference': event

            }

        }

        props.getGOOGLE(data, res => {
            setTFLVisibility(false);
            setGoogleVisibility(true);
            if (!res) {

                console.log('google did not return response')
            } else {
                setGoogleJourney(res.routes)
                setGOOGLERouteCard(true);
                console.log(res.routes)
            }
        })
    }
    // currently not being used
    const handleTFlRouteChange = (event) => {
        const train_arr = ['dlr', 'national-rail', 'overground', 'tflrail']
        journey:
        for (let journey in full_journey) {
            legs:
            for (let leg in full_journey[journey].legs) {
                if (event.target.value == "train") {

                    if (train_arr.indexOf(full_journey[journey].legs[leg].mode.name) > -1) {
                        console.log(full_journey[journey].legs[leg].mode.name)
                        //setJourney(null)
                        setJourney(full_journey[journey].legs)
                        break journey;
                    }


                }
                if (full_journey[journey].legs[leg].mode.name == event.target.value) {
                    console.log(full_journey[journey].legs[leg].mode.name)
                    //setJourney(null)
                    setJourney(full_journey[journey].legs)
                    break journey;
                }
            }
        }
        //console.log(event.target.value);
        console.log(full_journey);

    }

    const closeCard = (route) => {
        if (route == "TFL") {
            setTFLRouteCard(false)
        }
        else {
            setGOOGLERouteCard(false)
        }
    }

    return (
        <Fragment>
            <div className='nav-style'>
                <div className='map-style'>
                    <NavigationMap
                        latA={latA}
                        lngA={lngA}
                        searchA={searchA}

                        latB={latB}
                        lngB={lngB}
                        searchB={searchB}

                        journey={journey}
                        fullJourney={full_journey}
                        journeyGoogle={google_journey}
                        Nav_here={Nav_here}

                        GpoiDataA={GpoiDataA}
                        RpoiDataA={RpoiDataA}
                        OpoiDataA={OpoiDataA}
                        PpoiDataA={PpoiDataA}


                        GpoiDataB={GpoiDataB}
                        RpoiDataB={RpoiDataB}
                        OpoiDataB={OpoiDataB}
                        PpoiDataB={PpoiDataB}

                        valueA={valueA}
                        valueB={valueB}

                    />
                </div>
                <div className='se-dt'>
                    <div className='search-div'>

                        <Select
                            showSearch
                            labelInValue={autoSearchA ? false : true}
                            value={valueA}
                            placeholder="Select a location A"
                            notFoundContent={fetchingA ? <Spin size="small" /> : null}
                            showArrow={false}
                            filterOption={false}
                            onSearch={fetchUserA}
                            onSelect={handleChangeA}
                            onChange={onChangeA}
                            onClick={() => setAutoSearchA(false)}
                            className='search'
                        >
                            {dataA.map(d => (
                                <Option key={d.loc}>{d.title}</Option>
                            ))}
                        </Select>



                    </div>

                    <div className='search-div'>

                        <Select
                            showSearch
                            labelInValue={autoSearchB ? false : true}
                            value={valueB}
                            placeholder="Select a location B"
                            notFoundContent={fetchingB ? <Spin size="small" /> : null}
                            showArrow={false}
                            filterOption={false}
                            onSearch={fetchUserB}
                            onSelect={handleChangeB}
                            onChange={onChangeB}
                            onClick={() => setAutoSearchB(false)}
                            className='search'
                        >
                            {dataB.map(d => (
                                <Option key={d.loc}>{d.title}</Option>
                            ))}
                        </Select>
                    </div>
                    <div>
                        <Button className='route-but border-left' disabled>Choose Route</Button>
                        <Button className='route-but' onClick={() => handleTFl()}><img src={CM} className='sm-tf'/></Button>
                        <Button className='route-but border-right' onClick={() => handleGOOGLE()}><img src={GM} className='sm-im' /></Button>
                    </div>

                    {visibilityTFL &&
                    <>
                        <div onChange={handleTFl} className='route-switch'>
                            <input type="radio" id="leastinterchange" name="route-switch" value="leastinterchange" />
                            <label for="leastinterchange">Least Interchange</label>
                            <input type="radio" id="leasttime" name="route-switch" value="leasttime" />
                            <label for="leasttime">Least Time</label>
                            <input type="radio" id="leastwalking" name="route-switch" value="leastwalking" />
                            <label for="leastwalking">Least Walking</label>
                        </div>
                        </>


                    }

                        
                    {visibilityTFL && defaultV && <span style={{position:'absolute', color:'#7a68aa', fontSize: 60, display:'flex', alignItems:'center', top:180}}><ArrowUpOutlined  className='route-switch' />Click me</span>}
                    {visibilityGoogle &&
                        <div onChange={handleGOOGLE} className='route-switch'>
                            <input type="radio" id="fewertransfers" name="route-switch" value="FEWER_TRANSFERS" />
                            <label for="fewertransfers">Fewer Transfers</label>
                            <input type="radio" id="lesswalking" name="route-switch" value="LESS_WALKING" />
                            <label for="lesswalking">Less Walking</label>


                        </div>
                    }

                </div>

            </div>
            {TFLRouteCard &&
                <NavGeneralCard routeType="TFL" routeInfo={full_journey} close={() => closeCard('TFL')} />
            }
            {GOOGLERouteCard &&
                <NavGeneralCard routeType="GOOGLE" routeInfo={google_journey} close={() => closeCard('GOOGLE')} />
            }
        </Fragment>
    )
}

const mapStateToProps = state => {
    return {
        getLocationLoading: state.accessibilityReducer.getLocationLoading,
        getLocationSuccess: state.accessibilityReducer.getLocationSuccess,
        getProfileSuccess: state.profileReducer.getProfileSuccess,
        isAuthenticated: state.authReducer.email !== null,
        disability: state.dataCollectionReducer.disability,
        accessSearchA: state.accessibilityReducer.accessSearchA,
        accessSearchB: state.accessibilityReducer.accessSearchB,




    }
}
const mapDispatchToProps = dispatch => {
    return {
        getLocation: (data, res) => dispatch(actions.getLocation(data, res)),
        getTFL: (data, res) => dispatch(actions.getTFL(data, res)),
        getGOOGLE: (data, res) => dispatch(actions.getGOOGLE(data, res)),
        getProfile: (res) => dispatch(actions.getProfile(res)),
        saveSearchA: (data) => dispatch(actions.saveSearchA(data)),
        saveSearchB: (data) => dispatch(actions.saveSearchB(data)),
        getProfileGamm: (res) => dispatch(actions.getProfileGamm(res)),
        getPoi: (data, res) => dispatch(actions.getPoi(data, res)),



    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Navigation))