import React, { useState } from 'react';
import { Form, Input, Button, Radio, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/images/citymaasai1.png';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../Resusable/Notification/index';
import {
    CheckCircleOutlined
} from '@ant-design/icons';
import './ForgotPassword.css';

const ForgotPassword = (props) => {
    const [verifyMsg, setVerifyMsg] = useState('')
    const [showMsg, setShowMsg] = useState(false);
    const [loading, setLoading] = useState(false)
    const [bus, setBus] = useState('Individual');


    const onFinish = values => {
        setLoading(true)

        const credentials = {
            email: values.email,
            bus:bus

        };

        props.forgotPassword(credentials, res => {
            if (res.status === 200) {
                setVerifyMsg(res.data.message);
                setShowMsg(true)
                setLoading(false)


            } else {
                Notification('error', res.message);
                setLoading(false)

            }
        });
    };

    const onChangeBusiness = e => {
        console.log('radio checked', e.target.value);
        setBus(e.target.value)
      }
    return (
        <div className='sign-in-bg'>
            <div className='sign-in-div'>
                <div className='sign-in-img'>
                    <div style={{ width: '500px', height: '270px' }}>
                        <img src={logo} width={500} alt='' />
                    </div>
                    <Card className='sign-in-card'>
                        {showMsg ?
                            <div>
                                <div className='fp-head'>
                                    <CheckCircleOutlined className='fp-icon' />
                                    <h1 className='fp-header'>Email Sent</h1>
                                </div>
                                <p className='fp-det'>{verifyMsg}</p>

                          Remember password? <Link to="/sign-in">Sign In!</Link>

                                <div>
                                    Or <Link to="/">Go Back</Link>
                                </div>
                            </div>
                            : <Form
                                name="normal_login"
                                className="login-form"
                                initialValues={{ remember: true }}
                                onFinish={onFinish}
                                labelAlign='left'
                                layout='vertical'
                            >
                                <Form.Item
                                    label='Email'
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your email!' }]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                                </Form.Item>

                                <Form.Item
                                    label='Please select your status'
                                >
                                    <Radio.Group onChange={onChangeBusiness} value={bus}>
                                        <Radio value='Individual'>Individual</Radio>
                                        <Radio value='Business'>Business</Radio>
                                    </Radio.Group>
                                </Form.Item>

                                <Form.Item>
                                    <div className='sign-in-div  mr-b-10' >
                                        <Button type="primary" htmlType="submit" className="sign-in-button" loading={loading}>
                                            Send Email
                                </Button>
                                    </div>
                                Do you want to? <Link to="/sign-in">Sign In!</Link>

                                    <div>
                                        Or <Link to="/">Go Back</Link>
                                    </div>
                                </Form.Item>
                            </Form>}
                    </Card>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.email !== null,


    }
}
const mapDispatchToProps = dispatch => {
    return {
        forgotPassword: (credentials, res) => dispatch(actions.forgotPassword(credentials, res))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);