import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  getLocationLoading: false,
  getLocationSuccess: null,
  getLocationFailure: null,

  getBusLoading: false,
  getBusSuccess: null,
  getBusFailure: null,

  getTrainLoading: false,
  getTrainSuccess: null,
  getTrainFailure: null,

  getPoiLoading: false,
  getPoiSuccess: null,
  getPoiFailure: null,

  addSavedLoading: false,
  addSavedSuccess: null,
  addSavedFailure: null,

  addWorkHomeLoading: false,
  addWorkHomeSuccess: null,
  addWorkHomeFailure: null,

  addSearchLoading: false,
  addSearchSuccess: null,
  addSearchFailure: null,

  welcome: true,

  getDLRLoading: false,
  getDLRSuccess: null,
  getDLRFailure: null,

  accessSearch: [],
  accessSearchA: [],
  accessSearchB: [],

  setRecordLoading: false,
  setRecordSuccess: null,
  setRecordFailure: null,

  getBUSINESSLoading: false,
  getBUSINESSSuccess: null,
  getBUSINESSFailure: null,

  getSearchCatLoading: false,
  getSearchCatSuccess: null,
  getSearchCatFailure: null,

  getCordToWordsLoading: false,
  getCordToWordsSuccess: null,
  getCordToWordsFailure: null,
};

const getLocationFailure = (state, action) => {
  return updateObject(state, {
    getLocationFailure: action.data,
    getLocationLoading: false,
  });
};
const getLocationSuccess = (state, action) => {
  return updateObject(state, {
    getLocationSuccess: action.data,
    getLocationFailure: false,
  });
};

const getLocationLoading = (state, action) => {
  return updateObject(state, {
    getLocationLoading: action.data,
    getLocationFailure: null,
    getLocationSuccess: false,
  });
};

//get bus

const getBusFailure = (state, action) => {
  return updateObject(state, {
    getBusFailure: action.data,
    getBusLoading: false,
  });
};
const getBusSuccess = (state, action) => {
  return updateObject(state, {
    getBusSuccess: action.data,
    getBusFailure: false,
  });
};

const getBusLoading = (state, action) => {
  return updateObject(state, {
    getBusLoading: action.data,
    getBusFailure: null,
    getBusSuccess: false,
  });
};

//get bus

const getCordToWordsFailure = (state, action) => {
  return updateObject(state, {
    getCordToWordsFailure: action.data,
    getCordToWordsLoading: false,
  });
};
const getCordToWordsSuccess = (state, action) => {
  return updateObject(state, {
    getCordToWordsSuccess: action.data,
    getCordToWordsFailure: false,
  });
};

const getCordToWordsLoading = (state, action) => {
  return updateObject(state, {
    getCordToWordsLoading: action.data,
    getCordToWordsFailure: null,
    getCordToWordsSuccess: false,
  });
};
//get train

const getTrainFailure = (state, action) => {
  return updateObject(state, {
    getTrainFailure: action.data,
    getTrainLoading: false,
  });
};
const getTrainSuccess = (state, action) => {
  return updateObject(state, {
    getTrainSuccess: action.data,
    getTrainFailure: false,
  });
};

const getTrainLoading = (state, action) => {
  return updateObject(state, {
    getTrainLoading: action.data,
    getTrainFailure: null,
    getTrainSuccess: false,
  });
};

//get train

const getPoiFailure = (state, action) => {
  return updateObject(state, {
    getPoiFailure: action.data,
    getPoiLoading: false,
  });
};
const getPoiSuccess = (state, action) => {
  return updateObject(state, {
    getPoiSuccess: action.data,
    getPoiFailure: false,
  });
};

const getPoiLoading = (state, action) => {
  return updateObject(state, {
    getPoiLoading: action.data,
    getPoiFailure: null,
    getPoiSuccess: false,
  });
};

//

const addSavedFailure = (state, action) => {
  return updateObject(state, {
    addSavedFailure: action.data,
    addSavedLoading: false,
  });
};
const addSavedSuccess = (state, action) => {
  return updateObject(state, {
    addSavedSuccess: action.data,
    addSavedFailure: false,
  });
};

const addSavedLoading = (state, action) => {
  return updateObject(state, {
    addSavedLoading: action.data,
    addSavedFailure: null,
    addSavedSuccess: false,
  });
};

//

const addWorkHomeFailure = (state, action) => {
  return updateObject(state, {
    addWorkHomeFailure: action.data,
    addWorkHomeLoading: false,
  });
};
const addWorkHomeSuccess = (state, action) => {
  return updateObject(state, {
    addWorkHomeSuccess: action.data,
    addWorkHomeFailure: false,
  });
};

const addWorkHomeLoading = (state, action) => {
  return updateObject(state, {
    addWorkHomeLoading: action.data,
    addWorkHomeFailure: null,
    addWorkHomeSuccess: false,
  });
};
//

const setWelcome = (state, action) => {
  return updateObject(state, { welcome: false });
};

const setSerach = (state, action) => {
  return updateObject(state, { accessSearch: action.data });
};

//

const setSerachA = (state, action) => {
  return updateObject(state, { accessSearchA: action.data });
};

//
const setSerachB = (state, action) => {
  return updateObject(state, { accessSearchB: action.data });
};

const addSearchFailure = (state, action) => {
  return updateObject(state, {
    addSearchFailure: action.data,
    addSearchLoading: false,
  });
};
const addSearchSuccess = (state, action) => {
  return updateObject(state, {
    addSearchSuccess: action.data,
    addSearchFailure: false,
  });
};

const addSearchLoading = (state, action) => {
  return updateObject(state, {
    addSearchLoading: action.data,
    addSearchFailure: null,
    addSearchSuccess: false,
  });
};

//dlr

//get train

const getDLRFailure = (state, action) => {
  return updateObject(state, {
    getDLRFailure: action.data,
    getDLRLoading: false,
  });
};
const getDLRSuccess = (state, action) => {
  return updateObject(state, {
    getDLRSuccess: action.data,
    getDLRFailure: false,
  });
};

const getDLRLoading = (state, action) => {
  return updateObject(state, {
    getDLRLoading: action.data,
    getDLRFailure: null,
    getDLRSuccess: false,
  });
};

//add search

const getBUSINESSFailure = (state, action) => {
  return updateObject(state, {
    getBUSINESSFailure: action.data,
    getBUSINESSLoading: false,
  });
};
const getBUSINESSSuccess = (state, action) => {
  return updateObject(state, {
    getBUSINESSSuccess: action.data,
    getBUSINESSFailure: false,
  });
};

const getBUSINESSLoading = (state, action) => {
  return updateObject(state, {
    getBUSINESSLoading: action.data,
    getBUSINESSFailure: null,
    getBUSINESSSuccess: false,
  });
};

const setRecordFailure = (state, action) => {
  return updateObject(state, {
    setRecordFailure: action.data,
    setRecordLoading: false,
  });
};
const setRecordSuccess = (state, action) => {
  return updateObject(state, {
    setRecordSuccess: action.data,
    setRecordFailure: false,
  });
};

const setRecordLoading = (state, action) => {
  return updateObject(state, {
    setRecordLoading: action.data,
    setRecordFailure: null,
    setRecordSuccess: false,
  });
};

//category

const getSearchCatFailure = (state, action) => {
  return updateObject(state, {
    getSearchCatFailure: action.data,
    getSearchCatLoading: false,
  });
};
const getSearchCatSuccess = (state, action) => {
  return updateObject(state, {
    getSearchCatSuccess: action.data,
    getSearchCatFailure: false,
  });
};

const getSearchCatLoading = (state, action) => {
  return updateObject(state, {
    getSearchCatLoading: action.data,
    getSearchCatFailure: null,
    getSearchCatSuccess: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_LOCATION_FAILURE:
      return getLocationFailure(state, action);
    case actionTypes.GET_LOCATION_SUCCESS:
      return getLocationSuccess(state, action);
    case actionTypes.GET_LOCATION_LOADING:
      return getLocationLoading(state, action);

    case actionTypes.GET_BUS_FAILURE:
      return getBusFailure(state, action);
    case actionTypes.GET_BUS_SUCCESS:
      return getBusSuccess(state, action);
    case actionTypes.GET_BUS_LOADING:
      return getBusLoading(state, action);

    case actionTypes.GET_CORDTOWORDS_FAILURE:
      return getCordToWordsFailure(state, action);
    case actionTypes.GET_CORDTOWORDS_SUCCESS:
      return getCordToWordsSuccess(state, action);
    case actionTypes.GET_CORDTOWORDS_LOADING:
      return getCordToWordsLoading(state, action);

    case actionTypes.GET_TRAIN_FAILURE:
      return getTrainFailure(state, action);
    case actionTypes.GET_TRAIN_SUCCESS:
      return getTrainSuccess(state, action);
    case actionTypes.GET_TRAIN_LOADING:
      return getTrainLoading(state, action);

    case actionTypes.GET_POI_FAILURE:
      return getPoiFailure(state, action);
    case actionTypes.GET_POI_SUCCESS:
      return getPoiSuccess(state, action);
    case actionTypes.GET_POI_LOADING:
      return getPoiLoading(state, action);

    case actionTypes.ADD_SAVED_FAILURE:
      return addSavedFailure(state, action);
    case actionTypes.ADD_SAVED_SUCCESS:
      return addSavedSuccess(state, action);
    case actionTypes.ADD_SAVED_LOADING:
      return addSavedLoading(state, action);

    case actionTypes.ADD_WORKHOME_FAILURE:
      return addWorkHomeFailure(state, action);
    case actionTypes.ADD_WORKHOME_SUCCESS:
      return addWorkHomeSuccess(state, action);
    case actionTypes.ADD_WORKHOME_LOADING:
      return addWorkHomeLoading(state, action);

    case actionTypes.ADD_SEARCH_FAILURE:
      return addSearchFailure(state, action);
    case actionTypes.ADD_SEARCH_SUCCESS:
      return addSearchSuccess(state, action);
    case actionTypes.ADD_SEARCH_LOADING:
      return addSearchLoading(state, action);

    case actionTypes.SET_RECORD_FAILURE:
      return setRecordFailure(state, action);
    case actionTypes.SET_RECORD_SUCCESS:
      return setRecordSuccess(state, action);
    case actionTypes.SET_RECORD_LOADING:
      return setRecordLoading(state, action);

    case actionTypes.WELCOME:
      return setWelcome(state, action);

    case actionTypes.SAVE_SEARCH:
      return setSerach(state, action);

    case actionTypes.SAVE_SEARCH_A:
      return setSerachA(state, action);

    case actionTypes.SAVE_SEARCH_B:
      return setSerachB(state, action);

    case actionTypes.GET_DLR_FAILURE:
      return getDLRFailure(state, action);
    case actionTypes.GET_DLR_SUCCESS:
      return getDLRSuccess(state, action);
    case actionTypes.GET_DLR_LOADING:
      return getDLRLoading(state, action);

    case actionTypes.GET_BUSINESS_FAILURE:
      return getBUSINESSFailure(state, action);
    case actionTypes.GET_BUSINESS_SUCCESS:
      return getBUSINESSSuccess(state, action);
    case actionTypes.GET_BUSINESS_LOADING:
      return getBUSINESSLoading(state, action);

    case actionTypes.GET_SEARCH_CAT_FAILURE:
      return getSearchCatFailure(state, action);
    case actionTypes.GET_SEARCH_CAT_SUCCESS:
      return getSearchCatSuccess(state, action);
    case actionTypes.GET_SEARCH_CAT_LOADING:
      return getSearchCatLoading(state, action);
    default:
      return state;
  }
};

export default reducer;
