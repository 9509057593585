import * as actionTypes from './actionTypes';
import axios from 'axios';
import config from '../../config';
import querystring from 'querystring';

export const dataCollectionStart = (data) => {

    return {
        type: actionTypes.DATA_COLLECTION_START,
        data: data
    };
}

export const dataCollectionSuccess = (data) => {
    return {
        type: actionTypes.DATA_COLLECTION_SUCCESS,
        data: data
    }
}

export const dataCollectionFail = (error) => {
    return {
        type: actionTypes.DATA_COLLECTION_FAIL,
        error: error
    }
}

export const dataCollection = (obj, callback) => {
    return dispatch => {
        dispatch(dataCollectionStart(true));
        const  user_data = {
           data: {
                platform: obj.platform,
                screen_width: obj.screen_width.toString(),
                screen_height: obj.screen_height.toString(),
                client_screen_x: obj.client_screen_x.toString(),
                client_screen_y: obj.client_screen_y.toString(),
                interaction_timestamp: obj.interaction_timestamp.toString(),
                user_email: obj.user_email,
                event_type:obj.event_type.toString(),
            }
        }

        axios.post(`${config.adminApi}/userinteractiondata`, user_data)
            .then(response => {
                dispatch(dataCollectionStart(true));
                dispatch(dataCollectionSuccess(response.data));
                dispatch(dataCollectionStart(false));

                callback(true);
            })
            .catch(err => {
                console.log(err);
                dispatch(dataCollectionFail(err));
                callback(false);
                dispatch(dataCollectionStart(false));


            })
    }
}

//disability

export const disability = data => {
    return {
        type: actionTypes.SET_DISABILITY,
        data: data


    }

}

export const setDisability = (data) => {
    return dispatch => {
        dispatch(disability(data));
     
    }

}