import React, { useState } from 'react';
import { Form, Input, Button, Radio, Card, Upload, message } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined, TeamOutlined, UploadOutlined } from '@ant-design/icons';
import logo from '../../assets/images/citymaasai1.png';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import Notification from '../Resusable/Notification/index';
import {
    CheckCircleOutlined
} from '@ant-design/icons';
import './signup.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';


const SignUp = (props) => {
    const [verifyMsg, setVerifyMsg] = useState('');
    const [showMsg, setShowMsg] = useState(false);
    const [loading, setLoading] = useState(false);
    const [mode, setMode] = useState('individual');
    const [selectedFile, setSelectedFile] = useState(null);
    const [businessContact, setBusinessContact] = useState([]);


    const normFile = e => {
        console.log('Upload event:', e);
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    const onFinish = values => {
        setLoading(true)
        let credentials = {}
        if (mode === 'individual') {
            credentials = {
                email: values.email,
                password: values.password,
                last_request_timestamp: Math.round((new Date()).getTime() / 1000),
                mode: mode
            }
        } else {
            credentials = {
                email: values.email,
                password: values.password,
                last_request_timestamp: Math.round((new Date()).getTime() / 1000),
                mode: mode,
                business_name: values.business_name,
                business_contact_phone: businessContact,
                business_logo: values.business_logo,
                business_leaflet: values.business_leaflet

            }
        }
        props.onSignUp(credentials, res => {
            if (res.status === 200) {
                setVerifyMsg(res.data.message);
                setShowMsg(true)
                setLoading(false)

            } else {
                Notification('error', res.message);
                setLoading(false)

            }
        })

    };

    const handleModeChange = e => {
        const mode = e.target.value;
        setMode(mode)
    };


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      };
      


    let formDetails = '';

    if (mode === 'individual') {
        formDetails = (
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                labelAlign='left'
                layout='vertical'
            >

                <Form.Item
                    label='Email'
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>
                <Form.Item
                    label='Password'
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>




                <Form.Item>
                    <div className='sigin-up-div mr-b-10'>
                        <Button type="primary" htmlType="submit" className="sigin-up-button" loading={loading}>
                            Sign Up
            </Button>
                    </div>
            Have an account? <Link to="/sign-in">Sign In!</Link>

                    <div>
                        Or <Link to="/">Go Back</Link>
                    </div>
                </Form.Item>
            </Form>
        )
    } else {
        formDetails = (
            <Form
                name="normal_login"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                labelAlign='left'
                layout='vertical'
            >
                <Form.Item
                    label='Business Name'
                    name="business_name"
                    rules={[{ required: true, message: 'Please input your business name!' }]}
                >
                    <Input prefix={<TeamOutlined className="site-form-item-icon" />} placeholder="Business Name" />
                </Form.Item>

                <Form.Item
                    label='Business Contact'
                    name="business_contact_phone"
                    rules={[{ required: true, message: 'Please input your business number!' }]}
                >
                   <PhoneInput
                                country={'gb'}
                                value={businessContact}
                                // className='input-field' 
                                onChange={e => setBusinessContact(e)}
                            />
                </Form.Item>

                <Form.Item
                    label='Email'
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' }]}
                >
                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                </Form.Item>


                <Form.Item
                    label='Password'
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                    />
                </Form.Item>

                <Form.Item
                    name="business_logo"
                    label="Company Logo"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                    <Upload name="logo" customRequest={dummyRequest} listType="picture">
                        <Button>
                            <UploadOutlined /> Click to upload
                    </Button>
                    </Upload>
                </Form.Item>

                <Form.Item
                    name="business_leaflet"
                    label="Marketing Leaflet"
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                >
                   <Upload name="logo" customRequest={dummyRequest} listType="picture">
                        <Button>
                            <UploadOutlined /> Click to upload
                    </Button>
                    </Upload>
                </Form.Item>




                <Form.Item>
                    <div className='sigin-up-div mr-b-10'>
                        <Button type="primary" htmlType="submit" className="sigin-up-button" loading={loading}>
                            Sign Up
            </Button>
                    </div>
            Have an account? <Link to="/sign-in">Sign In!</Link>

                    <div>
                        Or <Link to="/">Go Back</Link>
                    </div>
                </Form.Item>
            </Form>
        )
    }

    return (
        <div className='sigin-up-bg'>
            <div className='sigin-up-div'>
                <div className='sigin-up-img'>
                    <div style={{ width: '500px', height: '270px' }}>
                        <img src={logo} width={500} alt='' />
                    </div>
                    <Card className='sigin-up-card mr-b-10'>
                        <h1 className='heading-signup'>Welcome to CityMaaS</h1>
                        {showMsg ?
                            <div>
                                <div className='fp-head'>
                                    <CheckCircleOutlined className='fp-icon' />
                                    <h1 className='fp-header'>Email Sent</h1>
                                </div>
                                <p className='fp-det'>{verifyMsg}</p>

                          Have an account? <Link to="/sign-in">Sign In!</Link>

                                <div>
                                    Or <Link to="/">Go Back</Link>
                                </div>
                            </div>
                            :
                            <>

                                <Radio.Group onChange={handleModeChange} value={mode} style={{ marginBottom: 8, display: 'flex' }}>
                                    <Radio.Button value="individual" style={{ width: '100%', textAlign: 'center' }}>Individual</Radio.Button>
                                    <Radio.Button value="business" style={{ width: '100%', textAlign: 'center' }}>Business</Radio.Button>
                                </Radio.Group>

                                {formDetails}
                            </>
                        }
                    </Card>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = state => {
    return {
        loading: state.authReducer.singUpLoading,
        error: state.authReducer.singUpError,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        onSignUp: (credentials, res) => dispatch(actions.signUp(credentials, res))
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(SignUp);