import React from 'react';
import { Layout, Menu, Avatar, Typography, Button } from 'antd';
import logo from '../../assets/images/icon.png';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import {
    LogoutOutlined,
    EyeOutlined,
    UserOutlined
} from '@ant-design/icons';
import Coin from '../../assets/images/coin.svg';
import './topbar.css'
const { SubMenu } = Menu;
const { Header } = Layout;

const Topbar = (props) => {
    return (
        <Header className="header">
            <div className="logo">
                <div className="title">
                    <img src={logo} alt='' className='img-lg' />
                    <p className="citymaas trx">CityMaaS</p>

                </div>


                {props.isAuthenticated ?
                    <>

                        <Menu theme="dark" mode="horizontal" className='menu-mr'>

                        <Menu.Item className="analysis" disabled style={{ borderLeft: '1px solid #8080806b' }}><div className=' analysis sm-an'><EyeOutlined className=' analysis'/>  <span className='trx'>Users</span> 3264</div></Menu.Item>
                        <Menu.Item className="analysis" disabled style={{ borderLeft: '1px solid #8080806b' }}><div className=' analysis sm-an'><UserOutlined className=' analysis'/><span className='trx'>Views</span> 4100</div></Menu.Item>
                        <SubMenu popupClassName='popsss'   title={<div><Avatar className='av'>{props.email && props.email.charAt(0).toUpperCase()}</Avatar></div>}>
                        {props.email &&  <Menu.Item disabled ><span className=' analysis' style={{ textTransform: 'capitalize' }}>Hi, {props.email && props.email.substr(0, props.email.indexOf('@'))}&nbsp;&nbsp;&nbsp;&nbsp;	</span></Menu.Item>}
                            {props.getProfileGammSuccess && props.getProfileGammSuccess.user_points > 0 && <Menu.Item disabled  ><span className=' analysis' > Score: &nbsp;&nbsp;{props.getProfileGammSuccess && props.getProfileGammSuccess.user_points}&nbsp;&nbsp;<img src={Coin} className='coin-div' /> </span></Menu.Item> }



                            <Menu.Item key="1"> <Link to='/logout'><LogoutOutlined /> <span className=' analysis'>Logout</span></Link> </Menu.Item>
                        </SubMenu>
                            
                           

                        </Menu>  </>
                    :
                    <Menu theme="dark" mode="horizontal">
                        <Menu.Item className="analysis" disabled style={{ borderLeft: '1px solid #8080806b' }}><div className=' analysis sm-an'><EyeOutlined className=' analysis' /> <span className='trx'>Users</span>  3264</div></Menu.Item>
                        <Menu.Item className="analysis" disabled style={{ borderLeft: '1px solid #8080806b' }}><div className=' analysis sm-an'><UserOutlined className=' analysis' /><span className='trx'>Views</span>  4100</div></Menu.Item>


                        <Button className='but-signin right-mr' href='/sign-in'>Sign In</Button>
                        <Button className='but-signin right-mr' href='/sign-up'>Sign Up</Button>
                    </Menu>
                }
            </div>

        </Header>
    )
}

const mapStateToProps = state => {
    console.log(state);

    return {
        isAuthenticated: state.authReducer.email !== null,
        email: state.authReducer.email,
        getProfileGammSuccess: state.profileReducer.getProfileGammSuccess,


    }
}
export default connect(mapStateToProps, null)(Topbar);