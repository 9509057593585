import React, { useState } from 'react';
import { Form, Input, Button, Modal, Result, Card } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/images/valpi.png';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../Resusable/Notification/index';
import { withRouter } from 'react-router-dom'

import './signin.css';

const ValpiResetPassword = (props) => {
    const [loading, setLoading] = useState(false)
    const [showMessage, setShowMessage] = useState(false)

    const handleOk = e => {
        setShowMessage(false);

    };

    const handleCancel = e => {
        setShowMessage(false);
        props.history.push('/');

    };
    const onFinish = values => {
        setLoading(true)

        const credentials = {
            email: values.email,
            password: values.password,
            role: props.location.state.role
        };

        props.resetValpiProfile(credentials, res => {
            if (res.status === 200) {
                setShowMessage(true)
              
                setLoading(false)


            } else if (res.status === 777) {
                Notification('error', res.message);
                setLoading(false)



            } else {
                Notification('error', res.message);
                setLoading(false)

            }
        });
    };

 
    return (
        <div className='sign-in-bg'>
            <div className='sign-in-div'>
                <div className='sign-in-img'>
                    
                    <Card className='sign-in-card'>
                    <div style={{display:'flex', width:'100%', justifyContent:'center', marginBottom:20}}>
                        <img src={logo} width={120} alt='' />
                    </div>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true, email: props.location.state && props.location.state.email }}
                            onFinish={onFinish}
                            labelAlign='left'
                            layout='vertical'
                        >
                            <Form.Item
                                label='Email'
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                label='Password'
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            
                          
                            <Form.Item>
                                <div className='sign-in-div  mr-b-10' >
                                    <Button type="primary" htmlType="submit" className="sign-in-button" loading={loading}>
                                        Reset Password
                                </Button>
                                </div>
                             
                            </Form.Item>
                        </Form>
                    </Card>
                    <Modal
                        visible={showMessage}
                        onOk={handleOk}
                        onCancel={handleCancel}
                        footer={null}
                    >
                        <Result
                            status="success"
                            title="Successfully Validated"
                            subTitle="Please use your app to start your travel"
                            extra={[
                                <Button type="link" key="console" href='https://www.citymaas.io/white-label-platform/'>
                                   Visit our Website
                                </Button>,
                            ]}
                        />
                    </Modal>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.email !== null,


    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetValpiProfile: (credentials, res) => dispatch(actions.resetValpiProfile(credentials, res))
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValpiResetPassword));