import React, { useState, useEffect } from 'react';
import {
    PageHeader,
    Card,
    Avatar,
    Form,
    Input,
    Button,
    Checkbox,
    Row,
    Space,
    Spin,
    Skeleton,
} from 'antd';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import {
    HomeFilled,
    ShoppingFilled,
    StarFilled,
    HistoryOutlined,
    CloseCircleOutlined
} from '@ant-design/icons';
import Notification from '../Resusable/Notification/index';
import {withRouter, Link} from 'react-router-dom'
import { GApageView} from '../../utils/index';

import './myplaces.css';


const MyPlaces = (props) => {
    const [favourites, setFavourites] = useState([]);
    const [home, setHome] = useState([]);
    const [work, setWork] = useState([]);
    const [search, setSearch] = useState([]);
    const [search2, setSearch2] = useState([]);

    const [loading, setLoading] = useState(false);
    const [edit, setEdit] = useState(false);
    const [reload, setReload] = useState(false)

    useEffect(() => {
        GApageView(props.location.pathname); 

        fetch();

    }, [reload])

    const fetch = () => {
        setLoading(true)
        props.getProfile(res => {
            if (!res) {
                setLoading(false)

            } else {
                setFavourites(res.data.favourites_sp)
                setHome(res.data.home_sp)
                setWork(res.data.work_sp)
                setSearch(res.data.search_1)
                setSearch2(res.data.search_2)
                setLoading(false)


            }

        })
    }

    const removeFav = (event, [f]) => {
        let obj = {
            mode: 'delete',
            data: {
                email: sessionStorage.getItem('email'),
                favourites: {
                    location_name: f.location_name,
                    latitude: f.latitude,
                    longitude: f.longitude

                }
            }
        }

        props.addSaved(obj, res => {
            if (res !== 200) {

            } else {
                Notification('success', 'Location saved removed');
                setReload(true)
            }
        })
    }


    const removeWork = (event, work) => {
        let obj = {
            data: {
                email: sessionStorage.getItem('email'),
                work: {
                    location_name: work.location_name,
                    latitude: work.latitude,
                    longitude: work.longitude

                },
                work_mode: 'delete',
            }
        }

        props.addWorkHome(obj, res => {
            if (res !== 200) {

            } else {
                Notification('success', 'Location saved removed');
                setReload(true)
            }
        })
    }


    const removeHome = (event, home) => {
        let obj = {
            data: {
                email: sessionStorage.getItem('email'),
                home: {
                    location_name: home.location_name,
                    latitude: home.latitude,
                    longitude: home.longitude

                },
                home_mode: 'delete',
            }
        }

        props.addWorkHome(obj, res => {
            if (res !== 200) {

            } else {
                Notification('success', 'Location saved removed');
                setReload(true)
            }
        })
    }

    let homeDiv = (
        home !== null && <div className='fav-wrk-hm'>
            <div className='div-button-link'>
                <div className='icon-det-hed'><HomeFilled className='icon-sym gold' /><p className='fav-wrk-hm-hed'>Home </p> <Link to={{pathname: '/', state:{place: home}}}  className='fav-wrk-hm-det' >{home.location_name}</Link>
                </div>
                {edit ? window.screen.width < 600 ? <Button type="link" onClick={(event) => removeHome(event, home)}><CloseCircleOutlined/></Button> : <Button type="link" onClick={(event) => removeHome(event, home)}>Remove</Button> : ''}
            </div>

        </div>
    )


    let workDiv = (
        work !== null &&
        <div className='fav-wrk-hm'>
            <div className='div-button-link'>
                <div className='icon-det-hed'>
                    <ShoppingFilled className='icon-sym gold' /> <p className='fav-wrk-hm-hed'>Work </p> <Link to={{pathname: '/', state:{place: work}}}  className='fav-wrk-hm-det'>{work.location_name}</Link>
                </div>
                {edit ? window.screen.width < 600 ? <Button type="link" onClick={(event) => removeWork(event, work)}><CloseCircleOutlined/></Button> : <Button type="link" onClick={(event) => removeWork(event, work)}>Remove</Button> : ''}
            </div>
        </div>
    )

    let FavDiv = (
        <div className='fav-div'>
            {favourites && favourites.map(f => <div className='fav-wrk-hm'>
                <div className='div-button-link'>
                    <div className='icon-det-hed'>
                        <StarFilled className='icon-sym gold' /> <p className='fav-wrk-hm-hed'>Favourites </p> <Link to={{pathname: '/', state:{place: f}}}  className='fav-wrk-hm-det'>{f.location_name}</Link>
                    </div>
                    {edit ? window.screen.width < 600 ? <Button type="link" onClick={(event) => removeFav(event, [f])}><CloseCircleOutlined/></Button> : <Button type="link" onClick={(event) => removeFav(event, [f])}>Remove</Button> : ''}
                </div>

            </div>)}
        </div>
    )
    return (

        <>
            <PageHeader
                className='Pageheader-prf'
                title={`Places`}
                ghost
            />

            <Card className='card-style-prof'>
                <div className='top-b-r'>
                    <Button type="primary" htmlType="submit" className='button-primary width-150' onClick={() => setEdit(!edit)}>
                        {edit ? 'Cancel' : 'Edit'}
                    </Button>
                </div>

                <div className='fav'>
                    <p className='d-h-l'>Favourites</p>
                </div>
                {loading ?
                    <Skeleton active width={100} paragraph style={{ textAlign: 'justify' }} />
                    :
                    <>
                        <div>{homeDiv} </div>
                        <div>{workDiv}</div>
                        <div>{FavDiv}</div>
                    </>
                }
                <div className='fav'>
                    <p className='d-h-l'>Search History</p>
                </div>

                {loading ?
                    <Skeleton active width={100} paragraph style={{ textAlign: 'justify' }} />
                    :
                    <>
                        <div className='fav-div'>
                            {search !== null && <div className='fav-wrk-hm'>
                                <div className='div-button-link'>
                                    <div className='icon-det-hed'>
                                        <HistoryOutlined className='icon-sym lt-grey' /> <p className='fav-wrk-hm-hed'>Recent </p> <Link to={{pathname: '/', state:{place: search}}} className='fav-wrk-hm-det'>{search.location_name}</Link>
                                    </div>
                                    {edit ? window.screen.width < 600 ?<Button type="link" ><CloseCircleOutlined /></Button> : <Button type="link" >Remove</Button> : ''}
                                </div>


                            </div>}

                            {search2 !== null && <div className='fav-wrk-hm'>
                                <div className='div-button-link'>
                                    <div className='icon-det-hed'>
                                        <HistoryOutlined className='icon-sym lt-grey' /> <p className='fav-wrk-hm-hed'>Recent </p> <Link to={{pathname: '/', state:{place: search2}}}  className='fav-wrk-hm-det'>{search2.location_name}</Link>
                                    </div>
                                    {edit ? window.screen.width < 600 ?<Button type="link" ><CloseCircleOutlined /></Button> : <Button type="link" >Remove</Button> : ''}
                                </div>


                            </div>}
                        </div>
                    </>
                }
            </Card>

        </>
    )
}
const mapStateToProps = state => {
    return {
        getProfileLoading: state.profileReducer.getProfileLoading,
        getProfileSuccess: state.profileReducer.getProfileSuccess,
        addSavedLoading: state.accessibilityReducer.addSavedLoading,
        addSavedSuccess: state.accessibilityReducer.addSavedSuccess,
        addWorkHomeLoading: state.accessibilityReducer.addWorkHomeLoading,
        addWorkHomeSuccess: state.accessibilityReducer.addWorkHomeSuccess,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        getProfile: (res) => dispatch(actions.getProfile(res)),
        profile: (data, res) => dispatch(actions.profile(data, res)),
        addSaved: (data, res) => dispatch(actions.addSaved(data, res)),
        addWorkHome: (data, res) => dispatch(actions.addWorkHome(data, res)),

    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MyPlaces))
