import React, { useState, useEffect } from 'react';
import {
    Card,
    Form,
    Button,
    Checkbox,
    Row,
    Col,
    Spin,
    Skeleton
} from 'antd';
import {
    HomeFilled,
    ShoppingFilled,
    StarFilled,
} from '@ant-design/icons';
import { Link, withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../Resusable/Notification/index';

import './ReactMapbox.css';

const CardInfo = (props) => {
    const [favYell, setfavYell] = useState(false);
    const [workYell, setWorkYell] = useState(false);
    const [homeYell, setHomeYell] = useState(false);
    const [accessibilityInfo, setaccessibilityInfo] = useState(false)

    useEffect(() => {

        setfavYell(false)
        setHomeYell(false)
        setWorkYell(false)

        let arr = props.favourite && props.favourite.filter(fav => fav.location_name === props.value.label)
        if (arr !== null && arr.length > 0) {
            setfavYell(true)
        }

        if (props.home !== null && props.home.location_name === props.value.label && props.value.label !== undefined ) {
            setHomeYell(true)
        } 
        if (props.work !== null  && props.work.location_name === props.value.label && props.value.label !== undefined ) {
            setWorkYell(true)
        } 


        let location = props.value && props.value.label && props.value.label.substr(0, props.value && props.value.label.indexOf(','))
        if(props.poiData !== null && props.poiData !== undefined && props.poiData.place_infos){
        Object.keys(props.poiData !== null && props.poiData !== undefined && props.poiData.place_infos).map(val => {
            console.log(props.poiData.place_infos && props.poiData.place_infos[val] && props.poiData.place_infos[val].description);
            if (location !== undefined && location.includes(props.poiData && props.poiData.place_infos[val] && props.poiData.place_infos[val].description)) {
                      setaccessibilityInfo(props.poiData.place_infos[val])
            }
        }

        )}
    




    }, [props.value.label, props.getProfileLoading])



    const handleFav = () => {
        var array = JSON.parse("[" + props.value.key + "]");
        let obj = {
            mode: favYell ? 'delete' : 'append',
            data: {
                email: sessionStorage.getItem('email'),
                favourites: {
                    location_name: props.value.label,
                    latitude: array[0],
                    longitude: array[1]

                }
            }
        }

        props.addSaved(obj, res => {
            if (res !== 200) {

            } else {
                Notification('success', favYell ? 'Location removed successfully' : 'Location saved successfully');
                setfavYell(!favYell)

            }
        })

    }

    const handleWork = () => {
        var array = JSON.parse("[" + props.value.key + "]");
        let obj = {
            data: {
                email: sessionStorage.getItem('email'),
                work: {
                    location_name: props.value.label,
                    latitude: array[0],
                    longitude: array[1]

                },
                work_mode: workYell ? 'delete' : 'set',
            }
        }

        props.addWorkHome(obj, res => {
            if (res !== 200) {

            } else {
                Notification('success', workYell ? 'Location removed successfully' : 'Location saved successfully');
                setWorkYell(!workYell)

            }
        })

    }


    const handleHome = () => {
        var array = JSON.parse("[" + props.value.key + "]");
        let obj = {
            data: {
                email: sessionStorage.getItem('email'),
                home: {
                    location_name: props.value.label,
                    latitude: array[0],
                    longitude: array[1]

                },
                home_mode: homeYell ? 'delete' : 'set',
            }
        }

        props.addWorkHome(obj, res => {
            if (res !== 200) {

            } else {
                Notification('success', homeYell ? 'Location removed successfully' : 'Location saved successfully');
                setHomeYell(!homeYell)

            }
        })

    }


    let show = '';
    if (props.searchCard && props.accessInfo && window.screen.width < 600) {
        return <Card className='card-acc-leaflet'>
            <div><p className='header-det'>{props.value.label}</p></div>
         
            <div className='scrll-he'>
            <div><p className='header-det'>Real Time Alerts</p>
                    {props.getTrainLoading
                        ?
                        <Skeleton active paragraph={false} />
                        :
                        props.trainData.map(train => {
                            return <p className='det-det'>{train.update ? train.update : `${train.station_name} - Service all good`}</p>
                        })}
                </div>
            {props.disability && props.disability.map(value =>{
                return value.map(val => {
                    return <div>
                        <p className='header-det'>  {val === 'wheelchair' ? 'Wheelchair' : ( val === 'hear' ? 'Hearing Impaired' : val === 'visual' ? 'Visually Impaired' : val === 'autism' ? 'Austism' : '' )} Accessibility</p>
                    {props.getBusLoading
                        ?
                        <Skeleton active rows={3} paragraph={false} />
                        :
                         val === 'wheelchair'  ?  (accessibilityInfo && accessibilityInfo.accessibility_cat === 'wheelchair' && accessibilityInfo.accessibility_value === true ? 
                         <p className='det-det'>Yes</p>
                         : accessibilityInfo && accessibilityInfo.accessibility_cat === 'partial_wheelchair' && accessibilityInfo.accessibility_value === true ?
                         <p className='det-det'>Partially</p>
                         : accessibilityInfo && accessibilityInfo.accessibility_cat === 'wheelchair' && accessibilityInfo.accessibility_value === false ? 
                          <p className='det-det'>No</p>
                         :<p className='det-det'>Information not available</p>) :
                        val === 'hear' ? (accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.hear === true ? 
                         <p className='det-det'>Yes</p>
                         : accessibilityInfo && accessibilityInfo.partiallyAccessibleWith && accessibilityInfo.partiallyAccessibleWith.hear === true ?
                         <p className='det-det'>Partially</p>
                         :accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.hear === false ? <p className='det-det'>No</p>
                         :<p className='det-det'>Information not available</p>) :
                         val === 'visual' ? (accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.visual === true ? 
                         <p className='det-det'>Yes</p>
                         : accessibilityInfo && accessibilityInfo.partiallyAccessibleWith && accessibilityInfo.partiallyAccessibleWith.visual === true ?
                         <p className='det-det'>Partially</p>
                         : accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.visual === false ?
                         <p className='det-det'>No</p>
                         :<p className='det-det'>Information not available</p>) :
                         val === 'autism' ? (accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.autism === true ? 
                         <p className='det-det'>Yes</p>
                         : accessibilityInfo && accessibilityInfo.partiallyAccessibleWith && accessibilityInfo.partiallyAccessibleWith.autism === true ?
                         <p className='det-det'>Partially</p>
                         : accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.autism === false ? <p className='det-det'>No</p>
                         :<p className='det-det'>Information not available</p>) : ''}
                </div>
               
                         })
              
            })}

                <div><p className='header-det'>Nearest Bus Stations</p>
                    {props.getBusLoading
                        ?
                        <Skeleton active paragraph={false} />
                        :
                        props.busData.map(bus => {
                            return <p className='det-det'>{bus.station_name && bus.station_name.toLowerCase().replace(/[&\/\\#,+()$~%.:*?<>{}]/g, '')}</p>
                        })

                    }  </div>

                <div><p className='header-det'>Nearest Train Stations</p>
                    {props.getTrainLoading
                        ?
                        <Skeleton active paragraph={false} />
                        :
                        props.trainData.map(train => {
                            return <p className='det-det'>{train.station_name && train.station_name.toLowerCase().replace(/[&\/\\#,+()$~%.:*?<>{}]/g, '')}</p>
                        })}
                </div>


              

            </div>
            {props.isAuthenticated && <div className='card-bott-button padding-top-10'>
                <div className='hbtx'>

                    <div className='l-20'  onClick={() => handleHome()}>
                        <HomeFilled className={props.addSavedLoading ? 'loader h-20' : homeYell ? 'yellow h-20' : 'grey h-20'} />
                    </div>
                </div>
                <div className='hbtx'>

                    <div className='l-20'  onClick={() => handleWork()}>
                        <ShoppingFilled className={props.addSavedLoading ? 'loader h-20' : workYell ? 'yellow h-20' : 'grey h-20'}/>
                    </div>

                </div>
                <div className='hbtx'>

                    <div className='l-20' onClick={() => handleFav()}>
                        <StarFilled className={props.addSavedLoading ? 'loader h-20' : favYell ? 'yellow h-20' : 'grey h-20'} />
                    </div>

                </div>
            </div>}

            <div className='card-info-div'>
                <Button type="primary" htmlType="submit" className="next-button" onClick={() => props.history.push('/navigation')}>Navigate Here</Button>
            </div>

        </Card>
    } else if (props.searchCard && window.screen.width > 600) {
        return <Card className='card-acc-leaflet'>
            <div><p className='header-det'>{props.value.label}</p></div>

           
            <div className='scrll-he'>
            <div><p className='header-det'>Real Time Alerts</p>
                    {props.getTrainLoading
                        ?
                        <Skeleton active paragraph={false} />
                        :
                        props.trainData.map(train => {
                            return <p className='det-det'>{train.update ? train.update : `${train.station_name} - Service all good`}</p>
                        })}
                </div>
            {props.disability && props.disability.map(value =>{
                return value.map(val => {
                    return <div>
                        <p className='header-det'>  {val === 'wheelchair' ? 'Wheelchair' : ( val === 'hear' ? 'Hearing Impaired' : val === 'visual' ? 'Visually Impaired' : val === 'autism' ? 'Austism' : '' )} Accessibility</p>
                    {props.getBusLoading
                        ?
                        <Skeleton active rows={3} paragraph={false} />
                        :
                        val === 'wheelchair'  ?  (accessibilityInfo && accessibilityInfo.accessibility_cat === 'wheelchair' && accessibilityInfo.accessibility_value === true ? 
                        <p className='det-det'>Yes</p>
                        : accessibilityInfo && accessibilityInfo.accessibility_cat === 'partial_wheelchair' && accessibilityInfo.accessibility_value === true ?
                        <p className='det-det'>Partially</p>
                        : accessibilityInfo && accessibilityInfo.accessibility_cat === 'wheelchair' && accessibilityInfo.accessibility_value === false ? 
                         <p className='det-det'>No</p>
                        :<p className='det-det'>Information not available</p>) :
                        val === 'hear' ? (accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.hear === true ? 
                         <p className='det-det'>Yes</p>
                         : accessibilityInfo && accessibilityInfo.partiallyAccessibleWith && accessibilityInfo.partiallyAccessibleWith.hear === true ?
                         <p className='det-det'>Partially</p>
                         :accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.hear === false ? <p className='det-det'>No</p>
                         :<p className='det-det'>Information not available</p>) :
                         val === 'visual' ? (accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.visual === true ? 
                         <p className='det-det'>Yes</p>
                         : accessibilityInfo && accessibilityInfo.partiallyAccessibleWith && accessibilityInfo.partiallyAccessibleWith.visual === true ?
                         <p className='det-det'>Partially</p>
                         : accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.visual === false ?
                         <p className='det-det'>No</p>
                         :<p className='det-det'>Information not available</p>) :
                         val === 'autism' ? (accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.autism === true ? 
                         <p className='det-det'>Yes</p>
                         : accessibilityInfo && accessibilityInfo.partiallyAccessibleWith && accessibilityInfo.partiallyAccessibleWith.autism === true ?
                         <p className='det-det'>Partially</p>
                         : accessibilityInfo && accessibilityInfo.accessibleWith && accessibilityInfo.accessibleWith.autism === false ? <p className='det-det'>No</p>
                         :<p className='det-det'>Information not available</p>) : ''}
                </div>
               
                         })
              
            })}

                <div><p className='header-det'>Nearest Bus Stations</p>
                    {props.getBusLoading
                        ?
                        <Skeleton active rows={3} paragraph={false} />
                        :
                        props.busData.map(bus => {
                            return <p className='det-det'>{bus.station_name && bus.station_name.toLowerCase().replace(/[&\/\\#,+()$~%.:*?<>{}]/g, '')}</p>
                        })

                    }  </div>

                <div><p className='header-det'>Nearest Train Stations</p>
                    {props.getTrainLoading
                        ?
                        <Skeleton active paragraph={false} />
                        :
                        props.trainData.map(train => {
                            return <p className='det-det'>{train.station_name && train.station_name.toLowerCase().replace(/[&\/\\#,+()$~%.:*?<>{}]/g, '')}</p>
                        })}
                </div>



            </div>
            {props.isAuthenticated && <div className='card-bott-button'>
                <div className='hbtx'  >
                    <HomeFilled className='h-20'  style={{ color: homeYell ? '#D4AF37' : '#e3e3e3' }} />
                    <Button type="link" className='l-20' onClick={() => handleHome()}>{homeYell ? 'Remove' : 'Set Home'}</Button>
                </div>
                <div className='hbtx' >
                    <ShoppingFilled className='h-20'  style={{ color: workYell ? '#D4AF37' : '#e3e3e3' }}/>
                    <Button type="link" className='l-20' onClick={() => handleWork()}>{workYell ? 'Remove' : 'Set Work'}</Button>

                </div>
                <div className='hbtx'>
                    <StarFilled className='h-20' style={{ color: favYell ? '#D4AF37' : '#e3e3e3' }} />
                    <Button type="link" className='l-20' onClick={() => handleFav()}>{favYell ? 'Remove' : 'Set Favourite'}</Button>

                </div>
            </div>}

            <div className='card-info-div'>
                <Button type="primary" htmlType="submit" className="next-button" onClick={() => {
                  props.history.replace({
                        pathname: `/navigation`,
                        state: { label: props.value.label, value: props.value.value, key: props.value.key }
                    }) }}>Navigate Here</Button>
            </div>

        </Card>
    }
    return (

        show
    )
}

const mapStateToProps = state => {
    return {
        addSavedLoading: state.accessibilityReducer.addSavedLoading,
        addSavedSuccess: state.accessibilityReducer.addSavedSuccess,
        addWorkHomeLoading: state.accessibilityReducer.addWorkHomeLoading,
        addWorkHomeSuccess: state.accessibilityReducer.addWorkHomeSuccess,
        accessSearch: state.accessibilityReducer.accessSearch,
        getProfileLoading: state.profileReducer.getProfileLoading,

    }
}
const mapDispatchToProps = dispatch => {
    return {
        addSaved: (data, res) => dispatch(actions.addSaved(data, res)),
        addWorkHome: (data, res) => dispatch(actions.addWorkHome(data, res)),


    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CardInfo))