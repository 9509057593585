import React, { Component } from 'react';
import { Layout, Menu, } from 'antd';
import {
    EyeOutlined,
    EnvironmentOutlined,
    UserOutlined,
    BoldOutlined,
    AimOutlined,
    CheckOutlined
} from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withRouter } from "react-router";
import './sidebar.css';
const { Sider } = Layout;
const { SubMenu } = Menu;
class Sidebar extends Component {
    state = {
        current: '',
        openKey: ''
    }

    componentWillMount() {
        if (this.props.location.pathname.includes('navigation')) {
            this.setState({ current: 'navigation', openKey: '' })

        } else if (this.props.location.pathname.includes('profile')) {
            this.setState({ current: 'profile', openKey: '' })

        } else if (this.props.location.pathname.includes('places')) {
            this.setState({ current: 'places', openKey: '' })

        } else if (this.props.location.pathname.includes('business')) {
            this.setState({ current: 'business', openKey: '' })

        } else if (this.props.location.pathname.includes('feedback')) {
            this.setState({ openKey: 'feedback', current: 'poi'  })
            if (this.props.location.pathname.includes('poi')) {
                this.setState({ openKey: 'feedback', current: 'poi' })

            } else if (this.props.location.pathname.includes('train')) {
                this.setState({ current: 'train' })


            } else if (this.props.location.pathname.includes('bus')) {
                this.setState({ current: 'bus' })

            }

        } else if (this.props.location.pathname === '/') {
            this.setState({ current: 'accessibility', openKey: '' })

        }

    }

    componentWillReceiveProps(nextProp) {
        if (nextProp.location.pathname.includes('navigation')) {
            this.setState({ current: 'navigation', openKey: '' })

        } else if (nextProp.location.pathname.includes('feedback')) {
            this.setState({ openKey: 'feedback', current: 'poi' })

            if (nextProp.location.pathname.includes('poi')) {
                this.setState({ openKey: 'feedback', current: 'poi' })


            } else if (nextProp.location.pathname.includes('train')) {
                this.setState({ current: 'train' })


            } else if (nextProp.location.pathname.includes('bus')) {
                this.setState({ current: 'bus' })


            }

        } else if (nextProp.location.pathname === '/') {
            this.setState({ current: 'accessibility', openKey: '' })

        } else if (nextProp.location.pathname.includes('profile')) {
            this.setState({ current: 'profile', openKey: '' })

        } else if (nextProp.location.pathname.includes('places')) {
            this.setState({ current: 'places', openKey: '' })

        } else if (nextProp.location.pathname.includes('business')) {
            this.setState({ current: 'business', openKey: '' })

        }

    }

    render() {
        const { current, openKey } = this.state;
      
        return (
            <Sider
                width={230}
                breakpoint="lg"
                collapsedWidth={45}
                onBreakpoint={broken => {
                    console.log(broken);
                }}
                onCollapse={(collapsed, type) => {
                    console.log(collapsed, type);
                }}
            >
                <div className="logo" />
                <div className="side" >
                    <div>

                        <Menu theme="dark" mode="inline" defaultSelectedKeys={[current.toString()]} selectedKeys={[current.toString()]} defaultOpenKeys={[openKey]}>
                            <Menu.Item key='accessibility'>
                                <Link to='/'>
                                    <EyeOutlined />
                                    <span>Accessibility Info</span>
                                </Link>
                            </Menu.Item>
                            <Menu.Item key="navigation">
                                <Link to='/navigation'>
                                    <EnvironmentOutlined />
                                    <span>Navigation</span>
                                </Link>
                            </Menu.Item>

                            <SubMenu
                                key="feedback"
                                title={
                                    <span>
                                        <CheckOutlined />
                                        <span>Feedback</span>
                                    </span>
                                }
                            >
                                <Menu.Item key="poi"> <Link to='/feedback/poi'>Places</Link></Menu.Item>
                                <Menu.Item key="train"><Link to='/feedback/train'>Train Station</Link></Menu.Item>
                                <Menu.Item key="bus"><Link to='/feedback/bus'>Bus Station</Link></Menu.Item>

                            </SubMenu>

                            {this.props.isAuthenticated && sessionStorage.getItem('role') === "user" && <Menu.Item key="profile">
                                <Link to='/profile'>
                                    <UserOutlined />
                                    <span>Profile</span>
                                </Link>
                            </Menu.Item>}

                            {this.props.isAuthenticated && sessionStorage.getItem('role') === "user" && <Menu.Item key="places">
                                <Link to='/places'>
                                    <AimOutlined />
                                    <span>My Places</span>
                                </Link>
                            </Menu.Item>}

                            <Menu.Item key="business">
                                <Link to='/businessCommunity'>
                                    <BoldOutlined />
                                    <span>Business Community</span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </div>
                    <div className='tpp'>
               {/* <a href='https://www.citymaas.io/privacy-policy/' target='_blank'>Privact Policy</a>| <a href='https://www.citymaas.io/terms/' target='_blank'>Term of use</a> */}
               </div>
                </div>
            </Sider>
        )
    }
}
const mapStateToProps = state => {
    console.log(state);

    return {
        isAuthenticated: state.authReducer.email !== null,
        email: state.authReducer.email

    }
}

export default withRouter(connect(mapStateToProps, null)(Sidebar));