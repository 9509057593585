import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import { Spin } from 'antd';
import * as actions from '../../../store/actions/index'
import Notification from '../../Resusable/Notification/index';



class FirstTimeLogin extends Component {
    state = {
        email: '',
        password: '',
        loading: false
    }
    componentDidMount() {
        let serachStr = '';
        let business = false;

        if(this.props.location.search){
            if(this.props.location.pathname.includes('business')){
                business = true;
            }
            serachStr = this.props.location.search;
            let [nothing, assistid, assistvalpassword] = serachStr.split('&');
            assistid = assistid.replace('assistid=', '')
            assistvalpassword = assistvalpassword.replace('assistvalpassword=', '')
            this.setState({assistid, assistvalpassword, loading: true})
            const credentials = {
                data : {
                    assistid: assistid,
                    assistvalpassword: assistvalpassword,
                    business: business
                }
               
            };
            this.props.resetPassword(credentials, res => {
                if (res.status === 200) {
                    this.setState({ loading: false})
                     this.props.history.push({pathname: '/reset-profile', state:{ email: res.data.auth_user_email}});

    
                }   else if (res.status === 777){
                    Notification('error', res.message);
    
                } else {
                    this.setState({ loading: false})
                    Notification('error', res.message);
                    // this.props.history.push('/');

                }
            });


        }

        console.log(this.props.match)
    }
    render() {
        const {loading} = this.state;
        return (
           loading ? <Spin/> : null
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.email !== null,


    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetPassword: (credentials, res) => dispatch(actions.resetPassword(credentials, res))

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(FirstTimeLogin))