import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom'
import rootReducer from './store/reducers'
import PublicRoutes from './Routes';
// import {
//   applyPolyfills as ap,
//   defineCustomElements as de,
// } from "@assistplugin/core/loader";
// import { CitymaasAssistPlugin } from "@assistplugin/react";
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
  <Provider store={store}>
    <React.StrictMode>
    <BrowserRouter>
            <PublicRoutes />
        </BrowserRouter>
        {/* <CitymaasAssistPlugin  data-id='AssistMePlugin' data-token='4Dfyau1tyyaLPruMUJnDXrl2vxWVrqJgasP3UIZT'/> */}
    </React.StrictMode>
  </Provider>
)
ReactDOM.render(
  app,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// ap().then(de);

serviceWorker.unregister();
