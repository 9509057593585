import React, { Component } from 'react';
import {
    PageHeader,
    Upload,
    Card,
    Button,
    Input,
    Radio,
    Select,
    Spin,
    List,
    Avatar
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import ColumnChart from '../Resusable/ColumnChart'
import StackedChart from '../Resusable/StackedChart';
import datasrc from '../../utils/categories.json';
import debounce from 'lodash/debounce';
import Notification from '../Resusable/Notification/index';
import BusinessMap from './BusinessMap';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { GApageView} from '../../utils/index'
import './BusinessCommunity.css';

const { Option } = Select;
class BusinessCommunity extends Component {
    constructor(props) {
        super(props);
        this.lastFetchId = 0;
        this.fetchUser = debounce(this.fetchUser, 800);
    }

    state = {
        loading: false,
        data: {},
        businessQuestionaire: {},
        accessibleEntrance: {},
        accessibleParking: {},
        goodsServicesAccess: {},
        restRoomUsability: {},
        value: 1,
        checkedList: {},
        dataSelect: [],
        valueSelect: [],
        fetching: false,
        valueLoc: [],
        dataLoc: [],
        fetchingLoc: false,
        search: false,
        lat: 0,
        lng: 0,
        chartData: [],
        dragLat: 0,
        dragLng: 0,
        valueGsa: '',
        valueAe: '',
        valueAp: '',
        valueRru: '',
        AE_1: '',
        AE_2: '',
        AP_1: '',
        AP_2: '',
        GSA_1: '',
        GSA_2: '',
        GSA_3: '',
        GSA_4: '',
        RU_1: '',
        RU_2: '',
        Lift_door_is_36inch_wide: '',
        close_to_accessible_entrance: '',
        foot_circle: '',
        shaped_space_for_wheelchairs: '',
        Letters_at_least_3_inches: '',
        mounted_80inchs_high: '',
        Anti_Slip_Ramp: '',
        Hand_Rail_greater_than_6_feet: '',
        Ramp_Raises_less_than_30_inches: '',
        wide_access: '',
        close_to_accessible_entrance2: '',
        wide_inch: '',
        deep_inch: '',
        rim_no_higher_than_34_inches: '',
        toilet_seat_17_to_19_inches_high: '',
        business_contact_phone: '',
        business_name: '',
        business_password: '',
        business_website_link: '',
        business_logo_url: '',
        business_email: '',
        business_leaflet_url: '',
        business_category: [],
        businessListing: [],
        businessNameHelper: false,
        websiteLinkHelper: false,
        categoryHelper: false,
        locationHelper: false,
        responseLoc: false
    }

    componentDidMount() {
    GApageView(this.props.location.pathname); 
        this.props.getProfileBus(res => {
            if (!res) {

            } else {
                this.setState({
                    data: res.data,
                    business_name: res.data.business_name,
                    business_contact_phone: res.data.business_contact_phone,
                    business_website_link: res.data.business_website_link,
                    business_email: res.data.business_email,
                    business_category: res.data.business_category === null ? [] : res.data.business_category,



                })
                this.setLocation(res.data && res.data.business_location_latitude === null ? 0 : res.data.business_location_latitude, res.data && res.data.business_location_longitude === null ? 0 : res.data.business_location_longitude);

                if (res.data.business_questionaire) {
                    res.data.business_questionaire.goods_services_access.map(check => {
                        return this.setState({ [check.id]: check.response });


                    })
                }

                if (res.data.business_questionaire.goods_services_access) {
                    res.data.business_questionaire.goods_services_access.map(check => {
                        Object.keys(check.feature_checks_yes).map(q => {
                            let param = q.replace(/ /g, "_");
                            if (param === "5-foot_circle") {
                                this.setState({
                                    foot_circle: check.feature_checks_yes[q],
                                });

                            } else if (param === "T-shaped_space_for_wheelchairs") {

                                this.setState({
                                    shaped_space_for_wheelchairs: check.feature_checks_yes[q],
                                });
                            } else if (param === '30-inch-wide') {
                                this.setState({
                                    wide_inch: check.feature_checks_yes[q],
                                });
                            } else if (param === '48-inch-deep') {
                                this.setState({
                                    deep_inch: check.feature_checks_yes[q],
                                });
                            }
                            else { this.setState({ [param]: check.feature_checks_yes[q] }); }
                        })
                    })
                }



                if (res.data.business_questionaire) {
                    res.data.business_questionaire.accessible_entrance.map(check => this.setState({ [check.id]: check.response }))
                }

                if (res.data.business_questionaire.accessible_entrance) {
                    res.data.business_questionaire.accessible_entrance.map(check => {
                        Object.keys(check.feature_checks_yes).map(q => {
                            let param = q.replace(/ /g, "_");

                            return this.setState({ [param]: check.feature_checks_yes[q] });
                        })
                    })
                }

                if (res.data.business_questionaire) {
                    res.data.business_questionaire.accessible_parking.map(check => this.setState({ [check.id]: check.response }))
                }

                if (res.data.business_questionaire.accessible_parking) {
                    res.data.business_questionaire.accessible_parking.map(check => {
                        Object.keys(check.feature_checks_yes).map(q => {
                            let param = q.replace(/ /g, "_");

                            if (param === '8-foot-wide_access') {
                                this.setState({
                                    wide_access: check.feature_checks_yes[q],
                                });
                            } else if (param === 'close_to_accessible_entrance') {
                                this.setState({
                                    close_to_accessible_entrance2: check.feature_checks_yes[q],
                                });
                            }
                        })
                    })
                }

                if (res.data.business_questionaire) {
                    res.data.business_questionaire.rest_room_usability.map(check => this.setState({ [check.id]: check.response }))

                    if (res.data.business_questionaire.rest_room_usability) {
                        res.data.business_questionaire.rest_room_usability.map(check => {
                            Object.keys(check.feature_checks_yes).map(q => {
                                let param = q.replace(/ /g, "_");
                                if (param === '30-inch-wide') {
                                    this.setState({
                                        wide_inch: check.feature_checks_yes[q],
                                    });
                                } else if (param === '48-inch-deep') {
                                    this.setState({
                                        deep_inch: check.feature_checks_yes[q],
                                    });
                                }
                                else { this.setState({ [param]: check.feature_checks_yes[q] }); }
                            })
                        })
                    }
                }

                if (res.data.business_category !== null) {
                    this.props.getChart(res.data.business_category, res => {
                        if (!res) {

                        } else {
                            this.setState({ chartData: res })

                        }
                    })
                }

            }
        })




        this.props.getGBus(res => {
            if (!res) {

            } else {
                this.setState({ businessListing: res.business_list })



            }
        })
    }



    onFinish = values => {
        console.log('Received values of form: ', values);

    };

    dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    onChangeGsa = (e, name) => {

        console.log('radio checked', e.target.value);

        this.setState({
            [name]: e.target.value,
        });
    };


    onChangegsa1 = (e, name) => {
        let param = name.replace(/ /g, "_")

        if (param === "5-foot_circle") {
            this.setState({
                foot_circle: e.target.value,
            });

        } else if (param === "T-shaped_space_for_wheelchairs") {

            this.setState({
                shaped_space_for_wheelchairs: e.target.value,
            });
        } else if (param === '30-inch-wide') {
            this.setState({
                wide_inch: e.target.value,
            });
        } else if (param === '48-inch-deep') {
            this.setState({
                deep_inch: e.target.value,
            });
        }
        console.log('radio checked', e.target.value);
        this.setState({
            [param]: e.target.value,
        });
    };


    onChangegsa2 = (e, name) => {
        let param = name.replace(/ /g, "_")

        if (param === '8-foot-wide_access') {
            this.setState({
                wide_access: e.target.value,
            });
        } else if (param === 'close_to_accessible_entrance') {
            this.setState({
                close_to_accessible_entrance2: e.target.value,
            });
        }
        console.log('radio checked', e.target.value);
        this.setState({
            [param]: e.target.value,
        });
    };


    handleSearch = () => {
        this.setState({ search: false })
    }

    onChangeCheck = checkedList => {
        this.setState({
            checkedList
        });
    };


    handleChange = valueSelect => {
        this.setState({
            business_category: valueSelect,
            dataSelect: [],
            fetching: false,
        });
    };

    fetchUser = value => {
        console.log('fetching user', value);
        this.lastFetchId += 1;
        const fetchId = this.lastFetchId;
        this.setState({ dataSelect: [], fetching: true });
        // let dataResult = datasrc.amenities.find(val => {val.includes(value)})
        let dataResult = []
        for (let i = 1; i <= datasrc.amenities.length; i++) {
            if (datasrc.amenities[i] && datasrc.amenities[i].toString().includes(value.toLowerCase())) {
                dataResult.push(datasrc.amenities[i])
            }
        }
        if (dataResult) {
            this.setState({ dataSelect: dataResult, fetching: false });
        }
    };


    onChangeLoc = value => {
        this.setState({ valueLoc: value })

    }

    setLocation = (lati, long) => {
        let val = {
            key: `${long},${lati}`,
            label: `${long},${lati}`,
            value: `${long},${lati}`
        }
        this.setState({ dragLat: lati, dragLng: long, valueLoc: val, lat: lati, lng: long })
    }

    fetchUserLoc = value => {
        this.setState({ valueLoc: value, dataLoc: [], fetchingLoc: true })

        this.props.getLocation(value, res => {
            if (!res) {
                this.setState({ search: false, dataLoc: [], fetchingLoc: false, search: false })

            } else {
                const results = [];
                res.map(val => {
                    results.push({
                        loc: val.center,
                        title: val.place_name
                    })
                })

                this.setState({ search: false, dataLoc: results, fetchingLoc: false, search: false })


            }

        })

    };

    handleChangeLoc = (value) => {
        let lat = value && value.key.split(",");
        console.log(lat);
        this.setState({ lat: lat[1], lng: lat[0], dataLoc: [], fetchUserLoc: false, valueLoc: value, search: true })


    };


    handleFieldChange = (e, name) => {
        if(name === 'business_contact_phone'){
        this.setState({ [name]: e })

        } else {
        this.setState({ [name]: e.target.value })

        }
    }

    handleUploadChange1 = (info) => {
        this.setState({ business_leaflet_url: info.fileList })

    }

    handleUploadChange2 = (info) => {
        this.setState({ business_logo_url: info.fileList })

    }

    handleClick = () => {
        const {
            business_email,
            business_password,
            business_name,
            business_contact_phone,
            business_logo_url,
            business_leaflet_url,
            dragLat,
            dragLng,
            business_website_link,
            business_category,
            Hand_Rail_greater_than_6_feet,
            Anti_Slip_Ramp,
            Ramp_Raises_less_than_30_inches,
            AE_1,
            AP_1,
            wide_access,
            close_to_accessible_entrance2,
            GSA_1,
            GSA_2,
            GSA_3,
            Lift_door_is_36inch_wide,
            close_to_accessible_entrance,
            foot_circle,
            shaped_space_for_wheelchairs,
            Letters_at_least_3_inches,
            mounted_80inchs_high,
            RU_1,
            rim_no_higher_than_34_inches,
            toilet_seat_17_to_19_inches_high,
            wide_inch,
            deep_inch,
            valueLoc

        } = this.state;
        let business_questionaireData = {
            "accessible_entrance": [{
                feature_check_on_inaccessibility: "False",
                feature_checks_no: {
                    "Anti Slip Ramp": '',
                    "Hand Rail greater than 6 feet": '',
                    "Ramp Raises less than 30 inches": '',
                },
                feature_checks_yes: {
                    "Anti Slip Ramp": Anti_Slip_Ramp,
                    "Hand Rail greater than 6 feet": Hand_Rail_greater_than_6_feet,
                    "Ramp Raises less than 30 inches": Ramp_Raises_less_than_30_inches,
                },
                id: "AE_1",
                question: "Do you have an accessible entrance?",
                response: AE_1.toString()
            }],
            "accessible_parking": [{
                feature_check_on_inaccessibility: "False",
                feature_checks_yes: {
                    '8-foot-wide access': wide_access,
                    'close to accessible entrance': close_to_accessible_entrance2
                },
                id: "AP_1",
                question: "Do you have accessible parking?",
                response: AP_1.toString()
            }],
            "goods_services_access": [{
                feature_check_on_inaccessibility: "False",
                feature_checks_yes: {
                    'Lift door is 36inch wide': Lift_door_is_36inch_wide,
                    'close to accessible entrance': close_to_accessible_entrance
                },
                id: "GSA_1",
                question: "Do you have /need to have accessible lift in your premises to serve wheelchairs users?",
                response: GSA_1.toString()
            },
            {
                feature_check_on_inaccessibility: "False",
                feature_checks_yes: { '5-foot circle': foot_circle, 'T-shaped space for wheelchairs': shaped_space_for_wheelchairs },
                id: "GSA_2",
                question: "Is your service area big enough to accommodate wheelchair users?",
                response: GSA_2.toString()
            },
            {
                feature_check_on_inaccessibility: "False",
                feature_checks_yes: { 'Letters at least 3 inches': Letters_at_least_3_inches, 'mounted 80inchs high': mounted_80inchs_high },
                id: "GSA_3",
                question: "Do you have good signage for serving your customers",
                response: GSA_3.toString()
            }
            ],
            "rest_room_usability": [{
                feature_check_on_inaccessibility: "False",
                feature_checks_yes: {
                    '30-inch-wide': wide_inch,
                    '48-inch-deep': deep_inch,
                    'rim no higher than 34 inches': rim_no_higher_than_34_inches,
                    'toilet seat 17 to 19 inches high': toilet_seat_17_to_19_inches_high
                },
                id: "RU_1",
                question: "Do you have accessible toilet?",
                response: RU_1.toString()
            }]
        }

        if (business_name === '') {
            this.setState({ businessNameHelper: true })
        } else {
            this.setState({ businessNameHelper: false })

        }
        if (business_website_link === '' || business_website_link === "null") {
            this.setState({ websiteLinkHelper: true })

        } else {
            this.setState({ websiteLinkHelper: false })

        }

        if (business_category.length < 1) {
            this.setState({ categoryHelper: true })

        } else {
            this.setState({ categoryHelper: false })

        }

        if (valueLoc.length < 1) {
            this.setState({ locationHelper: true })


        } else {
            this.setState({ locationHelper: false })

        }
        console.log(business_questionaireData)
        var bodyFormData = new FormData();

        bodyFormData.append('business_email', business_email);
        { business_password && business_password.length > 0 && bodyFormData.append('business_password', business_password); }
        bodyFormData.append('business_name', business_name);
        bodyFormData.append('business_contact_phone', business_contact_phone);
        { business_logo_url && business_logo_url[0] && bodyFormData.append('business_logo', business_logo_url[0]); }
        { business_leaflet_url && business_leaflet_url[0] && bodyFormData.append('business_leaflet', business_leaflet_url[0]); }
        bodyFormData.append('business_location_longitude', dragLng);
        bodyFormData.append('business_location_latitude', dragLat);
        bodyFormData.append('business_website_link', business_website_link);
        bodyFormData.append('business_category', business_category);
        bodyFormData.append('business_questionaire', JSON.stringify(business_questionaireData));

        if (business_name !== '' && business_website_link !== '' && business_website_link !== "null" && business_category.length > 1 && valueLoc && valueLoc.key.length > 1) {
            this.props.busProfile(bodyFormData, res => {
                if (!res) {
                    Notification('error', res.message);
                } else {
                    Notification('success', 'Profile updated successfully');

                }
            })
        } else {
            Notification('error', 'Please fill all mandatory fields');

        }



    }
    render() {
        const {
            data,
            dataSelect,
            valueSelect,
            fetching,
            valueLoc,
            lat,
            lng,
            search,
            dataLoc,
            fetchingLoc,
            GSA_1,
            GSA_2,
            GSA_3,
            AE_1,
            AP_1,
            RU_1,
            Lift_door_is_36inch_wide,
            close_to_accessible_entrance,
            shaped_space_for_wheelchairs,
            foot_circle,
            Letters_at_least_3_inches,
            mounted_80inchs_high,
            Anti_Slip_Ramp,
            Hand_Rail_greater_than_6_feet,
            Ramp_Raises_less_than_30_inches,
            rim_no_higher_than_34_inches,
            toilet_seat_17_to_19_inches_high,
            wide_inch,
            deep_inch,
            wide_access,
            close_to_accessible_entrance2,
            business_contact_phone,
            business_name,
            business_password,
            business_website_link,
            business_category,
            business_email,
            chartData,
            businessListing,
            businessNameHelper,
            websiteLinkHelper,
            categoryHelper,
            locationHelper,

        } = this.state;

        return (
            <div>
                <PageHeader
                    className='Pageheader-bs'
                    title={`Business Community`}
                    ghost
                />
                {sessionStorage.getItem('role') === "user" || !this.props.isAuthenticated ?
                    <Card className='card-style-bs'>
                        <>
                            <List
                                itemLayout="horizontal"
                                header={<div>CityMaaS partners & supporting ecosystem</div>}
                            >
                                <List.Item>

                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Microsoft (london)'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Atos (global)'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Grant Thorton London'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Sente (Chicago)'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>


                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Hatcher+ (Singapore)'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Fleetricity'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Avis Budget Group'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Unverisity Of Surrey
                                '
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>

                                <List.Item>
                                    <List.Item.Meta
                                        avatar={<Avatar src='' />}
                                        title='Brunel University'
                                    //   description="Ant Design, a design language for background applications, is refined by Ant UED Team"
                                    />
                                </List.Item>


                            </List>


                        </>
                    </Card>


                    : <>
                        <div className='charts-div-cards'>
                            <div className='lil-div'>
                                <Card className='card-style-bs width'>
                                    <ColumnChart chartData={chartData} />
                                </Card>
                            </div>

                            <div className='lil-div'>
                                <Card className='card-style-bs width'>
                                    <StackedChart chartData={chartData} />
                                </Card>
                            </div>
                        </div>

                        <Card className='card-style-bs'>

                            <div className='field'><span className='label'>Business Name <span style={{ color: 'red' }}>*</span></span><Input placeholder='Business Name' defaultValue={data.business_name} value={business_name} className='input-field' onChange={e => this.handleFieldChange(e, 'business_name')} /></div>
                            {businessNameHelper && <span style={{ color: 'red', fontSize: 12 }}>Please add business name</span>}
                            <div className='field input-field'><span className='label'>Contact Phone </span> <PhoneInput
                                country={'gb'}
                                value={business_contact_phone}
                                // className='input-field' 
                                onChange={e => this.handleFieldChange(e, 'business_contact_phone')}
                            /></div>
                            {/* <div className='field'><span className='label'>Contact Phone </span> <Input placeholder='Contact Phone' defaultValue={data.business_contact_phone} value={business_contact_phone} className='input-field' onChange={e => this.handleFieldChange(e, 'business_contact_phone')} /> </div> */}
                            <div className='field'><span className='label'>Email <span style={{ color: 'red' }}>*</span> </span><Input disabled placeholder='Email' defaultValue={data.business_email} value={business_email} className='input-field' onChange={e => this.handleFieldChange(e, 'business_email')} /></div>
                            <div className='field'><span className='label'>Website Link <span style={{ color: 'red' }}>*</span></span><Input placeholder='Website Link' defaultValue={data.business_website_link} value={business_website_link} className='input-field' onChange={e => this.handleFieldChange(e, 'business_website_link')} /></div>
                            {websiteLinkHelper && <span style={{ color: 'red', fontSize: 12 }}>Please add website link</span>}

                            <div className='field'><span className='label'>Password</span><Input type='password' placeholder='Password' value={business_password} className='input-field' onChange={e => this.handleFieldChange(e, 'business_password')} /></div>



                            <div className='field input-field'><span className='label'>Business Leaflet</span><div><Upload name="business_leaflet_url" customRequest={this.dummyRequest} listType="picture" onChange={this.handleUploadChange1}>
                                <Button className='input-field'>
                                    <UploadOutlined /> Click to upload leaflet
                           </Button>
                            </Upload></div></div>

                            <div className='field input-field'><span className='label'>Business Logo</span><div><Upload name="business_logo_url" customRequest={this.dummyRequest} listType="picture" onChange={this.handleUploadChange2}>
                                <Button className='input-field'>
                                    <UploadOutlined /> Click to upload logo
                    </Button>
                            </Upload></div></div>




                            {this.props.getProfileBusLoading ? <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0px' }}><Spin size='large' /> </div> : <div style={{ display: 'flex', justifyContent: 'flex-start', padding: '10px 0px' }}>
                                <h1><b>Accessibility Questionaire</b></h1>
                            </div>}

                            {data.business_questionaire && data.business_questionaire.goods_services_access && data.business_questionaire.goods_services_access.map(check => {
                                return <div className='input-field'>
                                    <b><span>▶ {check.question}</span></b>
                                    <br />

                                    <Radio.Group onChange={e => this.onChangeGsa(e, check.id)} value={check.id === 'GSA_1' ? GSA_1.toString() : check.id === 'GSA_2' ? GSA_2.toString() : GSA_3.toString()}>
                                        <Radio value='Yes'>Yes</Radio>
                                        <Radio value='No'>No</Radio>
                                    </Radio.Group>

                                    {check.id === 'GSA_1' && GSA_1 === 'Yes' && Object.keys(check.feature_checks_yes).map(q => {
                                        return <div className='input-field'><span>&#8226;  {q}</span>
                                            <br />
                                            <Radio.Group onChange={e => this.onChangegsa1(e, q)} value={q.replace(/ /g, "_") === 'Lift_door_is_36inch_wide' ? Lift_door_is_36inch_wide : close_to_accessible_entrance}>
                                                <Radio value='Yes'>Yes</Radio>
                                                <Radio value='No'>No</Radio>
                                            </Radio.Group></div>
                                    })}

                                    {check.id === 'GSA_2' && GSA_2 === 'Yes' && Object.keys(check.feature_checks_yes).map(q => {
                                        return <div className='input-field'><span>&#8226; {q}</span>
                                            <br />
                                            <Radio.Group onChange={e => this.onChangegsa1(e, q)} value={q.replace(/ /g, "_") === "5-foot_circle" ? foot_circle : shaped_space_for_wheelchairs}>
                                                <Radio value='Yes'>Yes</Radio>
                                                <Radio value='No'>No</Radio>
                                            </Radio.Group></div>
                                    })}

                                    {check.id === 'GSA_3' && GSA_3 === 'Yes' && Object.keys(check.feature_checks_yes).map(q => {
                                        return <div className='input-field'><span>&#8226; {q}</span>
                                            <br />
                                            <Radio.Group onChange={e => this.onChangegsa1(e, q)} value={q.replace(/ /g, "_") === 'mounted_80inchs_high' ? mounted_80inchs_high : Letters_at_least_3_inches}>
                                                <Radio value='Yes'>Yes</Radio>
                                                <Radio value='No'>No</Radio>
                                            </Radio.Group></div>
                                    })}
                                </div>

                            })}


                            {data.business_questionaire && data.business_questionaire.accessible_entrance && data.business_questionaire.accessible_entrance.map(check => {
                                return <div className='input-field'>
                                    <b><span>▶ {check.question}</span></b>

                                    <br />

                                    <Radio.Group onChange={e => this.onChangeGsa(e, check.id)} defaultValue={check.response} value={AE_1}>
                                        <Radio value='Yes'>Yes</Radio>
                                        <Radio value='No'>No</Radio>
                                    </Radio.Group>

                                    {check.id === 'AE_1' && AE_1 === 'Yes' && Object.keys(check.feature_checks_yes).map(q => {
                                        return <div className='input-field'><span>&#8226;  {q}</span>
                                            <br />

                                            <Radio.Group onChange={e => this.onChangegsa1(e, q)} value={q.replace(/ /g, "_") === 'Anti_Slip_Ramp' ? Anti_Slip_Ramp : q.replace(/ /g, "_") === "Hand_Rail_greater_than_6_feet" ? Hand_Rail_greater_than_6_feet : Ramp_Raises_less_than_30_inches}>
                                                <Radio value='Yes'>Yes</Radio>
                                                <Radio value='No'>No</Radio>
                                            </Radio.Group></div>
                                    })}
                                </div>

                            })}

                            {data.business_questionaire && data.business_questionaire.accessible_parking && data.business_questionaire.accessible_parking.map(check => {
                                return <div className='input-field'>
                                    <b><span>▶ {check.question}</span></b>

                                    <br />

                                    <Radio.Group onChange={e => this.onChangeGsa(e, check.id)} defaultValue={check.response} value={AP_1}>
                                        <Radio value='Yes'>Yes</Radio>
                                        <Radio value='No'>No</Radio>
                                    </Radio.Group>

                                    {check.id === 'AP_1' && AP_1 === 'Yes' && Object.keys(check.feature_checks_yes).map(q => {
                                        return <div className='input-field' ><span>&#8226;  {q}</span>
                                            <br />

                                            <Radio.Group onChange={e => this.onChangegsa2(e, q)} value={q.replace(/ /g, "_") === '8-foot-wide_access' ? wide_access : close_to_accessible_entrance2}>
                                                <Radio value='Yes'>Yes</Radio>
                                                <Radio value='No'>No</Radio>
                                            </Radio.Group></div>
                                    })}
                                </div>

                            })}


                            {data.business_questionaire && data.business_questionaire.rest_room_usability && data.business_questionaire.rest_room_usability.map(check => {
                                return <div className='input-field'>
                                    <b><span>▶ {check.question}</span></b>
                                    <br />

                                    <Radio.Group onChange={e => this.onChangeGsa(e, check.id)} defaultValue={check.response} value={RU_1}>
                                        <Radio value='Yes'>Yes</Radio>
                                        <Radio value='No'>No</Radio>
                                    </Radio.Group>

                                    {check.id === 'RU_1' && RU_1 === 'Yes' && Object.keys(check.feature_checks_yes).map(q => {
                                        return <div className='input-field'><span>&#8226; {q}</span>
                                            <br />

                                            <Radio.Group onChange={e => this.onChangegsa1(e, q)} value={q.replace(/ /g, "_") === '30-inch-wide' ? wide_inch : q.replace(/ /g, "_") === '48-inch-deep' ? deep_inch : q.replace(/ /g, "_") === 'rim_no_higher_than_34_inches' ? rim_no_higher_than_34_inches : toilet_seat_17_to_19_inches_high}>
                                                <Radio value='Yes'>Yes</Radio>
                                                <Radio value='No'>No</Radio>
                                            </Radio.Group></div>
                                    })}
                                </div>

                            })}




                            <div className='field'><span className='label'>Category <span style={{ color: 'red' }}>*</span></span><Select
                                // mode="multiple"
                                showSearch
                                // labelInValue
                                value={business_category}
                                placeholder="Select category"
                                notFoundContent={fetching ? <Spin size="small" /> : null}
                                filterOption={false}
                                onSearch={this.fetchUser}
                                onChange={this.handleChange}
                                style={{ width: '100%' }}
                            >
                                {dataSelect.map(d => (
                                    <Option key={d}>{d.replace('_', ' ')}</Option>
                                ))}
                                {/* <Option key={dataSelect}>{dataSelect}</Option> */}

                            </Select></div>
                            {categoryHelper && <span style={{ color: 'red', fontSize: 12 }}>Please add a category</span>}



                            <div className='field'><span className='label'>Location <span style={{ color: 'red' }}>*</span></span><Select
                                // mode="single"
                                showSearch
                                labelInValue
                                value={valueLoc}
                                placeholder="Select a location"
                                notFoundContent={fetchingLoc ? <Spin size="small" /> : null}
                                showArrow={false}
                                filterOption={false}
                                onSearch={this.fetchUserLoc}
                                onSelect={this.handleChangeLoc}
                                onChange={this.onChangeLoc}
                                style={{ width: '100%' }}

                            >
                                {dataLoc.map(d => (
                                    <Option style={{ textTransform: 'capitalize' }} key={d.loc}>{d.title}</Option>
                                ))}
                            </Select></div>
                            {locationHelper && <span style={{ color: 'red', fontSize: 12 }}>Please add a location</span>}


                            <br />


                            <div className='helper'><p>Couldn't find it on search? Help us locating you by moving the pin on the map</p></div>
                            <BusinessMap
                                value={valueLoc}
                                lat={lat}
                                lng={lng}
                                search={search}
                                setLocation={this.setLocation}
                                handleSearch={this.handleSearch}
                            />

                            <br />
                            <br />
                            <div style={{ display: 'flex', justifyContent: 'center', padding: '20px 0px' }}>
                                <Button type="primary" htmlType="submit" className='button-sub' onClick={this.handleClick} loading={this.props.getGBusLoading}>Submit</Button>
                            </div>

                        </Card>
                    </>}
            </div>
        )
    }

}


const mapStateToProps = state => {
    return {
        getProfileBusLoading: state.profileReducer.getProfileBusLoading,
        getProfileBusSuccess: state.profileReducer.getProfileBusSuccess,
        getLocationLoading: state.accessibilityReducer.getLocationLoading,
        getLocationSuccess: state.accessibilityReducer.getLocationSuccess,
        isAuthenticated: state.authReducer.email !== null,
        getGBusLoading: state.profileReducer.getGBusLoading


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getProfileBus: (res) => dispatch(actions.getProfileBus(res)),
        getLocation: (data, res) => dispatch(actions.getLocation(data, res)),
        getChart: (data, res) => dispatch(actions.getChart(data, res)),
        busProfile: (data, res) => dispatch(actions.busProfile(data, res)),
        getGBus: (res) => dispatch(actions.getGBus(res)),


    }
}
export default connect(mapStateToProps, mapDispatchToProps)(BusinessCommunity)