import React, { Component } from 'react'
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './stackes.css';


class ColumnChart extends Component {



    setHoverData = (e) => {
        // The chart is not updated because `chartOptions` has not changed.
        this.setState({ hoverData: e.target.category })
    }



    render() {
        const chartOptions = {
            title: {
                text: 'No. of people use our platform to go to the business we help advertise'
            },
            xAxis: {
                categories: ['Business', 'Category', 'World'],
                max: 3
            },
            labels: {
                items: [{
                    html: 'Total disabilities',
                    style: {
                        left: '50px',
                        top: '18px',
                        color: ( // theme
                            Highcharts.defaultOptions.title.style &&
                            Highcharts.defaultOptions.title.style.color
                        ) || 'black'
                    }
                }]
            },
            series: [{
                type: 'column',
                name: 'Wheelchair',
                data: [this.props.chartData && this.props.chartData.business && this.props.chartData.business.wheelchair ? this.props.chartData.business.wheelchair : 0
                    , this.props.chartData && this.props.chartData.category && this.props.chartData.category.wheelchair ? this.props.chartData.category.wheelchair : 0,
                this.props.chartData && this.props.chartData.world && this.props.chartData.world.wheelchair ? this.props.chartData.world.wheelchair : 0]
            }, {
                type: 'column',
                name: 'Autism',
                data: [this.props.chartData && this.props.chartData.business && this.props.chartData.business.autism ? this.props.chartData.business.autism : 0
                    , this.props.chartData && this.props.chartData.category && this.props.chartData.category.autism ? this.props.chartData.category.autism : 0,
                this.props.chartData && this.props.chartData.world && this.props.chartData.world.autism ? this.props.chartData.world.autism : 0]
            }, {
                type: 'column',
                name: 'Hearing Impairment',
                data: [this.props.chartData && this.props.chartData.business && this.props.chartData.business.hear ? this.props.chartData.business.hear : 0
                    , this.props.chartData && this.props.chartData.category && this.props.chartData.category.hear ? this.props.chartData.category.hear : 0,
                this.props.chartData && this.props.chartData.world && this.props.chartData.world.hear ? this.props.chartData.world.hear : 0]
            }, {
                type: 'column',
                name: 'Visual Impairment',
                data: [this.props.chartData && this.props.chartData.business && this.props.chartData.business.visual ? this.props.chartData.business.visual : 0
                    , this.props.chartData && this.props.chartData.category && this.props.chartData.category.visual ? this.props.chartData.category.visual : 0,
                this.props.chartData && this.props.chartData.world && this.props.chartData.world.visual ? this.props.chartData.world.visual : 0]
            },  {
                type: 'column',
                name: 'No Disability',
                data: [this.props.chartData && this.props.chartData.business && this.props.chartData.business.none ? this.props.chartData.business.none : 0
                    , this.props.chartData && this.props.chartData.category && this.props.chartData.category.none ? this.props.chartData.category.none : 0,
                this.props.chartData && this.props.chartData.world && this.props.chartData.world.none ? this.props.chartData.world.none : 0]
            },{
                type: 'spline',
                name: 'Average',
                data: [this.props.chartData && this.props.chartData.business && this.props.chartData.business.average ? this.props.chartData.business.average : 0
                    , this.props.chartData && this.props.chartData.category && this.props.chartData.category.average ? this.props.chartData.category.average : 0,
                this.props.chartData && this.props.chartData.world && this.props.chartData.world.average ? this.props.chartData.world.average : 0],
                marker: {
                    lineWidth: 2,
                    lineColor: Highcharts.getOptions().colors[3],
                    fillColor: 'white'
                }
            }, {
                type: 'pie',
                name: 'Total consumption',
                data: [{
                    name: 'Wheelchair',
                    y: this.props.chartData && this.props.chartData.business && this.props.chartData.business.wheelchair ? this.props.chartData.business.wheelchair : 0
                        + this.props.chartData && this.props.chartData.category && this.props.chartData.category.wheelchair ? this.props.chartData.category.wheelchair : 0 +
                            this.props.chartData && this.props.chartData.world && this.props.chartData.world.wheelchair ? this.props.chartData.world.wheelchair : 0,
                    color: Highcharts.getOptions().colors[0] // Jane's color
                }, {
                    name: 'Autism',
                    y: this.props.chartData && this.props.chartData.business && this.props.chartData.business.autism ? this.props.chartData.business.autism : 0
                        + this.props.chartData && this.props.chartData.category && this.props.chartData.category.autism ? this.props.chartData.category.autism : 0 +
                            this.props.chartData && this.props.chartData.world && this.props.chartData.world.autism ? this.props.chartData.world.autism : 0,
                    color: Highcharts.getOptions().colors[1] // John's color
                }, {
                    name: 'Hearing Impairment',
                    y: this.props.chartData && this.props.chartData.business && this.props.chartData.business.hear ? this.props.chartData.business.hear : 0
                        + this.props.chartData && this.props.chartData.category && this.props.chartData.category.hear ? this.props.chartData.category.hear : 0 +
                            this.props.chartData && this.props.chartData.world && this.props.chartData.world.hear ? this.props.chartData.world.hear : 0,
                    color: Highcharts.getOptions().colors[2] // Joe's color
                }, {
                    name: 'Visual Impairment',
                    y: this.props.chartData && this.props.chartData.business && this.props.chartData.business.visual ? this.props.chartData.business.visual : 0
                        + this.props.chartData && this.props.chartData.category && this.props.chartData.category.visual ? this.props.chartData.category.visual : 0 +
                            this.props.chartData && this.props.chartData.world && this.props.chartData.world.visual ? this.props.chartData.world.visual : 0,
                    color: Highcharts.getOptions().colors[3] // Joe's color
                }, {
                    name: 'No Disability',
                    y: this.props.chartData && this.props.chartData.business && this.props.chartData.business.none ? this.props.chartData.business.none : 0
                        + this.props.chartData && this.props.chartData.category && this.props.chartData.category.none ? this.props.chartData.category.none : 0 +
                            this.props.chartData && this.props.chartData.world && this.props.chartData.world.none ? this.props.chartData.world.none : 0,
                    color: Highcharts.getOptions().colors[3] // Joe's color
                }],
                center: [160, 40],
                size: 80,
                showInLegend: false,
                dataLabels: {
                    enabled: false
                }
            }]
        }

        return (
            <div>
                <HighchartsReact
                    highcharts={Highcharts}
                    options={chartOptions}
                />
            </div>
        )
    }
}


export default ColumnChart