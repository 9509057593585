import React, { Component } from 'react';
import {withRouter} from 'react-router-dom'
import { connect } from 'react-redux';
import { Spin } from 'antd';
import * as actions from '../../../store/actions/index'
import Notification from '../../Resusable/Notification/index';



class ValpiFirstTimeLogin extends Component {
    state = {
        email: '',
        password: '',
        loading: false
    }
    componentDidMount() {
        let serachStr = '';
        if(this.props.location.search){
            serachStr = this.props.location.search;
            let [assistid, resultstr] = serachStr.split('&');
            assistid = assistid.replace('?assistid=', '')
            let [assistvalpassword, role] = resultstr.split('/');
            role = role.replace('role=', '')
            assistvalpassword = assistvalpassword.replace('assistvalpassword=', '')
            this.setState({assistid, assistvalpassword, loading: true})
            const credentials = {
                data : {
                    assistid: assistid,
                    assistvalpassword: assistvalpassword,
                    role:role
                }
               
            };
            this.props.resetValpiPassword(credentials, res => {
                if (res.status === 200) {
                    this.setState({ loading: false})
                     this.props.history.push({pathname: '/valpi/reset-profile', state:{ email: res.data.auth_user_email, role: role}});

    
                }   else if (res.status === 777){
                    Notification('error', res.message);
    
                } else {
                    this.setState({ loading: false})
                    Notification('error', res.message);
                    // this.props.history.push('/');

                }
            });


        }

        console.log(this.props.match)
    }
    render() {
        const {loading} = this.state;
        return (
           loading ? <Spin/> : null
        );
    }
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.email !== null,


    }
}
const mapDispatchToProps = dispatch => {
    return {
        resetValpiPassword: (credentials, res) => dispatch(actions.resetValpiPassword(credentials, res))

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ValpiFirstTimeLogin))