import React, { useState, useEffect, Fragment } from 'react';
import { Tag, Select, Spin, Statistic, Card, Row, Col, Dropdown, Tooltip, Popover, Button } from 'antd';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Voiceover from '../Voiceover/Voiceover'
import ReactMapbox from './ReactMapbox'
import Vector from '../../assets/images/AIDA-Logo.gif';
import wordsToNumbers from 'words-to-numbers';
import { InfoCircleOutlined, ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';
import { Flex } from 'antd-mobile';
import GreenpoiURl from '../../assets/images/Ellipse4.png';
import RedpoiURl from '../../assets/images/Inaccessible.svg';
import OrangepoiURl from '../../assets/images/PartiallyAccessible.svg';
import PurplepoiURL from '../../assets/images/PredictionShape.svg';
import Pin from '../../assets/images/pin.png';
import { withRouter } from 'react-router-dom';

import './accessibilityInfo.css';
import { GApageView } from '../../utils/index'
import ReCAPTCHA from "react-google-recaptcha";

const { Option } = Select;

const { CheckableTag } = Tag;

const tagsData = ['Parking', 'Food & drinks', 'Shops', 'Accomodation', 'Attractions'];

const AccessibilityInfo = (props) => {
    const [data, setData] = useState([]);
    const [fetching, setFetching] = useState(false);
    const [value, setValue] = useState([]);
    const [lat, setLat] = useState(0);
    const [lng, setLng] = useState(0);
    const [search, setSearch] = useState(false);
    const [searchCard, setSearchCard] = useState(false);

    const [busData, setBusData] = useState([])
    const [trainData, setTrainData] = useState([]);
    const [DLRData, setDLRData] = useState([]);
    const [BUSINESSData, setBUSINESSData] = useState([]);
    const [accessInfo, setaccessInfo] = useState(false);
    const [poiData, setPoiData] = useState([])
    const [searchDisabled, setSearchDisabled] = useState(true);

    const [aida, setAida] = useState(false)
    const [showShowing, setshowShowing] = useState(false);
    const [first, setfirst] = useState(false);
    const [noResult, setNoResult] = useState(false);
    const [results, setResults] = useState(false);

    const [GpoiData, setGPoiData] = useState([])
    const [OpoiData, setOPoiData] = useState([])
    const [RpoiData, setRPoiData] = useState([])
    const [PpoiData, setPPoiData] = useState([])

    const [loading, setLoading] = useState(false);
    const [favourite, setFavourite] = useState([]);
    const [work, setWork] = useState([])
    const [home, setHome] = useState([])

    const [loggedInDis, setLoggedInDis] = useState([])

    const [pinClick, setPinClick] = useState(false)

    const [showGreen, setShowGreen] = useState(true);
    const [showRed, setShowRed] = useState(true);
    const [showOrange, setShowOrange] = useState(true);


    const [selectedTags, setSelectedTags] = useState([])
    const [catData, setCatData] = useState([]);
    const [captcha, setCaptcha] = useState(false);



    const fetchUser = value => {
        setValue(value)
        setData([]);
        setFetching(true)
        props.getLocation(value, res => {
            if (!res) {
                setData([]);
                setFetching(false)
                setSearch(false)
                setSearchCard(false)
            } else {
                const results = [];
                if(value.split(".").length - 1 === 2){
                    results.push({ 
                        loc: [res.coordinates.lat, res.coordinates.lng],
                        title: res.place_name,
                        word: res.words
                    })
                } else {
                    res.map(val => {
                        results.push({
                            loc: val.geometry.coordinates,
                            title: val.place_name,
                            word: val.word
                        })
                    })
                }
                setData(results);
                setFetching(false)
                setSearch(false)
                setSearchCard(false)
                setResults(true);
                setfirst(false);
            }
        })
    };

    useEffect(() => {
        GApageView(props.location.pathname);

        fetch();
        if (props.isAuthenticated) {
            if (sessionStorage.getItem('role') === 'user') {
                props.getProfileGamm(res => {
                    if (!res) {
                    } else {
                    }
                })
            } else {
                props.getProfileBus(res => {
                    if (!res) {
                    } else {
                    }
                })
            }
        }
    }, [])

    const fetch = () => {
        setLoading(true)

        props.getProfile(res => {
            if (!res) {
                setLoading(false)

            } else {
                setFavourite(res.data.favourites_sp);
                setLoggedInDis(res.data.user_disability)
                setWork(res.data.work_sp);
                setHome(res.data.home_sp);
                setLoading(false)

            }

        })
    }
    useEffect(() => {
        if (props.location.state === undefined && value.length === 0 && props.accessSearch.key && props.accessSearch.key.length > 0) {
            setValue(props.accessSearch)
            handleChange(props.accessSearch)
        }
        if (props.location.state && props.location.state.place) {
            let data = {
                key: `${props.location.state.place.longitude}, ${props.location.state.place.latitude}`,
                label: `${props.location.state.place.location_name}`,
                value: `${props.location.state.place.longitude}, ${props.location.state.place.latitude}`,

            }
            setValue(data)
            handleChange(data)
        } if (props.location.state === undefined && value.length === 0 && props.accessSearch.key === undefined && props.location.pathname.length < 4) {
            navigator.geolocation.getCurrentPosition((position) => {
                let data = {
                    key: `${position.coords.longitude}, ${position.coords.latitude}`,
                    label: `Your Location`,
                    value: `${position.coords.longitude}, ${position.coords.latitude}`,

                }

                setValue(data)
                handleChange(data)
            })
        } if (props.location.pathname.length > 5 && props.location.state === undefined && value.length === 0 && props.accessSearch.key === undefined) {
            let urlVal = decodeURIComponent(props.location.pathname);
            let result = urlVal.split('/');
            let long = result[2];
            let lati = result[3];
            let locat = result[4];
            if (long === 'nan' || lat === 'nan') {
                navigator.geolocation.getCurrentPosition((position) => {
                    let data = {
                        key: `${position.coords.longitude}, ${position.coords.latitude}`,
                        label: `Your Location`,
                        value: `${position.coords.longitude}, ${position.coords.latitude}`,

                    }

                    setValue(data)
                    handleChange(data)
                })
            } else {
                let data = {
                    key: `${long}, ${lati}`,
                    label: `${locat}`,
                    value: `${long}, ${lati}`,

                }

                setValue(data)
                handleChange(data)
            }

        }

    }, [])

    useEffect(() => {
        if (value && value.value && value.value.length > 0) {
            handleChange(value)
        }
    }, [selectedTags.length])



    const handleChange = (value) => {
        let lat = value && value.key.split(",");
        console.log(lat);
        setLat(lat[1]);
        setLng(lat[0]);
        setData([]);
        setFetching(false)
        setValue(value)
        props.saveSearch(value);
        let search = {
            data: {
                email: sessionStorage.getItem('email'),
                search_loc: {
                    location_name: value && value.label,
                    latitude: lat[1],
                    longitude: lat[0]
                }
            }
        }
        props.addSearch(search, res => {
            if (!res) {

            } else {
                console.log(res)

            }
        })

        const position = { lat: lat[1].replace(/ /g, ""), lng: lat[0].replace(/ /g, "") }
        props.getBus(position, res => {
            if (!res) {

            } else {
                setBusData(res.bus_data)

            }
        })

        props.getTrain(position, res => {
            if (!res) {

            } else {
                setTrainData(res.df_train)
            }
        })

        props.getDLR(position, res => {
            if (!res) {

            } else {
                setDLRData(res.data)
            }
        })

        props.getBUSINESS(res => {
            if (!res) {

            } else {
                setBUSINESSData(res.business_plot)
            }
        })
        if (selectedTags.length > 0) {
            let newPost = {
                lat: lat[1].replace(/ /g, ""),
                lng: lat[0].replace(/ /g, ""),
                category: true
            }
            props.getPoi(newPost, res => {
                if (!res) {

                } else {
                    if(res.captcha_flag){
                        setCaptcha(true)
                    }
                    setPoiData(res);
                    let green = [];
                    let red = [];
                    let orange = [];
                    let purple = [];
                    setGPoiData(green);
                    setOPoiData(orange);
                    setRPoiData(red)
                    setPPoiData(purple)
                    if (props.isAuthenticated && sessionStorage.getItem('role') === 'user') {
                        props.getProfileSuccess &&
                            props.getProfileSuccess.user_disability &&
                            props.getProfileSuccess.user_disability.map(disPref => {
                                if (Object.values(disPref).indexOf('wheelchair') >= 0) {
                                    if (Object.values(selectedTags).indexOf('Accomodation') >= 0) {
                                        Object.keys(res.categories.accomodation).map(val => {
                                            if (res.categories.accomodation[val].accessibility_cat) {
                                                if (res.categories.accomodation[val].accessibility_cat === 'wheelchair' && res.categories.accomodation[val].accessibility_value === true) {
                                                    green.push(res.categories.accomodation[val])
                                                } else if (res.categories.accomodation[val].accessibility_cat === 'partial_wheelchair' && res.categories.accomodation[val].accessibility_value === true) {
                                                    orange.push(res.categories.accomodation[val])

                                                } else {
                                                    red.push(res.categories.accomodation[val])
                                                }
                                            }
                                        });
                                    }
                                    if (Object.values(selectedTags).indexOf('Attractions') >= 0) {
                                        Object.keys(res.categories.attraction).map(val => {
                                            if (res.categories.attraction[val].accessibility_cat) {
                                                if (res.categories.attraction[val].accessibility_cat === 'wheelchair' && res.categories.attraction[val].accessibility_value === true) {
                                                    green.push(res.categories.attraction[val])
                                                } else if (res.categories.attraction[val].accessibility_cat === 'partial_wheelchair' && res.categories.attraction[val].accessibility_value === true) {
                                                    orange.push(res.categories.attraction[val])

                                                } else {
                                                    red.push(res.categories.attraction[val])
                                                }
                                            }
                                        });
                                    }
                                    if (Object.values(selectedTags).indexOf('Shops') >= 0) {
                                        Object.keys(res.categories.shops).map(val => {
                                            if (res.categories.shops[val].accessibility_cat) {
                                                if (res.categories.shops[val].accessibility_cat === 'wheelchair' && res.categories.shops[val].accessibility_value === true) {
                                                    green.push(res.categories.shops[val])
                                                } else if (res.categories.shops[val].accessibility_cat === 'partial_wheelchair' && res.categories.shops[val].accessibility_value === true) {
                                                    orange.push(res.categories.shops[val])

                                                } else {
                                                    red.push(res.categories.shops[val])
                                                }
                                            }
                                        });
                                    }

                                    if (Object.values(selectedTags).indexOf('Food & drinks') >= 0) {
                                        Object.keys(res.categories.food_and_drinks).map(val => {
                                            if (res.categories.food_and_drinks[val].accessibility_cat) {
                                                if (res.categories.food_and_drinks[val].accessibility_cat === 'wheelchair' && res.categories.food_and_drinks[val].accessibility_value === true) {
                                                    green.push(res.categories.food_and_drinks[val])
                                                } else if (res.categories.food_and_drinks[val].accessibility_cat === 'partial_wheelchair' && res.categories.food_and_drinks[val].accessibility_value === true) {
                                                    orange.push(res.categories.food_and_drinks[val])

                                                } else {
                                                    red.push(res.categories.food_and_drinks[val])
                                                }
                                            }
                                        });
                                    }

                                    if (Object.values(selectedTags).indexOf('Parking') >= 0) {
                                        Object.keys(res.categories.parking).map(val => {
                                            if (res.categories.parking[val].accessibility_cat) {
                                                if (res.categories.parking[val].accessibility_cat === 'wheelchair' && res.categories.parking[val].accessibility_value === true) {
                                                    green.push(res.categories.parking[val])
                                                } else if (res.categories.parking[val].accessibility_cat === 'partial_wheelchair' && res.categories.parking[val].accessibility_value === true) {
                                                    orange.push(res.categories.parking[val])

                                                } else {
                                                    red.push(res.categories.parking[val])
                                                }
                                            }
                                        });
                                    }

                                }

                            }

                            )
                    }


                    setGPoiData(green);
                    setOPoiData(orange);
                    setRPoiData(red)
                    setPPoiData(purple)
                    setAida(false)
                    setSearch(true);
                    setSearchCard(true);
                    setSearchCard(true);
                }
            })

        } else {
            let newPost = {
                lat: lat[1].replace(/ /g, ""),
                lng: lat[0].replace(/ /g, ""),
                category: false
            }

            props.getPoi(newPost, res => {
                if (!res) {

                } else {
                    if(res.captcha_flag){
                        setCaptcha(true)
                    }
                    setPoiData(res);
                    let green = [];
                    let red = [];
                    let orange = [];
                    let purple = [];

                    console.log(props.disability);
                    if (props.isAuthenticated && sessionStorage.getItem('role') === 'user') {
                        props.getProfileSuccess &&
                            props.getProfileSuccess.user_disability &&
                            props.getProfileSuccess.user_disability.map(disPref => {
                                if (Object.values(disPref).indexOf('wheelchair') >= 0) {

                                    Object.keys(res.place_infos).map(val => {
                                        if (res.place_infos[val].accessibility_cat) {
                                            if (res.place_infos[val].accessibility_cat === 'wheelchair' && res.place_infos[val].accessibility_value === true) {
                                                green.push(res.place_infos[val])
                                            } else if (res.place_infos[val].accessibility_cat === 'partial_wheelchair' && res.place_infos[val].accessibility_value === true) {
                                                orange.push(res.place_infos[val])

                                            } else {
                                                red.push(res.place_infos[val])
                                            }
                                        }
                                    });
                                    Object.keys(res.historical_preds).map(val => {
                                        purple.push(res.historical_preds[val])

                                    });
                                    Object.keys(res.knn_current_preds).map(val => {
                                        purple.push(res.knn_current_preds[val])

                                    });
                                } if (Object.values(disPref).indexOf('autism') >= 0) {
                                    Object.keys(res.place_infos).map(val => {
                                        if (res.place_infos[val].accessibility_cat) {
                                            if (res.place_infos[val].accessibility_cat === 'autism' && res.place_infos[val].accessibility_value === true) {
                                                green.push(res.place_infos[val])
                                            } else if (res.place_infos[val].accessibility_cat === 'partial_autism' && res.place_infos[val].accessibility_value === true) {
                                                orange.push(res.place_infos[val])

                                            } else {
                                                red.push(res.place_infos[val])
                                            }
                                        }
                                    });
                                    Object.keys(res.historical_preds).map(val => {
                                        purple.push(res.historical_preds[val])

                                    });
                                    Object.keys(res.knn_current_preds).map(val => {
                                        purple.push(res.knn_current_preds[val])

                                    });


                                }

                            }

                            )
                    }


                    setGPoiData(green);
                    setOPoiData(orange);
                    setRPoiData(red)
                    setPPoiData(purple)
                    setAida(false)
                    setSearch(true);
                    setSearchCard(true);
                    setSearchCard(true);

                }
            })
        }
        setSearch(true);
        setSearchCard(true);


    };

    const onChange = value => {
        setValue(value)
    }

    const handleSearch = () => {
        setSearch(false);
        setSearchCard(false);

    }

    const handleSearchCard = () => {
        setSearchCard(true);

    }
    const disableSearch = () => {
        setSearchDisabled(false)
    }

    const finding = (transcript) => {
        var regex = /\d/g;
        if (transcript.includes('number')
            || transcript.includes('Number') || regex.test(transcript)) {

            setResults(false)
            setshowShowing(true)

            let answer = transcript.replace("number", "");
            let selection = wordsToNumbers(answer); //100
            let coord = data.filter((val, i) => {
                if (i + 1 === parseInt(selection)) {
                    return val
                }
                else {
                    return 0
                }

            })
            if (coord.length > 0) {
                const value = { 'key': coord[0].loc.toString() }
                handleChange(value)
            } else {
                setResults(false)
                setNoResult(true)
                handlepop();
            }

        } else {
            setResults(false)
            setNoResult(true)
            handlepop();

        }
    }

    const handleAida = () => {
        setAida(!aida)
    }



    const setTransript = (transcript) => {
        fetchUser(transcript);


    }

    const handlepop = () => {
        setshowShowing(false);
        setNoResult(false)
        setAida(false)

    }

    const handleAccessInfo = () => {
        setaccessInfo(!accessInfo)
    }

    const handleGreen = () => {
        setShowGreen(!showGreen)
    }

    const handleRed = () => {
        setShowRed(!showRed)
    }

    const handleOrange = () => {
        setShowOrange(!showOrange)
    }

    const handleEvent = (event, name) => {
        if (props.isAuthenticated) {
            let data = {
                platform: window.navigator.platform,
                screen_width: window.screen.width,
                screen_height: window.screen.height,
                client_screen_x: event.clientX,
                client_screen_y: event.clientY,
                interaction_timestamp: Math.round((new Date()).getTime() / 1000),
                user_email: sessionStorage.getItem('email'),
                event_type: name,
            }
            props.dataCollection(data, response => {
                if (!response) {

                } else {
                    console.log(response)
                }
            })
        }

    }

    const handleChangeCategory = (tag, checked) => {
        const nextSelectedTags = checked ? [...selectedTags, tag] : selectedTags.filter(t => t !== tag);
        console.log('You are interested in: ', nextSelectedTags);
        setSelectedTags(nextSelectedTags)

    }

    
    const menu = (
        <Voiceover aida={aida}
            setTransript={setTransript}
            results={results}
            data={data}
            finding={finding}
            showShowing={showShowing}
            first={first}
            noResult={noResult}
            handleAida={handleAida}
        />

    );

    return (
        <Fragment>

            <div className='style'>
                <div className='acc-div'>
                    <p className='check-acc'>Check the place’s accessibility info before your travel</p>
                </div>
                {window.screen.width > 600 ?
                    <div className='search-div-acc' onClick={(event) => handleEvent(event, 'accessibilty search box')}>
                        <div className='search-select'>
                            <div className='search-aida'>
                                <Select
                                    // mode="single"
                                    showSearch
                                    labelInValue
                                    optionLabelProp="label"
                                    value={value}
                                    placeholder="Select a location"
                                    notFoundContent={fetching ? <Spin size="small" /> : null}
                                    showArrow={false}
                                    filterOption={false}
                                    onSearch={fetchUser}
                                    onSelect={(event) =>  handleChange( event)}
                                    onChange={onChange}
                                    className='search-acc'
                                    disabled={searchDisabled && !props.isAuthenticated && props.welcome}
                                >

                                    {data.map(d => (
                                        <Option key={d.loc} label={d.title}>{d.title}<br/><strong>{d.word}</strong></Option>
                                    ))}
                                </Select>
                                <div className='img-aida'>
                                    <Tooltip
                                        placement="topLeft"
                                        title={"Speak to Aida"}
                                        arrowPointAtCenter
                                    >

                                        <Dropdown
                                            overlay={menu}
                                            onClick={() => {
                                                setAida(!aida);
                                                setfirst(true);
                                                setshowShowing(false);
                                                setNoResult(false);
                                                setResults(false)
                                            }}
                                            visible={aida}
                                            trigger={['click']}
                                            disabled={searchDisabled && !props.isAuthenticated && props.welcome}
                                        >
                                            <img src={Vector} width={45} height={45} className='aida-i' onClick={() => {
                                                setAida(!aida);
                                                setfirst(true);
                                                setshowShowing(false);
                                                setNoResult(false);
                                                setResults(false)
                                            }} />
                                        </Dropdown>
                                    </Tooltip>
                                </div>
                            </div>

                        </div>
                        <div className='legend'>

                            <Button className='one-div' disabled>
                                <img src={PurplepoiURL} className='leg-img' />
                                                Our Assessment
                                            </Button>
                            <Button className='one-div space' onClick={() => handleGreen()} >
                                <img src={GreenpoiURl} className='leg-img ' />
                                                Accessible
                                            </Button>
                            <Button className='one-div space' onClick={() => handleOrange()}>
                                <img src={OrangepoiURl} className='leg-img ' />
                                              Partial Accessible

                                            </Button>
                            <Button className='one-div space' onClick={() => handleRed()}>
                                <img src={RedpoiURl} className='leg-img ' />
                                               Not Accessible
                                            </Button>
                        </div>
                    </div>

                    :

                    <div className='search-div-acc' onClick={(event) => handleEvent(event, 'accessibilty search box')}>

                        <Tooltip
                            placement="topLeft"
                            title={searchDisabled && !props.isAuthenticated && props.welcome ? "Please follow the popup" : ''}
                            arrowPointAtCenter
                        ></Tooltip>
                        <Select
                            // mode="single"
                            showSearch
                            labelInValue
                            optionLabelProp="label"
                            value={value}
                            placeholder="Select a location"
                            notFoundContent={fetching ? <Spin size="small" /> : null}
                            showArrow={false}
                            filterOption={false}
                            onSearch={fetchUser}
                            onSelect={handleChange}
                            onChange={onChange}
                            className='search-acc'
                            disabled={searchDisabled && !props.isAuthenticated && props.welcome}
                        >

                            {data.map(d => (
                                 <Option key={d.loc} label={d.title}>{d.title}<br/><bold>{d.word}</bold></Option>
                            ))}
                        </Select>

                        <div className='legend'>
                            <Button className='one-div' disabled>
                                <img src={PurplepoiURL} className='leg-img' />
                                        Our Assessment
                                    </Button>
                            <Button className='one-div ' onClick={() => handleGreen()} >
                                <img src={GreenpoiURl} className='leg-img ' />
                                   Accessible
                                    </Button>
                            <Button className='one-div ' onClick={() => handleOrange()}>
                                <img src={OrangepoiURl} className='leg-img ' />
                                    Partial Accessible

                                    </Button>
                            <Button className='one-div ' onClick={() => handleRed()}>
                                <img src={RedpoiURl} className='leg-img ' />
                                       Not Accessible

                                    </Button>
                        </div>

                    </div>}
                {searchCard && window.screen.width < 600 && <div className='fixed-widgets' onClick={() => handleAccessInfo()}>
                    <span className='fx-w'>
                        <InfoCircleOutlined />
                    </span>

                </div>}

                {props.accessSearch !== undefined && props.accessSearch.length > 0 && props.accessSearch.map(sj => <p style={{ color: 'black', fontSize: 14, marginTop: '8px' }} >{sj}</p>)}
                <div>
                    <span className='category-title'>Categories:</span>
                    {tagsData.map(tag => (
                        <CheckableTag
                            key={tag}
                            checked={selectedTags.indexOf(tag) > -1}
                            onChange={checked => handleChangeCategory(tag, checked)}
                        >
                            {tag}
                        </CheckableTag>
                    ))}
                </div>
                <div>
                    <div className='gotissue' >Got an issue to report? Click <img src={Pin} height={65} onClick={() => setPinClick(!pinClick)} style={{ margin: '-16px' }} /> once and drop the pin on the map to report. </div>
                </div>
                <div className='leaf-div' onClick={(event) => handleEvent(event, 'accessibilty map box')}>
                    <ReactMapbox
                        lat={lat}
                        lng={lng}
                        search={search}
                        searchCard={searchCard}
                        busData={busData}
                        trainData={trainData}
                        poiData={poiData}
                        handleSearch={handleSearch}
                        handleSearchCard={handleSearchCard}
                        disableSearch={disableSearch}
                        value={value}
                        {...props}
                        selectedTags={selectedTags}
                        GpoiData={GpoiData}
                        RpoiData={RpoiData}
                        OpoiData={OpoiData}
                        PpoiData={PpoiData}
                        accessInfo={accessInfo}
                        handleAccessInfo={handleAccessInfo}
                        favourite={favourite}
                        work={work}
                        home={home}
                        isAuthenticated={props.isAuthenticated}
                        noWelcome={() => props.noWelcome()}
                        welcome={props.welcome}
                        loggedInDis={loggedInDis}
                        pinClick={pinClick}
                        DLRData={DLRData}
                        BUSINESSData={BUSINESSData}
                        catData={catData}
                        showGreen={showGreen}
                        showOrange={showOrange}
                        showRed={showRed}

                    />
                </div>
            </div>
        </Fragment>
    )
}
const mapStateToProps = state => {
    return {
        getLocationLoading: state.accessibilityReducer.getLocationLoading,
        getLocationSuccess: state.accessibilityReducer.getLocationSuccess,
        getTrainLoading: state.accessibilityReducer.getTrainLoading,
        getBusLoading: state.accessibilityReducer.gSizetBusLoading,
        getProfileLoading: state.profileReducer.getProfileLoading,
        getProfileSuccess: state.profileReducer.getProfileSuccess,
        isAuthenticated: state.authReducer.email !== null,
        email: state.authReducer.email,
        welcome: state.accessibilityReducer.welcome,
        disability: state.dataCollectionReducer.disability,
        accessSearch: state.accessibilityReducer.accessSearch,
        getProfileGammLoading: state.profileReducer.getProfileGammLoading,
        getProfileGammSuccess: state.profileReducer.getProfileGammSuccess,


    }
}
const mapDispatchToProps = dispatch => {
    return {
        getLocation: (data, res) => dispatch(actions.getLocation(data, res)),
        getBus: (data, res) => dispatch(actions.getBus(data, res)),
        getTrain: (data, res) => dispatch(actions.getTrain(data, res)),
        getPoi: (data, res) => dispatch(actions.getPoi(data, res)),
        getProfile: (res) => dispatch(actions.getProfile(res)),
        noWelcome: () => dispatch(actions.noWelcome()),
        addSearch: (data, res) => dispatch(actions.addSearch(data, res)),
        dataCollection: (data, res) => dispatch(actions.dataCollection(data, res)),
        getDLR: (data, res) => dispatch(actions.getDLR(data, res)),
        saveSearch: (data) => dispatch(actions.saveSearch(data)),
        getProfileGamm: (res) => dispatch(actions.getProfileGamm(res)),
        getProfileBus: (res) => dispatch(actions.getProfileBus(res)),
        getBUSINESS: (res) => dispatch(actions.getBUSINESS(res)),
        getSearchCat: (data, res) => dispatch(actions.getSearchCat(data, res)),


    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AccessibilityInfo))