import React, { Component } from 'react';
import { Layout, Spin } from 'antd';
import { withRouter, Route, Switch } from 'react-router-dom'
import Sidebar from './components/Sidebar/Sidebar'
import Topbar from './components/Topbar/Topbar'
import AccessibilityInfo from './components/AccessibilityInfo/AccessibilityInfo';
import Navigation from './components/Navigation/Navigation';
import Profile from './components/Profile/Profile';
import GamificationPOI from './components/Gamification/poi';
import GamificationTrain from './components/Gamification/train';
import GamificationBus from './components/Gamification/bus';
import FirstTimeLogin from './components/SignIn/FirstTimeLogin/FirstTimeLogin';
import ValpiFirstTimeLogin from './components/SignIn/FirstTimeLogin/ValpiFirstTimeLogin'
import { connect } from 'react-redux';
import MyPlaces from './components/MyPlaces/MyPlaces';
import BusinessCommunity from './components/BusinessCommunity/BusinessCommunity';
import ReactGA from 'react-ga';
import { GApageView} from './utils/index'
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie'
import './App.css';

const { Footer, Content } = Layout;
class App extends Component {
  state = {
    loading: true
  }

  componentDidMount() {
    this.initGA();
    setTimeout(() => {
      this.setState({ loading: false })
    }, 1200)
    GApageView(this.props.location.pathname); 

  }


  initGA = () => {    

    ReactGA.initialize('UA-127394154-2'); // put your tracking id here
  } 



  render() {
    console.log(sessionStorage.getItem('role'))
    let route = (
      <Switch>
        <Route exact path='/' render={({ match }) => <AccessibilityInfo match={match} />} />
        <Route  path='/accessibility/:param1' render={({ match }) => <AccessibilityInfo match={match} />} />
        <Route exact path='/navigation' render={({ match }) => <Navigation match={match} />} />
        <Route exact path='/feedback/poi' render={({ match }) => <GamificationPOI match={match} />} />
        <Route exact path='/feedback/train' render={({ match }) => <GamificationTrain match={match} />} />
        <Route exact path='/feedback/bus' render={({ match }) => <GamificationBus match={match} />} />
        <Route path='/resetpassword' component={FirstTimeLogin} />
        <Route path='/business/resetpassword' component={FirstTimeLogin} />
        <Route path='/valpi/resetpassword' component={ValpiFirstTimeLogin} />

        <Route exact path='/businessCommunity' component={BusinessCommunity} />

      </Switch>
    )

    if (this.props.isAuthenticated) {
      route = (
        <Switch>
          <Route exact path='/' render={({ match }) => <AccessibilityInfo match={match} />} />
          <Route  path='/accessibility/:param1' render={({ match }) => <AccessibilityInfo match={match} />} />
          <Route exact path='/navigation' render={({ match }) => <Navigation match={match} />} />
           <Route exact path='/businessCommunity' component={BusinessCommunity} /> 
           {sessionStorage.getItem('role')  === "user" &&<Route exact path='/profile' render={({ match }) => <Profile match={match} />} />}
           {sessionStorage.getItem('role')  === "user" &&<Route exact path='/places' render={({ match }) => <MyPlaces match={match} />} />}
          <Route exact path='/feedback/poi' render={({ match }) => <GamificationPOI match={match} />} />
          <Route exact path='/feedback/train' render={({ match }) => <GamificationTrain match={match} />} />
          <Route exact path='/feedback/bus' render={({ match }) => <GamificationBus match={match} />} />
     

        </Switch>
      )
    }
    const { loading } = this.state;
    return (
      loading ? <div className='spining'><Spin size="large" /></div>
        :
        <Layout className="App-header">
          <Topbar />
          <Layout>
            <Sidebar {...this.props} />
            <Layout >
              <Content
                className="site-layout-background"
                style={{
                  padding: '20px 10px',
                  margin: 0,
                  minHeight: 280,
                }}
              >
                {route}

              </Content>
               
            <Footer style={{ textAlign: 'center', backgroundColor:'#fff' }} className='foot'>CityMaaS ©2020 | <a href='https://www.citymaas.io/privacy-policy/' rel="noopener noreferrer"  target='_blank'>Privact Policy</a>| <a href='https://www.citymaas.io/terms' rel="noopener noreferrer"  target='_blank'>Term of use</a> </Footer>


            </Layout>

          </Layout>
          {Cookies.get('PrivacyPolicy') === undefined && <CookieConsent
                location="bottom"
                buttonText="Accept"
                cookieName="PrivacyPolicy"
                style={{ background: "#000", height: 400, opacity: 0.8 }}
                buttonStyle={{ 
                    color: "#fff",
                    fontSize: "13px", 
                    marginRight: 100, 
                    width: 150, 
                    fontWeight: 600,
                    backgroundColor: '#602b82',
                    opacity: 1
                }}
                onAccept={() => {
                    Cookies.set('PrivacyPolicy', 'Accept', { expires: 7 })
                  }}
                declineButtonText="Privacy Policy"
                declineButtonStyle={{ 
                  color: "#000",
                  fontSize: "13px", 
                  marginRight: 100, 
                  width: 150, 
                  fontWeight: 600,
                  backgroundColor: 'yellow',
                  opacity: 1
              }}

              onDecline={()=> {
                window.open('https://www.citymaas.io/privacy-policy/')
              }}
              enableDeclineButton
            >
                
                <span style={{ fontSize: "16px", textAlign:'center', justifyContent:'center', display:'flex' }}>We use cookies to ensure that we give you the best experience on our website. If you continue to use this site we will assume that you are happy with it.</span>
                {/* <span style={{ fontSize: "10px" }}>This bit of text is smaller :O</span> */}
            </CookieConsent>}
        </Layout>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.authReducer.email !== null,
});

export default withRouter(connect(mapStateToProps, null)(App));
