import React, { Component } from 'react';
import ReactMapboxGl, { Marker, ScaleControl, ZoomControl, Popup, Layer, Feature, Cluster } from 'react-mapbox-gl';
import { token } from '../../config';
import styled from 'styled-components';
import locateIcon from '../../assets/images/locate.svg';
import {
    Card,
    Form,
    Button,
    Checkbox,
    Row,
    Col,
    Radio
} from 'antd';
import DragPin from '../../assets/images/pin.png';
import { CloseOutlined } from '@ant-design/icons';
import ReportPin from '../../assets/images/reportPin.svg';

const Map = ReactMapboxGl({
    accessToken: token,
    renderWorldCopies: true
});
const Container = styled.div`
   position: relative;
   height: 100%;
  flex: 1;
   `

const StyledDragPopup = window.screen.width > 600 ? styled.div`
   background: #121859;
   color: #3f618c;
   padding: 12px 20px;
   border-radius: 16px;
   max-width: 300px;
   max-height: 300px;
   overflow-y: auto;
   overflow-x: hidden;
   word-wrap: break-word;
   min-width: 250px;
   `
    :
    styled.div`
   background: #121859;
   color: #3f618c;
   padding: 4px 8px;
   border-radius: 6px;
   font-size: 10px;
   max-width: auto;
   max-height: 250px;
   overflow-y: auto;
   overflow-x: hidden;
   word-wrap: break-word;
   min-width: 200px;
   
   `

const layoutLayerPin = { 'icon-image': 'londonDragPin' }
const imagePin = new Image();
imagePin.src = DragPin;
imagePin.alt = 'dragAlt';
imagePin.width = 70;
imagePin.height = 70;
const imagesDragPin = ['londonDragPin', imagePin]
class BusinessMap extends Component {
    state = {
        center: [-0.09, 53.505],
        haveUserLocation: false,
        zoom: [12],
        searched: true,
        showDragPop: false,
        dragLat: '',
        dragLong: '',
        drag: false

    }

    componentDidMount() {
 
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.longitude, position.coords.latitude],
                    haveUserLocation: true,
                    zoom: [16],
                    dragPosition: [position.coords.longitude, position.coords.latitude]

                })
                this.props.setLocation(position.coords.latitude, position.coords.longitude)


            })
        

    }

    componentWillReceiveProps(prev, next) {
        const { dragLong, dragLat } = this.state;
        if (prev.search) {
            this.setState({
                center: [parseFloat(prev.lng), parseFloat(prev.lat)],
                haveUserLocation: true,
                zoom: [16],
                searched: true,
                drag: false

                // dragPosition: [parseFloat(this.props.lng), parseFloat(this.props.lat)],

            })

        } else if (!prev.search && prev.lng !== 0){
            this.setState({
                center: [parseFloat(prev.lng), parseFloat(prev.lat)],
                haveUserLocation: true,
                zoom: [16],
                searched: true,
                drag: false

                // dragPosition: [parseFloat(this.props.lng), parseFloat(this.props.lat)],

            })

        } else if (!prev.search && prev.lng === 0){
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.longitude, position.coords.latitude],
                    haveUserLocation: true,
                    zoom: [16],
                    dragPosition: [position.coords.longitude, position.coords.latitude]

                })
                this.props.setLocation(position.coords.latitude, position.coords.longitude)


            })
        }


        // if(!this.state.searched){
        //     this.setState({
        //       dragPosition: [parseFloat(this.props.lng), parseFloat(this.props.lat)],
        //     })
        //   }
    }

    markerClickPin = (value) => {
        this.setState({
            dragLat: value.lngLat.lat,
            dragLong: value.lngLat.lng,
            showDragPop: true,
            center: [value.lngLat.lng, value.lngLat.lat],
            searched: false
        })
        this.props.handleSearch()
        this.props.setLocation(value.lngLat.lat, value.lngLat.lng)

    }
    handleSubmit = (dragLat, dragLong) => {
        this.props.setLocation(dragLat, dragLong)
        this.setState({
            showDragPop: false,
            center: [dragLong, dragLat],
            searched: false
        })
    }

    render() {
        const position = [parseFloat(this.props.lng), parseFloat(this.props.lat)]
        const {
            center,
            zoom,
            searched,
            showDragPop,
            dragLong,
            dragLat
        } = this.state
        return (
            <div className='map'>
                <Container>
                    <Map
                        style={{
                            "version": 8,
                            "name": "Raster Tiles",
                            "sources": {
                                "yourTileLayer": {
                                    "type": "raster",
                                    "tiles": [
                                        "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
                                        "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
                                        "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    ],
                                    "tileSize": 256,
                                }
                            },
                            "layers": [{
                                "id": "yourTileLayer",
                                "type": "raster",
                                "source": "yourTileLayer",
                                "paint": {
                                    "raster-fade-duration": 100
                                }
                            }]
                        }}
                        containerStyle={{
                            height: '550px',
                            width: '100%'
                        }}
                        onStyleLoad={this.onStyleLoad}
                        center={center}
                        zoom={zoom}

                    >
                        {this.state.haveUserLocation && <Layer type="symbol" id="dragPin" layout={layoutLayerPin} images={[imagesDragPin]}>
                            <Feature
                                key='a'
                                draggable
                                coordinates={center}
                                // onClick={() => this.setState({drag: true})}
                                onDragEnd={this.markerClickPin.bind(this)}
                            />
                        </Layer>
                        }


                        {showDragPop && (
                            <Popup coordinates={[dragLong, dragLat]} >
                                <StyledDragPopup>
                                    <div className='cross'>
                                        <div style={{ width: '100%' }}>
                                            <CloseOutlined style={{ color: '#fff', justifyContent: 'flex-end', display: 'flex', padding: '4px 2px' }} onClick={() => this.setState({ showDragPop: false })} />
                                            <div className='loc-div' >
                                                <img src={ReportPin} className='loc-pin' />  &nbsp; <span className='report-loc'>Is this your location?</span>
                                            </div>
                                            <div className='cong-b-d'>
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className='button-tooltip'
                                                    onClick={() => this.handleSubmit(dragLat, dragLong)}>
                                                    Submit
                       </Button>
                                            </div>
                                        </div>
                                    </div>
                                </StyledDragPopup>
                            </Popup>
                        )}


                    </Map>
                </Container>
            </div>
        )
    }
}

export default BusinessMap