import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Card, Radio } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import logo from '../../assets/images/citymaasai1.png';
import { Link } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import Notification from '../Resusable/Notification/index';

import './signin.css';

const SignIn = (props) => {
    const [loading, setLoading] = useState(false)
    const [bus, setBus] = useState('Individual')

    const onFinish = values => {
        
        setLoading(true)
        let credentials = {};
        let serachStr = '';
        if (props.location.search) {
            serachStr = props.location.search;
            let [nothing, assistkey] = serachStr.split('?');
            assistkey = assistkey.replace('assistkey=', '')

            credentials = {
                email: values.email,
                password: values.password,
                auth: 'citymaas',
                assistkey: assistkey,
                bus: bus
            };
        } else {
            credentials = {
                email: values.email,
                password: values.password,
                auth: 'user',
                assistkey: '',
                bus: bus
            };
        }

        props.onAuth(credentials, res => {
            if (res.status === 200) {
                props.history.push('/');
                setLoading(false)
                window.loadPreference && window.loadPreference();
                props.getProfile(res => {
                    if (!res) {
        
                    } else {
        
                    }
                })

              

            } else if (res.status === 777) {
                Notification('info', res.message);
                setLoading(false)


            } else {
                Notification('error', res.message);
                setLoading(false)

            }
        });
    };

    const onChangeBusiness = e => {
        console.log('radio checked', e.target.value);
        setBus(e.target.value)
      }
    return (
        <div className='sign-in-bg'>
            <div className='sign-in-div'>
                <div className='sign-in-img'>
                    <div style={{ width: '500px', height: '270px' }}>
                        <img src={logo} width={500} alt='' />
                    </div>
                    <Card className='sign-in-card'>
                        <Form
                            name="normal_login"
                            className="login-form"
                            initialValues={{ remember: true }}
                            onFinish={onFinish}
                            labelAlign='left'
                            layout='vertical'
                        >
                            <Form.Item
                                label='Email'
                                name="email"
                                rules={[{ required: true, message: 'Please input your email!' }]}
                            >
                                <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Email" />
                            </Form.Item>
                            <Form.Item
                                label='Password'
                                name="password"
                                rules={[{ required: true, message: 'Please input your Password!' }]}
                            >
                                <Input.Password
                                    prefix={<LockOutlined className="site-form-item-icon" />}
                                    type="password"
                                    placeholder="Password"
                                />
                            </Form.Item>

                            <Form.Item
                                label='Please select your status'
                            >
                            <Radio.Group onChange={onChangeBusiness} value={bus}>
                                <Radio value='Individual'>Individual</Radio>
                                <Radio value='Business'>Business</Radio>
                            </Radio.Group>
                            </Form.Item>



                            <Form.Item>
                                <Form.Item name="remember" valuePropName="checked" noStyle>
                                    <Checkbox>Remember me</Checkbox>
                                </Form.Item>

                                <Link className="login-form-forgot" to="/forgotPassword">
                                    Forgot password
                                </Link>
                            </Form.Item>

                            <Form.Item>
                                <div className='sign-in-div  mr-b-10' >
                                    <Button type="primary" htmlType="submit" className="sign-in-button" loading={loading}>
                                        Log in
                                </Button>
                                </div>
                                Don't have an account? <Link to="/sign-up">Sign Up!</Link>

                                <div>
                                    Or <Link to="/">Go Back</Link>
                                </div>
                            </Form.Item>
                        </Form>
                    </Card>
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        loading: state.authReducer.loading,
        error: state.authReducer.error,
        isAuthenticated: state.authReducer.email !== null,
        getProfileGammLoading: state.profileReducer.getProfileGammLoading,
        getProfileGammSuccess: state.profileReducer.getProfileGammSuccess,


    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAuth: (credentials, res) => dispatch(actions.auth(credentials, res)),
        getProfile: (res) => dispatch(actions.getProfile(res)),
        getProfileGamm: (res) => dispatch(actions.getProfileGamm(res)),


    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);