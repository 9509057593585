import React, {Component} from 'react'
import { render } from 'react-dom'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './stackes.css';

class StackedChart extends Component {
          // To avoid unnecessary update keep all options in the state.
    
    
      setHoverData = (e) => {
        // The chart is not updated because `chartOptions` has not changed.
        this.setState({ hoverData: e.target.category })
      }
    
   
    
      render() {
        const chartOptions = {
          chart: {
              type: 'bar'
          },
          title: {
              text: 'People of disabilities interact with logo and link by disabilities type'
          },
          xAxis: {
            type: 'category',
            title: {
                text: null
            },
            min: 0,
            scrollbar: {
                enabled: false
            },
            tickLength: 0
          },
          yAxis: {
            min: 0,
            title: {
                text: 'Count',
                align: 'high'
            }
          },
          legend: {
              reversed: true
          },
          plotOptions: {
              series: {
                  stacking: 'normal'
              }
          },
          series: [{
              name: 'Count',
              data: [
                ['Wheelchair' , this.props.chartData && this.props.chartData.business && this.props.chartData.business.wheelchair ? this.props.chartData.business.wheelchair : 0],
                ['Autism' , this.props.chartData && this.props.chartData.business && this.props.chartData.business.autism ? this.props.chartData.business.autism : 0],
                ['Hearing Impairment' , this.props.chartData && this.props.chartData.business && this.props.chartData.business.hear ? this.props.chartData.business.hear : 0],
                ['Visual Impairment' , this.props.chartData && this.props.chartData.business && this.props.chartData.business.visual ? this.props.chartData.business.visual: 0],
                ['No Disability' , this.props.chartData && this.props.chartData.business && this.props.chartData.business.none ? this.props.chartData.business.none: 0],
                ['Average' , this.props.chartData && this.props.chartData.business && this.props.chartData.business.average ? this.props.chartData.business.average : 0],

            ]
          }]
      }
      
    
        return (
          <div>
            <HighchartsReact
              highcharts={Highcharts}
              options={chartOptions}
            />
 
          </div>
        )
      }
    }
    

export default StackedChart