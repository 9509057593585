
import * as actionTypes from './actionTypes';
import axios from 'axios';
import config from '../../config';
import querystring from 'querystring';


export const gamificationFail = (error) => {
    return {
        type: actionTypes.GAMIFICATION_FAIL,
        error: error
    }
}

export const gamificationSuccess = (data) => {
    return {
        type: actionTypes.GAMIFICATION_SUCCESS,
        data: data
    }
}

export const gamificationStart = (data) => {
    return {
        type: actionTypes.GAMIFICATION_START,
        data: data
    };
}

export const gamification = ( data, callback) => {


    return dispatch => {
        dispatch(gamificationStart(true));
      
        axios.post(`${config.gamification}`,data)
            .then(response => {
                dispatch(gamificationStart(true));
                dispatch(gamificationSuccess(response.data));
                dispatch(gamificationStart(false));

                callback(response.data);
            })
            .catch(err => {
                console.log(err);
                dispatch(gamificationFail(err));
                callback({ status: 2 });
                dispatch(gamificationStart(false));


            })
    }
  
   

    
}




