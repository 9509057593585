import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {

    getTFLLoading: false,
    getTFLSuccess: null,
    getTFLFailure: null,
    getGOOGLELoading: false,
    getGOOGLESuccess: null,
    getGOOGLEFailure: null,

}




const getTFLFailure = (state, action) => {
    return updateObject(state, { getTFLFailure: action.data, getTFLLoading: false });

}
const getTFLSuccess = (state, action) => {
    return updateObject(state, { getTFLSuccess: action.data, getTFLFailure: false });

}

const getTFLLoading = (state, action) => {
    return updateObject(state, { getTFLLoading: action.data, getTFLFailure: null, getTFLSuccess: false });

}
const getGOOGLEFailure = (state, action) => {
    return updateObject(state, { getGOOGLEFailure: action.data, getGOOGLELoading: false });

}
const getGOOGLESuccess = (state, action) => {
    return updateObject(state, { getGOOGLESuccess: action.data, getGOOGLEFailure: false });

}

const getGOOGLELoading = (state, action) => {
    return updateObject(state, { getGOOGLELoading: action.data, getGOOGLEFailure: null, getGOOGLESuccess: false });

}
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.GET_TFL_FAILURE:
            return getTFLFailure(state, action)
        case actionTypes.GET_TFL_SUCCESS:
            return getTFLSuccess(state, action)
        case actionTypes.GET_TFL_LOADING:
            return getTFLLoading(state, action)

        case actionTypes.GET_GOOGLE_FAILURE:
            return getGOOGLEFailure(state, action)
        case actionTypes.GET_GOOGLE_SUCCESS:
            return getGOOGLESuccess(state, action)
        case actionTypes.GET_GOOGLE_LOADING:
            return getGOOGLELoading(state, action)

        default:
            return state;
    }
}

export default reducer;