import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Card,
  Avatar,
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Spin,
  InputNumber,
  Rate,
  Upload,
  Modal,
  Result,
} from "antd";
import {
  UserOutlined,
  EyeInvisibleOutlined,
  PictureOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import wheelchair from "../../assets/images/wheelchair.png";
import t1 from "./icons/train/t1.png";
import t2 from "./icons/train/t2.png";
import t3 from "./icons/train/t3.png";
import t4 from "./icons/train/t4.png";
import t5 from "./icons/train/t5.png";
import t6 from "./icons/train/t6.png";
import t7 from "./icons/train/t7.png";
import t8 from "./icons/train/t8.png";
import t9 from "./icons/train/t9.png";
import t10 from "./icons/train/t10.png";
import t11 from "./icons/train/t11.png";
import t12 from "./icons/train/t12.png";
import t13 from "./icons/train/t13.png";
import t14 from "./icons/train/t14.png";
import b5 from "./icons/bus/b-support.png";

import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import Notification from "../Resusable/Notification/index";
import { Link, withRouter } from "react-router-dom";
import { Select, Dropdown, Tooltip } from "antd";

import { GApageView } from "../../utils/index";

import "./gamification.css";
var FormData = require("form-data");
const { Option } = Select;
const desc = ["terrible", "bad", "normal", "good", "wonderful"];
const { TextArea } = Input;
const Train = (props) => {
  const [phoneInput, setPhoneInput] = useState("");
  const [emerConInput, setEmerCon] = useState("");
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [age, setAge] = useState("");
  const [dis, setDis] = useState([]);
  const [password, setpassword] = useState("");
  const [name, setname] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [previewTitle, setpreviewTitle] = useState("");
  const [fileList, setfileList] = useState([]);
  const [textInput, setTextInput] = useState("");
  //search
  const [value, setValue] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState(false);
  const [rating, setRating] = useState(3);
  const [poiName, setPOIname] = useState("");
  const [Lng, setLng] = useState("");
  const [Lat, setLat] = useState("");
  const [Loc_id, setLocId] = useState("");
  const [accessibility_category, setAccessibility_category] = useState("");
  const [accessibility_value, setAccessibility_value] = useState("");
  const [submit, setSubmit] = useState(false);

  let checked_services = [];

  useEffect(() => {
    GApageView(props.location.pathname);

    if (props.location.state) {
      setLat(props.location.state.lat);
      setLng(props.location.state.lng);
      if (props.location.state.cat) {
        setAccessibility_category(props.location.state.cat);
      }
      if (props.location.state.acc_val) {
        setAccessibility_value(props.location.state.acc_val);
      }
      if (props.location.state.loc_id) {
        setLocId(props.location.state.loc_id);
      }
      if (props.location.state.name) {
        setPOIname(props.location.state.name);
      }
    }
  }, []);

  const formItemLayout = {
    // labelCol: { span: 24 , offset: 4 },
    wrapperCol: { span: 20, offset: 0 },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 10,
      },
    },
  };

  const [form] = Form.useForm();

  //search
  const fetchUser = (value) => {
    setValue(value);
    setData([]);
    setFetching(true);

    props.getLocation(value, (res) => {
      if (!res) {
        setData([]);
        setFetching(false);
        setSearch(false);
      } else {
        const results = [];
        res.map((val) => {
          results.push({
            loc: val.center,
            title: val.place_name,
          });
        });
        setData(results);
        setFetching(false);
        setSearch(false);
      }
    });
  };
  const changeRating = (value) => {
    setRating(value);
  };

  const handleChange = (value) => {
    setSearch(true);

    setData([]);
    setFetching(false);
    setPOIname(value.label);
    let lat_lng = value && value.key.split(",");
    setLat(lat_lng[1]);
    setLng(lat_lng[0]);
  };

  const handleFeedback = (values) => {
    var data = new FormData();
    data.append("update_mode", "location_accessibility_train_station");
    if (
      props.isAuthenticated &&
      sessionStorage.getItem("email") !== undefined
    ) {
      data.append("email", sessionStorage.getItem("email"));
    } else {
      data.append("email", "guest_user");
    }
    data.append("accessibility_category", accessibility_category);
    data.append("accessibility_value", accessibility_value);
    data.append("latitude", Lat);
    data.append("longitude", Lng);
    data.append("location_id", Loc_id);
    data.append("location_name", poiName);
    data.append("feedback_time_unix", Math.floor(Date.now() / 1000));
    data.append("source", "source_mapbox");
    data.append("feedback_text", textInput);
    data.append("feedback_rating", rating);
    data.append("feedback_categories", JSON.stringify(checked_services));
    fileList.map((file) => {
      data.append("file", file.originFileObj);
    });

    console.log(data);
    props.gamification(data, (res) => {
      if (!res) {
        Notification("error", "Something went wrong");
      } else {
        if (!props.isAuthenticated) {
          setSubmit(true);
        } else {
          Notification("success", "Congrats You earned 100 points !!");
        }
      }
    });

   
  };

  const onChange = (name, event) => {
    if (event.target.checked) {
      checked_services.push(name);
    } else if (!event.target.checked && checked_services.indexOf(name) > -1) {
      checked_services = checked_services.filter((e) => e !== name);
    } else {
    }
  };

  const handleCancel = () => {
    setpreviewVisible(false);
  };

  const handlePreview = async (file) => {
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChangeImage = ({ fileList }) => {
    setfileList(fileList);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  const onTextChange = (name, event) => {
    if (name === "textInput") {
      setTextInput(event.target.value);
    }
  };

  return (
    <>
      <PageHeader
        className="Pageheader-prf"
        title={`Feedback Train Stations`}
        ghost
      />

      <Card className="card-style-prof">
        {loading ? (
          <div className="spining-pr">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <span>
              <strong>Search Train Stations</strong>
            </span>
            <br />
            <Select
              showSearch
              labelInValue
              value={value}
              placeholder="Select exact poi location"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              showArrow={false}
              filterOption={false}
              onSearch={fetchUser}
              onSelect={handleChange}
              //onChange={onChangeA}
              //onClick={() => setAutoSearchA(false)}
              className="search"
            >
              {data.map((d) => (
                <Option key={d.loc}>{d.title}</Option>
              ))}
            </Select>
            <Form
              form={form}
              name="validate_other"
              labelAlign="left"
              layout="vertical"
            >
              <div className="area-pic">
                <p>
                  {" "}
                  <strong>{poiName}</strong>
                </p>
                <div className="div_inline">
                  <Avatar
                    size={100}
                    shape="square"
                    icon={<PictureOutlined />}
                  />
                </div>

                <div className="div_inline">
                  <p className="rating">
                    {" "}
                    <strong>Accessibility Rating</strong>
                  </p>
                  <Rate
                    className="rating"
                    tooltips={desc}
                    onChange={changeRating}
                    value={rating}
                  />
                  {value ? (
                    <span className="ant-rate-text">{desc[value - 1]}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <Form.Item name="name_of_user" label="Provide More Text Feedback">
                <TextArea
                  rows={4}
                  onChange={(event) => onTextChange("textInput", event)}
                />
              </Form.Item>
              <span>
                <strong>
                  Upload Photos
                  <br />
                </strong>
              </span>
              <br />
              <div className="clearfix">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChangeImage}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </div>
              <span>
                <strong>
                  Report an Issue
                  <br />
                </strong>
                <br />
              </span>
              <div>
                <input
                  type="checkbox"
                  id="toggle"
                  className="visually-hidden"
                  onChange={(event) => onChange("service_delayed", event)}
                />
                <label for="toggle" className="checkbox-label">
                  <img src={t1} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle2"
                  className="visually-hidden"
                  onChange={(event) => onChange("lift_out_of_service", event)}
                />
                <label for="toggle2" className="checkbox-label">
                  {" "}
                  <img src={t2} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle3"
                  className="visually-hidden"
                  onChange={(event) =>
                    onChange("escalator_out_of_service", event)
                  }
                />
                <label for="toggle3" className="checkbox-label">
                  {" "}
                  <img src={t3} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle8"
                  className="visually-hidden"
                  onChange={(event) => onChange("no_assistance", event)}
                />
                <label for="toggle8" className="checkbox-label">
                  {" "}
                  <img src={t8} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle4"
                  className="visually-hidden"
                  onChange={(event) =>
                    onChange("accessible_entrance_closed", event)
                  }
                />
                <label for="toggle4" className="checkbox-label">
                  {" "}
                  <img src={t4} alt="" className="img-bus" />
                </label>
                <br /> <br /> <br />
                <input
                  type="checkbox"
                  id="toggle9"
                  className="visually-hidden"
                  onChange={(event) => onChange("no_ramps", event)}
                />
                <label for="toggle9" className="checkbox-label">
                  {" "}
                  <img src={t9} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle10"
                  className="visually-hidden"
                  onChange={(event) => onChange("crowdedness", event)}
                />
                <label for="toggle10" className="checkbox-label">
                  {" "}
                  <img src={t10} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle11"
                  className="visually-hidden"
                  onChange={(event) => onChange("no_text_display", event)}
                />
                <label for="toggle11" className="checkbox-label">
                  {" "}
                  <img src={t11} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle12"
                  className="visually-hidden"
                  onChange={(event) =>
                    onChange("accessible_toilet_out_of_display", event)
                  }
                />
                <label for="toggle12" className="checkbox-label">
                  {" "}
                  <img src={t12} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle13"
                  className="visually-hidden"
                  onChange={(event) => onChange("unclear_signage", event)}
                />
                <label for="toggle13" className="checkbox-label">
                  {" "}
                  <img src={t13} alt="" className="img-bus" />
                </label>
                <br /> <br /> <br />
                <input
                  type="checkbox"
                  id="toggle14"
                  className="visually-hidden"
                  onChange={(event) =>
                    onChange("help_points_not_working", event)
                  }
                />
                <label for="toggle14" className="checkbox-label">
                  {" "}
                  <img src={t14} alt="" className="img-bus" />
                </label>
                <input
                  type="checkbox"
                  id="toggle5"
                  className="visually-hidden"
                  onChange={(event) => onChange("bad_customer_service", event)}
                />
                <label for="toggle5" className="checkbox-label">
                  {" "}
                  <img src={b5} alt="" className="img-bus" />
                </label>
              </div>
              <br /> <br /> <br />
              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-primary width-200"
                  loading={updateLoading}
                  onClick={() => handleFeedback()}
                >
                  Submit feedback
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {!props.isAuthenticated && (
          <Modal
            visible={submit}
            footer={null}
            width="auto"
            centered
            closable={false}
          >
            <Result
              status="success"
              title="Sign up and earn reward points"
              // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
              extra={[
                <Button type="primary" key="console" href="/sign-up">
                  Sign Up!
                </Button>,
                <Button onClick={() => setSubmit(false)}>Cancel</Button>,
              ]}
            />
          </Modal>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getProfileLoading: state.profileReducer.getProfileLoading,
    getProfileSuccess: state.profileReducer.getProfileSuccess,
    profileLoading: state.profileReducer.profileLoading,
    getProfileSuccess: state.profileReducer.getProfileSuccess,
    isAuthenticated: state.authReducer.email !== null,
    gamificationLoading: state.gamificationReducer.gamificationLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (res) => dispatch(actions.getProfile(res)),
    profile: (data, res) => dispatch(actions.profile(data, res)),
    getLocation: (data, res) => dispatch(actions.getLocation(data, res)),
    gamification: (data, res) => dispatch(actions.gamification(data, res)),
  };
};
//export default connect(mapStateToProps, mapDispatchToProps)(Gamification)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Train));
