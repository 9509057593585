import * as actionTypes from './actionTypes';
import axios from 'axios';
import config from '../../config';
import querystring from 'querystring';



//get poi


export const getTFLFailure = (error) => {
    return {
        type: actionTypes.GET_TFL_FAILURE,
        error: error
    }
}

export const getTFLSuccess = (data) => {
    return {
        type: actionTypes.GET_TFL_SUCCESS,
        data: data
    }
}

export const getTFLLoading = (data) => {
    return {
        type: actionTypes.GET_TFL_LOADING,
        data: data
    };
}

export const getTFL = ( data, callback) => {
    return (dispatch) => {
        var url ;
        if(data.journeyPreference!='' && data.accessibilityPreference!=''){
            url = `${config.tfl}/${data.latA},${data.lngA}/to/${data.latB},${data.lngB}?journeyPreference=${data.journeyPreference}&accessibilityPreference=${data.accessibilityPreference}&nationalSearch=true`
        }
        else if(data.journeyPreference!='' && data.accessibilityPreference==''){
            url = `${config.tfl}/${data.latA},${data.lngA}/to/${data.latB},${data.lngB}?journeyPreference=${data.journeyPreference}&nationalSearch=true`
        }
        else if(data.journeyPreference=='' && data.accessibilityPreference!=''){
            url = `${config.tfl}/${data.latA},${data.lngA}/to/${data.latB},${data.lngB}?accessibilityPreference=${data.accessibilityPreference}&nationalSearch=true`
        }
        else{
            url = `${config.tfl}/${data.latA},${data.lngA}/to/${data.latB},${data.lngB}?nationalSearch=true`
        }
        

        dispatch(getTFLLoading(true));
        return axios.get(url,
        )
            .then((response) => {
                
                dispatch(getTFLSuccess(response.data));
                dispatch(getTFLLoading(true));
                callback(response.data);
                dispatch(getTFLLoading(false));
            })
            .catch((err) => {
        

                    dispatch(getTFLLoading(false));
                    dispatch(getTFLFailure(err));
                    callback(false);
            })
    }
}






export const getGOOGLEFailure = (error) => {
    return {
        type: actionTypes.GET_GOOGLE_FAILURE,
        error: error
    }
}

export const getGOOGLESuccess = (data) => {
    return {
        type: actionTypes.GET_GOOGLE_SUCCESS,
        data: data
    }
}

export const getGOOGLELoading = (data) => {
    return {
        type: actionTypes.GET_GOOGLE_LOADING,
        data: data
    };
}

export const getGOOGLE = ( data, callback) => {
  
   

    return (dispatch) => {
        var request ;
        if(data.routingPreference!=''){
            request = {
                origin: `${data.latA},${data.lngA}`,
                provideRouteAlternatives :true,
                destination: `${data.latB},${data.lngB}`,
                travelMode: 'TRANSIT',
                transitOptions: {
                  departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
                  modes:['BUS','RAIL','SUBWAY','TRAIN','TRAM'],
                  routingPreference: data.routingPreference
                }
              }
        }
        else{
            request = {
                origin: `${data.latA},${data.lngA}`,
                provideRouteAlternatives :true,
                destination: `${data.latB},${data.lngB}`,
                travelMode: 'TRANSIT',
                transitOptions: {
                  departureTime: new Date(Date.now()),  // for the time N milliseconds from now.
                  modes:['BUS','RAIL','SUBWAY','TRAIN','TRAM']
                }
              }
        }
        
       
            var directionsService = new window.google.maps.DirectionsService();
            directionsService.route(request, function(result, status) {
                     
                dispatch(getGOOGLESuccess(result));
                dispatch(getGOOGLELoading(true));
                callback(result);
                dispatch(getGOOGLELoading(false));
               
              });
       
    
    }
}




