import * as actionTypes from './actionTypes';
import axios from 'axios';
import config from '../../config';
import querystring from 'querystring';
export const signUpStart = (data) => {

    return {
        type: actionTypes.SIGN_UP_START,
        data: data
    };
}

export const signUpSuccess = (data) => {
    return {
        type: actionTypes.SIGN_UP_SUCCESS,
        data: data
    }
}

export const signUpFail = (error) => {
    return {
        type: actionTypes.SIGN_UP_FAIL,
        error: error
    }
}

export const signUp = (obj, callback) => {
    return dispatch => {
        dispatch(signUpStart(true));
        const url = ''
        if (obj.mode === 'individual') {
            let authData =  {
                data: {
                    email: obj.email,
                    password: obj.password,
                    source: 'citymaas'
                }
            }

            axios.post(`${config.userURL}/signup`, authData)
                .then(response => {
                    dispatch(signUpStart(true));
                    dispatch(signUpSuccess(response.data.message));
                    dispatch(signUpStart(false));
                    callback(response);
                })
                .catch(err => {
                    console.log(err);
                    dispatch(signUpFail(err));
                    callback({ message: err.response.data.message });
                    dispatch(signUpStart(false));

                })
        } else {
            var bodyFormData = new FormData();
            bodyFormData.set('business_email', obj.email);
            bodyFormData.append('business_password', obj.password);
            bodyFormData.append('business_name', obj.business_name);
            bodyFormData.append('business_contact_phone', obj.business_contact_phone);
            {obj.business_logo && obj.business_logo[0] && bodyFormData.append('business_logo', obj.business_logo[0]);}
            {obj.business_leaflet && obj.business_leaflet[0] && bodyFormData.append('business_leaflet', obj.business_leaflet[0]);}


            axios.post(`${config.adminApi}/business/signup`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    dispatch(signUpStart(true));
                    dispatch(signUpSuccess(response.data.message));
                    dispatch(signUpStart(false));
                    callback(response);
                })
                .catch(err => {
                    console.log(err);
                    dispatch(signUpFail(err));
                    callback({ message: err.response.data.message });
                    dispatch(signUpStart(false));


                })
        }


    }
}


export const authStart = (data) => {

    return {
        type: actionTypes.AUTH_START,
        data: data
    };
}


export const authSuccess = (email) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        email: email,

    }
}

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    }
}

export const logout = () => {
    sessionStorage.removeItem('email');
    sessionStorage.removeItem('user_disability');

    return {
        type: actionTypes.AUTH_LOGOUT,

    }

}

export const logoutUser = () => {
    return dispatch => {
        dispatch(logout());
        axios.post(`${config.adminApi}/logout`)
            .then(response => {
                console.log(response);
                sessionStorage.clear();

            }).catch(err => {
                console.log(err);
            })
    }

}


export const auth = (obj, callback) => {
    return dispatch => {
        dispatch(authStart(true));
        const authData = {
            data: {
                email: obj.email,
                password: obj.password,
                'auth': obj.auth,
                assistkey: obj.assistkey
            }
        }
        if (obj.bus === 'Individual') {
            axios.post(`${config.userURL}/login`, authData)
                .then(response => {
                    if (response.data.status_code === 200) {
                        sessionStorage.setItem('email', response.data.data.email);
                        sessionStorage.setItem('role', response.data.data.role);

                        dispatch(authSuccess(response.data.data.email));
                        dispatch(authStart(false));
                        callback({ status: response.data.status_code });
                    } else if (response.data.status_code === 777) {
                        callback({ status: response.data.status_code, message: response.data.message });

                    }
                }
                )
                .catch(err => {
                    console.log(err);
                    dispatch(authStart(false));
                    dispatch(authFail(err));
                    callback({ status: 2, message: err.response.data.message });

                })
        } else {
            var bodyFormData = new FormData();
            bodyFormData.set('business_email', obj.email);
            bodyFormData.append('business_password', obj.password);
            bodyFormData.append('auth', obj.auth);
            bodyFormData.append('assistkey', obj.assistkey);

            axios.post(`${config.adminApi}/business/login`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {
                    if (response.data.status_code === 200) {
                        sessionStorage.setItem('email', response.data.data.business_email);
                        sessionStorage.setItem('role', response.data.data.role);

                        dispatch(authSuccess(response.data.data.business_email));
                        dispatch(authStart(false));
                        callback({ status: response.data.status_code });
                    } else if (response.data.status_code === 777) {
                        callback({ status: response.data.status_code, message: response.data.message });

                    }
                }
                )
                .catch(err => {
                    console.log(err);
                    dispatch(authStart(false));
                    dispatch(authFail(err));
                    callback({ status: 2, message: err.response.data.message });

                })
        }




    }
}



export const authValpi = (obj, callback) => {
    return dispatch => {
        dispatch(authStart(true));
        const authData = {
            data: {
                email: obj.email,
                password: obj.password,
                'auth': obj.auth,
                assistkey: obj.assistkey
            }
        }
            axios.post(`${config.valpi}/citymaas/login`, authData)
                .then(response => {
                    if (response.data.status_code === 200) {

                        // dispatch(authSuccess(response.data.data.email));÷\
                        dispatch(authStart(false));
                        callback({ status: response.data.status_code });
                    } else if (response.data.status_code === 777) {
                        callback({ status: response.data.status_code, message: response.data.message });

                    }
                }
                )
                .catch(err => {
                    console.log(err);
                    dispatch(authStart(false));
                    dispatch(authFail(err));
                    callback({ status: 2, message: err.response.data.message });

                })

    }
}


export const forgotPasswordStart = (data) => {

    return {
        type: actionTypes.FORGOT_PASSWORD_START,
        data: data
    };
}

export const forgotPasswordSuccess = (data) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_SUCCESS,
        data: data
    }
}

export const forgotPasswordFail = (error) => {
    return {
        type: actionTypes.FORGOT_PASSWORD_FAIL,
        error: error
    }
}

export const forgotPassword = (obj, callback) => {
    return dispatch => {
        dispatch(forgotPasswordStart(true));
        const authData = {
            "data": {
                "email": obj.email,
            }
        }

        if (obj.bus === 'Individual') {
            axios.post(`${config.adminApi}/resetpassword`, authData)
                .then(response => {

                    dispatch(forgotPasswordStart(true));
                    dispatch(forgotPasswordSuccess(response.data.message));
                    dispatch(forgotPasswordStart(false));
                    callback({ status: response.status, data: response.data });



                })
                .catch(err => {
                    console.log(err);
                    dispatch(forgotPasswordFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(forgotPasswordStart(false));


                })
        } else {
            var bodyFormData = new FormData();
            bodyFormData.set('business_email', obj.email);

            axios.post(`${config.adminApi}/business/resetpassword`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {

                    dispatch(forgotPasswordStart(true));
                    dispatch(forgotPasswordSuccess(response.data.message));
                    dispatch(forgotPasswordStart(false));
                    callback({ status: response.status, data: response.data });



                })
                .catch(err => {
                    console.log(err);
                    dispatch(forgotPasswordFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(forgotPasswordStart(false));


                })
        }


    }
}


export const resetPasswordStart = (data) => {

    return {
        type: actionTypes.RESET_PASSWORD_START,
        data: data
    };
}

export const resetPasswordSuccess = (data) => {
    return {
        type: actionTypes.RESET_PASSWORD_SUCCESS,
        data: data
    }
}

export const resetPasswordFail = (error) => {
    return {
        type: actionTypes.RESET_PASSWORD_FAIL,
        error: error
    }
}

export const resetPassword = (creds, callback) => {
    return dispatch => {
        dispatch(resetPasswordStart(true));
        if (creds.data.business) {
            var bodyFormData = new FormData();
            bodyFormData.set('assistid', creds.data.assistid);
            bodyFormData.append('assistvalpassword', creds.data.assistvalpassword);

            axios.post(`${config.adminApi}/business/resetpasswordemailauth`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
                .then(response => {


                    dispatch(resetPasswordStart(true));
                    dispatch(resetPasswordSuccess(response.data.message));
                    dispatch(resetPasswordStart(false));
                    callback({ status: response.status, data: response.data });


                })
                .catch(err => {
                    console.log(err);
                    dispatch(resetPasswordFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(resetPasswordStart(false));


                })
        }

        else {
            axios.post(`${config.adminApi}/resetpasswordemailauth`, creds)
                .then(response => {

                    dispatch(resetPasswordStart(true));
                    dispatch(resetPasswordSuccess(response.data.message));
                    dispatch(resetPasswordStart(false));
                    callback({ status: response.status, data: response.data });


                })
                .catch(err => {
                    console.log(err);
                    dispatch(resetPasswordFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(resetPasswordStart(false));


                })
        }
    }

}


//reset valpi

export const resetValpiPassword = (creds, callback) => {
    return dispatch => {
        dispatch(resetPasswordStart(true));

        if(creds.data.role === 'driver'){
            axios.post(`${config.valpi}/driverresetpasswordemailauth`, creds)
                .then(response => {

                    dispatch(resetPasswordStart(true));
                    dispatch(resetPasswordSuccess(response.data.message));
                    dispatch(resetPasswordStart(false));
                    callback({ status: response.status, data: response.data });


                })
                .catch(err => {
                    console.log(err);
                    dispatch(resetPasswordFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(resetPasswordStart(false));


                })
        }
        else{  axios.post(`${config.valpi}/citymaas/resetpasswordemailauth`, creds)
                .then(response => {

                    dispatch(resetPasswordStart(true));
                    dispatch(resetPasswordSuccess(response.data.message));
                    dispatch(resetPasswordStart(false));
                    callback({ status: response.status, data: response.data });


                })
                .catch(err => {
                    console.log(err);
                    dispatch(resetPasswordFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(resetPasswordStart(false));


                })
            }
    }

}

//

export const resetProfileStart = (data) => {

    return {
        type: actionTypes.RESET_PROFILE_START,
        data: data
    };
}

export const resetProfileSuccess = (data) => {
    return {
        type: actionTypes.RESET_PROFILE_SUCCESS,
        data: data
    }
}

export const resetProfileFail = (error) => {
    return {
        type: actionTypes.RESET_PROFILE_FAIL,
        error: error
    }
}

export const resetProfile = (obj, callback) => {
    return dispatch => {
        dispatch(resetProfileStart(true));
        if(obj.bus === 'Individual'){
            const authData = {
                data: {
                    email: obj.email,
                    password: obj.password,
    
    
                }
            }
            axios.post(`${config.userURL}/profileupdate`, authData)
                .then(response => {
    
                    dispatch(resetProfileStart(true));
                    dispatch(resetProfileSuccess(response.data));
                    dispatch(resetProfileStart(false));
    
                    callback({ status: response.status, data: response.data });
                })
                .catch(err => {
    
                    console.log(err);
                    dispatch(resetProfileFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(resetProfileStart(false));
    
    
                })
        } else {
            var bodyFormData = new FormData();
            bodyFormData.set('business_email', obj.email);
            bodyFormData.append('business_password', obj.password);

            axios.post(`${config.adminApi}/business/profileupdate`, bodyFormData, { headers: { 'Content-Type': 'multipart/form-data' } })
            .then(response => {

                dispatch(resetProfileStart(true));
                dispatch(resetProfileSuccess(response.data));
                dispatch(resetProfileStart(false));

                callback({ status: response.status, data: response.data });
            })
            .catch(err => {

                console.log(err);
                dispatch(resetProfileFail(err));
                callback({ status: 2, message: err.response.data.message });
                dispatch(resetProfileStart(false));


            })
        }
    }
}

// resetValpiProfile


export const resetValpiProfile = (obj, callback) => {
    return dispatch => {
        dispatch(resetProfileStart(true));
            if(obj.role === 'driver'){
                const authData ={
                    data: {
                        driver_email: obj.email,
                        driver_password: obj.password,
                    }
                }
                axios.post(`${config.valpi}/driverprofileupdate`, authData)
                .then(response => {
    
                    dispatch(resetProfileStart(true));
                    dispatch(resetProfileSuccess(response.data));
                    dispatch(resetProfileStart(false));
    
                    callback({ status: response.status, data: response.data });
                })
                .catch(err => {
    
                    console.log(err);
                    dispatch(resetProfileFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(resetProfileStart(false));
    
    
                })
    
            } else {
                const authData ={
                    data: {
                        email: obj.email,
                        password: obj.password,
                    }
                }
                axios.post(`${config.valpi}/citymaas/profileupdate`, authData)
                .then(response => {
    
                    dispatch(resetProfileStart(true));
                    dispatch(resetProfileSuccess(response.data));
                    dispatch(resetProfileStart(false));
    
                    callback({ status: response.status, data: response.data });
                })
                .catch(err => {
    
                    console.log(err);
                    dispatch(resetProfileFail(err));
                    callback({ status: 2, message: err.response.data.message });
                    dispatch(resetProfileStart(false));
    
    
                })
            }
            
      
    }
}