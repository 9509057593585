module.exports = {
    adminApi: 'https://citymaasassistweb.uksouth.cloudapp.azure.com',
    mapboxToken: 'pk.eyJ1IjoiY2l0eXRlc3QiLCJhIjoiY2s3bXc4ejFiMDZoaTNrcGZzMGFvMGV4MCJ9.d5gaDF0WsWCcG4RiuxuW0g',
    baseApiUrl: 'https://api.mapbox.com',
    userURL:'https://citymaasassistweb.uksouth.cloudapp.azure.com/citymaas',
    token: 'pk.eyJ1IjoiYW5keWNpdHltYWFzIiwiYSI6ImNrOWlqbm9rMDA2dmEza29tN2lsODdjZ3AifQ._sUJxnaFyOlLl1t3l80TkA',
    tfl: 'https://api.tfl.gov.uk/journey/journeyresults', 
    google: 'https://maps.googleapis.com/maps/api/directions/json',
    gamification : 'https://citymaasassistweb.uksouth.cloudapp.azure.com/gamificationupdate',
    valpi:'https://valpivmtest.uksouth.cloudapp.azure.com',
    what3wordstoWords: 'https://api.what3words.com/v3/convert-to-3wa?key=IBOH5259',
    what3wordstoCords: 'https://api.what3words.com/v3/convert-to-coordinates?key=IBOH5259',

  };

 
