import React, { Component } from "react";
import ReactMapboxGl, {
  Marker,
  ScaleControl,
  ZoomControl,
  Popup,
  Layer,
  Feature,
  Cluster,
} from "react-mapbox-gl";

import styled from "styled-components";
import { token } from "../../config";
import locateIcon from "../../assets/images/locate.svg";
import busURL from "../../assets/images/busPin.svg";
import trainURl from "../../assets/images/Train.svg";
import GreenpoiURl from "../../assets/images/Ellipse4.png";
import RedpoiURl from "../../assets/images/Inaccessible.svg";
import OrangepoiURl from "../../assets/images/PartiallyAccessible.svg";
import PurplepoiURL from "../../assets/images/PredictionShape.svg";
import BusinessLayerRL from "../../assets/images/busCom.png";
import Cookies from "js-cookie";

import { CloseOutlined } from "@ant-design/icons";
import {
  Card,
  Form,
  Button,
  Checkbox,
  Row,
  Col,
  Radio,
  Modal,
  Result,
} from "antd";
import accessRate from "../../assets/images/accessRate.png";
import planner from "../../assets/images/planner.png";
import Aida from "../../assets/images/Aida.png";
import wheelchairPic from "../../assets/images/wheelchair.png";
import blind from "../../assets/images/blind.png";
import block from "../../assets/images/block.png";
import brain from "../../assets/images/brain.png";
import hear from "../../assets/images/hear.png";
import CardInfo from "../AccessibilityInfo/CardInfo";
import DisabilityInfo from "../AccessibilityInfo/DisabilityInfo";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import DragPin from "../../assets/images/pin.png";
import { withRouter, Link } from "react-router-dom";
import Coin from "../../assets/images/coin.svg";
import ReportPin from "../../assets/images/reportPin.svg";
import "./ReactMapbox.css";

const formItemLayout = {
  labelCol: { span: 16, offset: 6 },
  wrapperCol: { span: 18, offset: 6 },
};

const Map = ReactMapboxGl({
  accessToken: token,
  renderWorldCopies: true,
});
const Container = styled.div`
  position: relative;
  height: 100%;
  flex: 1;
`;
const StyledPopup =
  window.screen.width > 600
    ? styled.div`
        background: #121859;
        color: #fff;
        padding: 12px 20px;
        border-radius: 16px;
        max-width: 250px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        word-wrap: break-word;
      `
    : styled.div`
        background: #121859;
        color: #fff;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 10px;
        max-width: 150px;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        word-wrap: break-word;
      `;
// Define layout to use in Layer component
const StyledDragPopup =
  window.screen.width > 600
    ? styled.div`
        background: #121859;
        color: #3f618c;
        padding: 12px 20px;
        border-radius: 16px;
        max-width: 300px;
        max-height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
        word-wrap: break-word;
        min-width: 250px;
      `
    : styled.div`
        background: #121859;
        color: #3f618c;
        padding: 4px 8px;
        border-radius: 6px;
        font-size: 10px;
        max-width: auto;
        max-height: 250px;
        overflow-y: auto;
        overflow-x: hidden;
        word-wrap: break-word;
        min-width: 200px;
      `;
const layoutLayer = { "icon-image": "londonPoi" };
const image = new Image();
image.src = GreenpoiURl;
image.alt = "alt";
const imagePOI = ["londonPoi", image];

const layoutLayerOrange = { "icon-image": "londonPoiO" };
const imageO = new Image();
imageO.src = OrangepoiURl;
imageO.alt = "alt";
const OrangeimagePOI = ["londonPoiO", imageO];

const layoutLayerRed = { "icon-image": "londonPoiR" };
const imageR = new Image();
imageR.src = RedpoiURl;
imageR.alt = "alt";
const RedimagePOI = ["londonPoiR", imageR];

const layoutLayerPurple = { "icon-image": "londonPoiP" };
const imageP = new Image();
imageP.src = PurplepoiURL;
imageP.alt = "alt";
const PurpleimagePOI = ["londonPoiP", imageP];

const layoutLayerBusinesss = { "icon-image": "londonPoiB" };
const imageB = new Image();
imageB.src = BusinessLayerRL;
imageB.alt = "altBusiness";
imageB.width = 50;
imageB.height = 50;
const BusinessLayerPOI = ["londonPoiB", imageB];

const layoutLayerTrain = { "icon-image": "londonTrain" };
const imageTrain = new Image();
imageTrain.src = trainURl;
imageTrain.alt = "trainalt";
imageTrain.width = 40;
imageTrain.height = 40;
const imagesTrain = ["londonTrain", imageTrain];

const layoutBusLayer = { "icon-image": "bustravel" };
const busImg = new Image();
busImg.src = busURL;
busImg.alt = "busalt";
busImg.width = 50;
busImg.height = 50;
const imagesBus = ["bustravel", busImg];

const layoutLayerPin = { "icon-image": "londonDragPin" };
const imagePin = new Image();
imagePin.src = DragPin;
imagePin.alt = "dragAlt";
imagePin.width = 70;
imagePin.height = 70;
const imagesDragPin = ["londonDragPin", imagePin];

class ReactMapbox extends Component {
  state = {
    center: [-0.09, 53.505],
    haveUserLocation: false,
    zoom: [12],
    welcome: true,
    navigate: false,
    userPopUp: false,
    userPopUpLocation: [0, 0],
    poi: [],
    orangePoi: [],
    greenPoi: [],
    showPoi: false,
    train: [],
    bus: [],
    dlr: [],
    navigate: false,
    disability: [],
    pPoi: [],
    dragLat: "",
    dragLong: "",
    showDragPop: false,
    issues: ["roadWork"],
    congrats: false,
    validatePoi: "",
    validatePopup: false,
    congrats_validate: false,
    dragPosition: [],
    firstDrag: false,
    loader: false,
    poiBus: [],

    wheelchairG: [],
    wheelchairO: [],
    wheelchairR: [],
    wheelchair: false,
    autism: false,
    autismG: [],
    autismO: [],
    autismR: [],

    submit: false,
    purplePoi: [],
    what3words: "",
  };
  componentDidMount() {
    if (Cookies.get("Welcome") !== undefined) {
      this.props.noWelcome();
      this.setState({ welcome: false, navigate: true });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.search) {
      this.setState({
        center: [parseFloat(this.props.lng), parseFloat(this.props.lat)],
        haveUserLocation: true,
        zoom: [16],
      });

      if (!this.state.firstDrag) {
        this.setState({
          dragPosition: [
            parseFloat(this.props.lng),
            parseFloat(this.props.lat),
          ],
        });
      }
    }
    this.props.handleSearch();
    this.props.handleSearchCard();
  }
  onStyleLoad = (map) => {
    const { onStyleLoad } = this.props;
    return onStyleLoad && onStyleLoad(map);
  };

  getCirclePaint = () => ({
    "circle-radius": this.state.circleRadius,
    "circle-color": "red",
    "circle-opacity": 0.8,
  });

  onDrag = () => {
    if (this.state.station) {
      this.setState({ station: undefined });
    }
  };

  setLoading = () => {
    this.setState({ loader: true });

    setTimeout(() => {
      this.setState({ loader: false });
    }, 1000);
  };

  onToggleHover(cursor, map) {
    map.getCanvas().style.cursor = cursor;
  }

  markerClickBusiness = (poi, val) => {
    if (this.props.isAuthenticated) {
      if (sessionStorage.getItem("role") === "user") {
        var bodyFormData = new FormData();
        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("business_id", poi.business_id);
        bodyFormData.append("business_name", poi.business_name);
        bodyFormData.append("business_email", poi.business_email);
        bodyFormData.append(
          "location_latitude",
          poi.business_location_latitude
        );
        bodyFormData.append(
          "location_longitude",
          poi.business_location_longitude
        );
        bodyFormData.append("location_id", poi.business_id);
        bodyFormData.append("location_category", "hotel");
        bodyFormData.append("location_name", poi.business_name);
        bodyFormData.append(
          "user_disability",
          JSON.stringify(
            (
              this.props.getProfileSuccess.user_disability &&
              this.props.getProfileSuccess.user_disability
            ).flat()
          )
        );
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        this.props.setRecord(bodyFormData);
      } else {
        var bodyFormData = new FormData();
        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("business_email", poi.business_email);
        bodyFormData.append("business_name", poi.business_name);
        bodyFormData.append("business_id", poi.business_id);
        bodyFormData.append(
          "location_latitude",
          poi.business_location_latitude
        );
        bodyFormData.append("location_name", poi.business_name);
        bodyFormData.append(
          "location_longitude",
          poi.business_location_longitude
        );
        bodyFormData.append("location_id", poi.business_id);
        bodyFormData.append("location_category", "hotel");

        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.append(
          "user_disability",
          JSON.stringify(
            (this.props.disability && this.props.disability).flat()
          )
        );

        this.props.setRecord(bodyFormData);
      }
    }

    let coord = `${poi.business_location_latitude},${poi.business_location_longitude} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          poiBus: [poi],
          poi: [],
          train: [],
          bus: [],
          pPoi: [],
          dlr: [],
          orangePoi: [],
          greenPoi: [],
          what3words: res.words,
        });
      }
    });
  };

  markerClick = (poi, val) => {
    if (this.props.isAuthenticated) {
      if (sessionStorage.getItem("role") === "user") {
        var bodyFormData = new FormData();
        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("location_latitude", poi.access_lat);
        bodyFormData.append("location_longitude", poi.access_long);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.category);
        bodyFormData.append("location_name", poi.description);
        bodyFormData.set(
          "user_disability",
          JSON.stringify(
            (
              this.props.getProfileSuccess.user_disability &&
              this.props.getProfileSuccess.user_disability
            ).flat()
          )
        );
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.append("interaction_source", "accessibility_info");
      } else {
        var bodyFormData = new FormData();

        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("location_latitude", poi.access_lat);
        bodyFormData.append("location_longitude", poi.access_long);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.category);
        bodyFormData.append("location_name", poi.description);
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.set(
          "user_disability",
          JSON.stringify(this.props.disability && this.props.disability.flat())
        );
        bodyFormData.append("interaction_source", "accessibility_info");

        this.props.setRecord(bodyFormData);
      }
    }

    let coord = `${poi.access_lat},${poi.access_long} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          poi: [poi],
          train: [],
          bus: [],
          pPoi: [],
          dlr: [],
          poiBus: [],
          what3words: res.words,
          greenPoi: [],
          orangePoi: [],
        });
      }
    });
  };

  markerClickOrange = (poi, val) => {
    if (this.props.isAuthenticated) {
      if (sessionStorage.getItem("role") === "user") {
        var bodyFormData = new FormData();
        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("location_latitude", poi.access_lat);
        bodyFormData.append("location_longitude", poi.access_long);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.category);
        bodyFormData.append("location_name", poi.description);
        bodyFormData.set(
          "user_disability",
          JSON.stringify(
            (
              this.props.getProfileSuccess.user_disability &&
              this.props.getProfileSuccess.user_disability
            ).flat()
          )
        );
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.append("interaction_source", "accessibility_info");
      } else {
        var bodyFormData = new FormData();

        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("location_latitude", poi.access_lat);
        bodyFormData.append("location_longitude", poi.access_long);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.category);
        bodyFormData.append("location_name", poi.description);
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.set(
          "user_disability",
          JSON.stringify(this.props.disability && this.props.disability.flat())
        );
        bodyFormData.append("interaction_source", "accessibility_info");

        this.props.setRecord(bodyFormData);
      }
    }

    let coord = `${poi.access_lat},${poi.access_long} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          poi: [],
          train: [],
          bus: [],
          pPoi: [],
          dlr: [],
          poiBus: [],
          orangePoi: [poi],
          greenPoi: [],
          what3words: res.words,
        });
      }
    });
  };

  markerClickGreen = (poi, val) => {
    if (this.props.isAuthenticated) {
      if (sessionStorage.getItem("role") === "user") {
        var bodyFormData = new FormData();
        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("location_latitude", poi.access_lat);
        bodyFormData.append("location_longitude", poi.access_long);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.category);
        bodyFormData.append("location_name", poi.description);
        bodyFormData.set(
          "user_disability",
          JSON.stringify(
            (
              this.props.getProfileSuccess.user_disability &&
              this.props.getProfileSuccess.user_disability
            ).flat()
          )
        );
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.append("interaction_source", "accessibility_info");
      } else {
        var bodyFormData = new FormData();

        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("location_latitude", poi.access_lat);
        bodyFormData.append("location_longitude", poi.access_long);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.category);
        bodyFormData.append("location_name", poi.description);
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.set(
          "user_disability",
          JSON.stringify(this.props.disability && this.props.disability.flat())
        );
        bodyFormData.append("interaction_source", "accessibility_info");

        this.props.setRecord(bodyFormData);
      }
    }

    let coord = `${poi.access_lat},${poi.access_long} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          poi: [],
          train: [],
          bus: [],
          pPoi: [],
          dlr: [],
          poiBus: [],
          orangePoi: [],
          greenPoi: [poi],
          what3words: res.words,
        });
      }
    });
  };

  markerClickPurple = (poi, val) => {
    if (this.props.isAuthenticated) {
      if (sessionStorage.getItem("role") === "user") {
        var bodyFormData = new FormData();
        bodyFormData.set("user_email", sessionStorage.getItem("email"));
        bodyFormData.append("location_latitude", poi.latitude);
        bodyFormData.append("location_longitude", poi.longitude);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.place_category);
        bodyFormData.append("location_name", poi.location_name);
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.append(
          "user_disability",
          JSON.stringify(
            (
              this.props.getProfileSuccess.user_disability &&
              this.props.getProfileSuccess.user_disability
            ).flat()
          )
        );
        bodyFormData.append("interaction_source", "accessibility_info");

        this.props.setRecord(bodyFormData);
      } else {
        var bodyFormData = new FormData();
        bodyFormData.set("user_email", sessionStorage.getItem("email"));

        bodyFormData.append("location_latitude", poi.latitude);
        bodyFormData.append("location_longitude", poi.longitude);
        bodyFormData.append("location_id", poi.place_id);
        bodyFormData.append("location_category", poi.place_category);
        bodyFormData.append("location_name", poi.location_name);
        bodyFormData.append(
          "user_visit_time",
          Math.round(new Date().getTime() / 1000)
        );
        bodyFormData.append(
          "user_disability",
          JSON.stringify(
            (this.props.disability && this.props.disability).flat()
          )
        );
        bodyFormData.append("interaction_source", "accessibility_info");

        this.props.setRecord(bodyFormData);
      }
    }

    let coord = `${poi.latitude},${poi.longitude} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          poi: [],
          train: [],
          bus: [],
          pPoi: [poi],
          validatePopup: true,
          dlr: [],
          poiBus: [],
          what3words: res.words,
        });
      }
    });
  };

  markerTrainClick = (train, val) => {
    let coord = `${train.latitude},${train.longitude} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          train: [train],
          poi: [],
          bus: [],
          pPoi: [],
          dlr: [],
          poiBus: [],
          what3words: res.words,
        });
      }
    });
  };

  markerDLRClick = (dlr, val) => {
    let coord = `${dlr.latitude},${dlr.longitude} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          train: [],
          poi: [],
          bus: [],
          pPoi: [],
          dlr: [dlr],
          poiBus: [],
          what3words: res.words,
        });
      }
    });
  };

  markerBusClick = (bus, val) => {
    let coord = `${bus.latitude},${bus.longitude} `;
    this.props.getCordToWords(coord, (res) => {
      if (!res) {
      } else {
        this.setState({
          bus: [bus],
          poi: [],
          pPoi: [],
          train: [],
          dlr: [],
          poiBus: [],
          what3words: res.words,
        });
      }
    });
  };

  markerClickPin = (value) => {
    console.log(value);
    this.setState({
      dragLat: value.lngLat.lat,
      dragLong: value.lngLat.lng,
      showDragPop: true,
      dragPosition: [value.lngLat.lng, value.lngLat.lat],
      firstDrag: true,
    });
  };

  onChange = (checkedValues) => {
    let dis = [];
    dis.push(checkedValues);
    this.setState({ disability: dis });
    this.props.setDisability(dis);
    sessionStorage.setItem('disabilitiesUnAuth',dis )
  };

  handleEvent = (event, name) => {
    if (this.props.isAuthenticated) {
      let data = {
        platform: window.navigator.platform,
        screen_width: window.screen.width,
        screen_height: window.screen.height,
        client_screen_x: event.clientX,
        client_screen_y: event.clientY,
        interaction_timestamp: Math.round(new Date().getTime() / 1000),
        user_email: sessionStorage.getItem("email"),
        event_type: name,
      };
      this.props.dataCollection(data, (response) => {
        if (!response) {
        } else {
          console.log(response);
        }
      });
    }
  };
  onIssueChange = (checkedValues) => {
    let iss = [];
    iss.push(checkedValues);
    this.setState({ issues: iss });
  };

  onValidatePoi = (event) => {
    this.setState({
      validatePoi: event.target.checked ? event.target.value : "",
    });
  };

  handleConfirm = () => {
    const { dragLong, dragLat, issues } = this.state;

    var data = new FormData();
    data.append("update_mode", "location_accessibility_popup");
    if (this.props.isAuthenticated && this.props.getProfileSuccess.email) {
      data.append("email", this.props.getProfileSuccess.email);
    } else {
      data.append("email", "guest_user");
    }
    data.append("accessibility_category", issues);
    data.append("accessibility_value", issues);
    data.append("latitude", dragLat);
    data.append("longitude", dragLong);
    data.append("location_id", "user_location");
    data.append("location_name", "user_location");
    data.append("feedback_time_unix", Math.round(new Date().getTime() / 1000));
    data.append("source", "user_pin_feedback");

    this.props.gamification(data, (res) => {
      console.log(res);
      if (!res) {
      } else {
        this.setState({ congrats: true, showDragPop: false });
      }
    });

    if (this.props.isAuthenticated) {
      this.props.getProfileGamm((res) => {
        if (!res) {
        } else {
        }
      });
    }
  };

  handleValidate = (poi) => {
    const { validatePoi } = this.state;
    var data = new FormData();
    data.append("update_mode", "location_accessibility_popup");
    if (this.props.isAuthenticated && this.props.getProfileSuccess.email) {
      data.append("email", this.props.getProfileSuccess.email);
    } else {
      data.append("email", "guest_user");
    }
    data.append("accessibility_category", poi.accessibility_category);
    data.append("accessibility_value", validatePoi);
    data.append("latitude", poi.latitude);
    data.append("longitude", poi.longitude);
    data.append("location_id", poi.place_id);
    data.append("location_name", poi.location_name);
    data.append("feedback_time_unix", Math.round(new Date().getTime() / 1000));
    data.append("source", "user_prediction_feedback");

    this.props.gamification(data, (res) => {
      console.log(res);
      if (!res) {
      } else {
        this.setState({
          congrats_validate: true,
          validatePopup: false,
          submit: true,
        });
      }
    });

    if (this.props.isAuthenticated) {
      this.props.getProfileGamm((res) => {
        if (!res) {
        } else {
        }
      });
    }
  };

  handleCongrats = () => {
    const { dragLong, dragLat } = this.state;
    this.props.history.push({
      pathname: "/feedback/poi",
      state: { lat: dragLat, lng: dragLong },
    });
  };
  handleCongratsValidate = () => {
    const { validatePoi, pPoi } = this.state;
    this.props.history.push({
      pathname: "/feedback/poi",
      state: {
        lat: pPoi[0].latitude,
        lng: pPoi[0].longitude,
        cat: pPoi[0].accessibility_category,
        acc_val: validatePoi,
        loc_id: pPoi[0].place_id,
        name: pPoi[0].location_name,
      },
    });
  };

  handleTrainFeedback = (train) => {
    this.props.history.push({
      pathname: "/feedback/train",
      state: {
        lat: train.latitude,
        lng: train.longitude,
        cat: "",
        acc_val: "",
        loc_id: train.source_station_code,
        name: train.station_name,
      },
    });
  };

  handleBusinesPoiFeedback = (poi) => {
    this.props.history.push({
      pathname: "/feedback/poi",
      state: {
        lat: poi.business_location_latitude,
        lng: poi.business_location_longitude,
        cat: "Business",
        acc_val: "",
        loc_id: poi.business_id,
        name: poi.business_name,
      },
    });
  };

  handleDLRFeedback = (d) => {
    this.props.history.push({
      pathname: "/feedback/train",
      state: {
        lat: d.latitude,
        lng: d.longitude,
        cat: "",
        acc_val: "",
        loc_id: d.id,
        name: d.description,
      },
    });
  };
  handleBusFeedback = (bus) => {
    this.props.history.push({
      pathname: "/feedback/bus",
      state: {
        lat: bus.latitude,
        lng: bus.longitude,
        cat: "",
        acc_val: "",
        loc_id: bus.station_code,
        name: bus.station_name,
      },
    });
  };

  handlePoi = (value) => {
    this.props.handleSearch();
    this.props.handleSearchCard();
    let disability = value;
    let res = this.props.poiData;
    let green = [];
    let red = [];
    let orange = [];
    let purple = [];
    this.setState({ autism: false, wheelchair: false });
    if (this.props.selectedTags && this.props.selectedTags.length > 0) {
      if (res && res.categories) {
        disability &&
          disability.map((disPref) => {
            if (Object.values(disPref).indexOf("wheelchair") >= 0) {
              if (
                Object.values(this.props.selectedTags).indexOf(
                  "Accomodation"
                ) >= 0
              ) {
                Object.keys(res.categories.accomodation).map((val) => {
                  if (res.categories.accomodation[val].accessibility_cat) {
                    if (
                      res.categories.accomodation[val].accessibility_cat ===
                        "wheelchair" &&
                      res.categories.accomodation[val].accessibility_value ===
                        true
                    ) {
                      green.push(res.categories.accomodation[val]);
                    } else if (
                      res.categories.accomodation[val].accessibility_cat ===
                        "partial_wheelchair" &&
                      res.categories.accomodation[val].accessibility_value ===
                        true
                    ) {
                      orange.push(res.categories.accomodation[val]);
                    } else {
                      red.push(res.categories.accomodation[val]);
                    }
                  }
                });
              }
              if (
                Object.values(this.props.selectedTags).indexOf("Attractions") >=
                0
              ) {
                Object.keys(res.categories.attraction).map((val) => {
                  if (res.categories.attraction[val].accessibility_cat) {
                    if (
                      res.categories.attraction[val].accessibility_cat ===
                        "wheelchair" &&
                      res.categories.attraction[val].accessibility_value ===
                        true
                    ) {
                      green.push(res.categories.attraction[val]);
                    } else if (
                      res.categories.attraction[val].accessibility_cat ===
                        "partial_wheelchair" &&
                      res.categories.attraction[val].accessibility_value ===
                        true
                    ) {
                      orange.push(res.categories.attraction[val]);
                    } else {
                      red.push(res.categories.attraction[val]);
                    }
                  }
                });
              }
              if (
                Object.values(this.props.selectedTags).indexOf("Shops") >= 0
              ) {
                Object.keys(res.categories.shops).map((val) => {
                  if (res.categories.shops[val].accessibility_cat) {
                    if (
                      res.categories.shops[val].accessibility_cat ===
                        "wheelchair" &&
                      res.categories.shops[val].accessibility_value === true
                    ) {
                      green.push(res.categories.shops[val]);
                    } else if (
                      res.categories.shops[val].accessibility_cat ===
                        "partial_wheelchair" &&
                      res.categories.shops[val].accessibility_value === true
                    ) {
                      orange.push(res.categories.shops[val]);
                    } else {
                      red.push(res.categories.shops[val]);
                    }
                  }
                });
              }

              if (
                Object.values(this.props.selectedTags).indexOf(
                  "Food & drinks"
                ) >= 0
              ) {
                Object.keys(res.categories.food_and_drinks).map((val) => {
                  if (res.categories.food_and_drinks[val].accessibility_cat) {
                    if (
                      res.categories.food_and_drinks[val].accessibility_cat ===
                        "wheelchair" &&
                      res.categories.food_and_drinks[val]
                        .accessibility_value === true
                    ) {
                      green.push(res.categories.food_and_drinks[val]);
                    } else if (
                      res.categories.food_and_drinks[val].accessibility_cat ===
                        "partial_wheelchair" &&
                      res.categories.food_and_drinks[val]
                        .accessibility_value === true
                    ) {
                      orange.push(res.categories.food_and_drinks[val]);
                    } else {
                      red.push(res.categories.food_and_drinks[val]);
                    }
                  }
                });
              }

              if (
                Object.values(this.props.selectedTags).indexOf("Parking") >= 0
              ) {
                Object.keys(res.categories.parking).map((val) => {
                  if (res.categories.parking[val].accessibility_cat) {
                    if (
                      res.categories.parking[val].accessibility_cat ===
                        "wheelchair" &&
                      res.categories.parking[val].accessibility_value === true
                    ) {
                      green.push(res.categories.parking[val]);
                    } else if (
                      res.categories.parking[val].accessibility_cat ===
                        "partial_wheelchair" &&
                      res.categories.parking[val].accessibility_value === true
                    ) {
                      orange.push(res.categories.parking[val]);
                    } else {
                      red.push(res.categories.parking[val]);
                    }
                  }
                });
              }

              this.setState({
                wheelchairG: green,
                wheelchairO: orange,
                wheelchairR: red,
                purplePoi: purple,
                wheelchair: true,
              });
            }
          });
      }
    } else {
      if (res && res.place_infos && res.place_infos.length > 0) {
        disability &&
          disability.map((disPref) => {
            if (Object.values(disPref).indexOf("wheelchair") >= 0) {
              Object.keys(res.place_infos).map((val) => {
                if (res.place_infos[val].accessibility_cat) {
                  if (
                    res.place_infos[val].accessibility_cat === "wheelchair" &&
                    res.place_infos[val].accessibility_value === true
                  ) {
                    green.push(res.place_infos[val]);
                  } else if (
                    res.place_infos[val].accessibility_cat ===
                      "partial_wheelchair" &&
                    res.place_infos[val].accessibility_value === true
                  ) {
                    orange.push(res.place_infos[val]);
                  } else {
                    red.push(res.place_infos[val]);
                  }
                }
              });
              Object.keys(res.historical_preds).map((val) => {
                purple.push(res.historical_preds[val]);
              });
              Object.keys(res.knn_current_preds).map((val) => {
                purple.push(res.knn_current_preds[val]);
              });

              this.setState({
                wheelchairG: green,
                wheelchairO: orange,
                wheelchairR: red,
                purplePoi: purple,
                wheelchair: true,
              });
            }
            if (Object.values(disPref).indexOf("autism") >= 0) {
              Object.keys(res.place_infos).map((val) => {
                if (res.place_infos[val].accessibility_cat) {
                  if (
                    res.place_infos[val].accessibility_cat === "autism" &&
                    res.place_infos[val].accessibility_value === true
                  ) {
                    green.push(res.place_infos[val]);
                  } else if (
                    res.place_infos[val].accessibility_cat ===
                      "partial_autism" &&
                    res.place_infos[val].accessibility_value === true
                  ) {
                    orange.push(res.place_infos[val]);
                  } else {
                    red.push(res.place_infos[val]);
                  }
                }
              });
              Object.keys(res.historical_preds).map((val) => {
                purple.push(res.historical_preds[val]);
              });
              Object.keys(res.knn_current_preds).map((val) => {
                purple.push(res.knn_current_preds[val]);
              });

              this.setState({
                autismG: green,
                autismO: orange,
                autismR: red,
                purplePoi: purple,
                autism: true,
              });
            }
          });
      }
    }
  };

  render() {
    const {
      center,
      zoom,
      dragPosition,
      poi,
      showPoi,
      train,
      bus,
      pPoi,
      dragLong,
      dragLat,
      showDragPop,
      congrats,
      validatePoi,
      dlr,
      validatePopup,
      congrats_validate,
      loader,
      poiBus,
      wheelchairG,
      wheelchairO,
      wheelchairR,
      purplePoi,
      wheelchair,
      submit,
      orangePoi,
      greenPoi,
    } = this.state;
    console.log(pPoi[0]);

    const options = [
      {
        label: <span className="chck-style">Road Work</span>,
        value: "roadWork",
      },
      {
        label: <span className="chck-style">Blocked Pavement</span>,
        value: "blockedPavement",
      },
      {
        label: <span className="chck-style">Accidents</span>,
        value: "accidents",
      },
      {
        label: <span className="chck-style">No Drop Kerb</span>,
        value: "noDropKurb",
      },
      {
        label: <span className="chck-style">Traffic</span>,
        value: "traffic",
      },
    ];

    const validate_options = [
      {
        label: <span className="chck-style">Accessible</span>,
        value: "accessible",
      },
      {
        label: <span className="chck-style">Not Accessible</span>,
        value: "not_accessible",
      },
      {
        label: <span className="chck-style">Partially Accessible</span>,
        value: "partially_accessible",
      },
    ];

    return (
      <div className="map">
        <Container>
          <Map
            style={{
              version: 8,
              name: "Raster Tiles",
              sources: {
                yourTileLayer: {
                  type: "raster",
                  tiles: [
                    "https://a.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    "https://b.tile.openstreetmap.org/{z}/{x}/{y}.png",
                    "https://c.tile.openstreetmap.org/{z}/{x}/{y}.png",
                  ],
                  tileSize: 256,
                },
              },
              layers: [
                {
                  id: "yourTileLayer",
                  type: "raster",
                  source: "yourTileLayer",
                  paint: {
                    "raster-fade-duration": 100,
                  },
                },
              ],
            }}
            containerStyle={{
              height: "550px",
              width: "100%",
            }}
            onStyleLoad={this.onStyleLoad}
            center={center}
            zoom={zoom}
          >
            {this.props.pinClick && (
              <Layer
                type="symbol"
                id="dragPin"
                layout={layoutLayerPin}
                images={[imagesDragPin]}
              >
                <Feature
                  key="a"
                  draggable
                  coordinates={dragPosition}
                  onDragEnd={this.markerClickPin.bind(this)}
                />
              </Layer>
            )}

            {congrats && this.props.isAuthenticated && (
              <Popup coordinates={[dragLong, dragLat]}>
                <StyledDragPopup>
                  <div className="cross">
                    <div style={{ width: "100%" }}>
                      <CloseOutlined
                        style={{
                          color: "#fff",
                          justifyContent: "flex-end",
                          display: "flex",
                          padding: "4px 2px",
                        }}
                        onClick={() => this.setState({ congrats: false })}
                      />
                      <p className="cong-tool">
                        Congratulations <br />
                        <br />
                        <br /> You earned 50 Points!
                        <br />
                        <br />
                      </p>
                      <div className="cong-b-d">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-tooltip"
                          onClick={() => this.handleCongrats()}
                        >
                          Leave Feedback &nbsp;
                          <img src={Coin} className="coin-div" /> 100
                        </Button>
                      </div>
                    </div>
                  </div>
                </StyledDragPopup>
              </Popup>
            )}
            {pPoi.length > 0 &&
              congrats_validate &&
              !validatePopup &&
              this.props.isAuthenticated && (
                <Popup coordinates={[pPoi[0].longitude, pPoi[0].latitude]}>
                  <StyledDragPopup>
                    <div className="cross">
                      <div style={{ width: "100%" }}>
                        <CloseOutlined
                          style={{
                            color: "#fff",
                            justifyContent: "flex-end",
                            display: "flex",
                            padding: "4px 2px",
                          }}
                          onClick={() =>
                            this.setState({ congrats_validate: false })
                          }
                        />
                        <p className="cong-tool">
                          Congratulations <br />
                          <br />
                          <br /> You earned 50 Points! <br />
                          <br />
                        </p>
                        <div className="cong-b-d">
                          <Button
                            type="primary"
                            htmlType="submit"
                            className="button-tooltip"
                            onClick={() => this.handleCongratsValidate()}
                          >
                            Leave Feedback &nbsp;
                            <img src={Coin} className="coin-div" /> 100
                          </Button>
                        </div>
                      </div>
                    </div>
                  </StyledDragPopup>
                </Popup>
              )}

            {showDragPop && (
              <Popup coordinates={[dragLong, dragLat]}>
                <StyledDragPopup>
                  <div className="cross">
                    <div style={{ width: "100%" }}>
                      <CloseOutlined
                        style={{
                          color: "#fff",
                          justifyContent: "flex-end",
                          display: "flex",
                          padding: "4px 2px",
                        }}
                        onClick={() => this.setState({ showDragPop: false })}
                      />
                      <div className="loc-div">
                        <img src={ReportPin} className="loc-pin" /> &nbsp;{" "}
                        <span className="report-loc">
                          Your reported location
                        </span>
                      </div>
                      <Checkbox.Group
                        options={options}
                        defaultValue={["roadWork"]}
                        onChange={this.onIssueChange}
                        className="chck-tool"
                      />
                      <div className="c-b-d">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-tooltip"
                          onClick={() => this.handleConfirm()}
                        >
                          Confirm &nbsp;
                          <img src={Coin} className="coin-div" /> 50
                        </Button>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-tooltip"
                          onClick={() => this.handleCongrats()}
                        >
                          Leave Feedback &nbsp;
                          <img src={Coin} className="coin-div" /> 100
                        </Button>
                      </div>
                    </div>
                  </div>
                </StyledDragPopup>
              </Popup>
            )}

            {this.state.haveUserLocation && (
              <Marker coordinates={center} anchor="bottom">
                <img src={locateIcon} width={50} height={50} />
              </Marker>
            )}

            {wheelchair && (
              <Layer
                type="symbol"
                id="ppoiWP"
                layout={layoutLayerPurple}
                images={[PurpleimagePOI]}
              >
                {Object.keys(purplePoi && purplePoi).map((val, index) => (
                  <Feature
                    key={val}
                    coordinates={[
                      purplePoi[val].longitude,
                      purplePoi[val].latitude,
                    ]}
                    onClick={this.markerClickPurple.bind(this, purplePoi[val])}
                  />
                ))}
              </Layer>
            )}

            {this.props.showRed && wheelchair && (
              <Layer
                type="symbol"
                id="poiWR"
                layout={layoutLayerRed}
                images={[RedimagePOI]}
              >
                {Object.keys(wheelchairR && wheelchairR).map((val, index) => {
                  return (
                    <Feature
                      key={val}
                      coordinates={[
                        wheelchairR[val].access_long,
                        wheelchairR[val].access_lat,
                      ]}
                      onClick={this.markerClick.bind(this, wheelchairR[val])}
                    />
                  );
                })}
              </Layer>
            )}

            {this.props.showGreen && wheelchair && (
              <Layer
                type="symbol"
                id="gpoiWG"
                layout={layoutLayer}
                images={[imagePOI]}
              >
                {Object.keys(wheelchairG && wheelchairG).map((val, index) => (
                  <Feature
                    key={val}
                    coordinates={[
                      wheelchairG[val].access_long,
                      wheelchairG[val].access_lat,
                    ]}
                    onClick={this.markerClickGreen.bind(this, wheelchairG[val])}
                  />
                ))}
              </Layer>
            )}

            {this.props.showOrange && wheelchair && (
              <Layer
                type="symbol"
                id="gpoiWO"
                layout={layoutLayerOrange}
                images={[OrangeimagePOI]}
              >
                {Object.keys(wheelchairO && wheelchairO).map((val, index) => {
                  return (
                    <Feature
                      key={val}
                      coordinates={[
                        wheelchairO[val].access_long,
                        wheelchairO[val].access_lat,
                      ]}
                      onClick={this.markerClickOrange.bind(
                        this,
                        wheelchairO[val]
                      )}
                    />
                  );
                })}
              </Layer>
            )}

            {this.props.isAuthenticated &&
              sessionStorage.getItem("role") === "user" && (
                <Layer
                  type="symbol"
                  id="ppoi"
                  layout={layoutLayerPurple}
                  images={[PurpleimagePOI]}
                >
                  {Object.keys(this.props.PpoiData && this.props.PpoiData).map(
                    (val, index) => (
                      <Feature
                        key={val}
                        coordinates={[
                          this.props.PpoiData[val].longitude,
                          this.props.PpoiData[val].latitude,
                        ]}
                        onClick={this.markerClickPurple.bind(
                          this,
                          this.props.PpoiData[val]
                        )}
                      />
                    )
                  )}
                </Layer>
              )}

            {this.props.showRed &&
              this.props.isAuthenticated &&
              sessionStorage.getItem("role") === "user" && (
                <Layer
                  type="symbol"
                  id="poi"
                  layout={layoutLayerRed}
                  images={[RedimagePOI]}
                >
                  {Object.keys(this.props.RpoiData && this.props.RpoiData).map(
                    (val, index) => (
                      <Feature
                        key={val}
                        coordinates={[
                          this.props.RpoiData[val].access_long,
                          this.props.RpoiData[val].access_lat,
                        ]}
                        onClick={this.markerClick.bind(
                          this,
                          this.props.RpoiData[val]
                        )}
                        // onMouseEnter={this.markerClick.bind(this, this.props.RpoiData[val])}
                        // onMouseLeave={() => this.setState({ poi: [] })}
                      />
                    )
                  )}
                </Layer>
              )}

            {this.props.showGreen &&
              this.props.isAuthenticated &&
              sessionStorage.getItem("role") === "user" && (
                <Layer
                  type="symbol"
                  id="gpoi"
                  layout={layoutLayer}
                  images={[imagePOI]}
                >
                  {Object.keys(this.props.GpoiData && this.props.GpoiData).map(
                    (val, index) => (
                      <Feature
                        key={val}
                        coordinates={[
                          this.props.GpoiData[val].access_long,
                          this.props.GpoiData[val].access_lat,
                        ]}
                        onClick={this.markerClickGreen.bind(
                          this,
                          this.props.GpoiData[val]
                        )}
                        // onMouseEnter={this.markerClick.bind(this, this.props.GpoiData[val])}
                        // onMouseLeave={() => this.setState({ poi: [] })}
                      />
                    )
                  )}
                </Layer>
              )}

            {this.props.showOrange &&
              this.props.isAuthenticated &&
              sessionStorage.getItem("role") === "user" && (
                <Layer
                  type="symbol"
                  id="Opoi"
                  layout={layoutLayerOrange}
                  images={[OrangeimagePOI]}
                >
                  {Object.keys(this.props.OpoiData && this.props.OpoiData).map(
                    (val, index) => (
                      <Feature
                        key={val}
                        coordinates={[
                          this.props.OpoiData[val].access_long,
                          this.props.OpoiData[val].access_lat,
                        ]}
                        onClick={this.markerClickOrange.bind(
                          this,
                          this.props.OpoiData[val]
                        )}
                      />
                    )
                  )}
                </Layer>
              )}

            <Layer
              type="symbol"
              id="Bpoi"
              layout={layoutLayerBusinesss}
              images={[BusinessLayerPOI]}
            >
              {Object.keys(
                this.props.BUSINESSData && this.props.BUSINESSData
              ).map((val, index) => (
                <Feature
                  key={val}
                  coordinates={[
                    this.props.BUSINESSData[val].business_location_longitude,
                    this.props.BUSINESSData[val].business_location_latitude,
                  ]}
                  onClick={this.markerClickBusiness.bind(
                    this,
                    this.props.BUSINESSData[val]
                  )}
                />
              ))}
            </Layer>

            {poi.length > 0 && (
              <Popup coordinates={[poi[0].access_long, poi[0].access_lat]}>
                <StyledPopup>
                  <div className="cross">
                    {/* <div style={{ fontSize: 14 }}>{poi[0].description}</div> */}
                    <CloseOutlined
                      onClick={() => this.setState({ poi: [], what3words: "" })}
                    />
                  </div>
                  <div style={{ textTransform: "capitalize" }}>
                    {poi[0].category === "undefined"
                      ? "Not available"
                      : poi[0].category}
                  </div>
                  <div>What3Words : {this.state.what3words}</div>

                  <div>
                    Wheelchair Accessibility:{" "}
                    {poi[0].accessibility_cat &&
                    poi[0].accessibility_cat === "wheelchair" &&
                    poi[0].accessibility_value === true
                      ? "Yes"
                      : poi[0].accessibility_cat === "partial_wheelchair" &&
                        poi[0].accessibility_value === true
                      ? "Partially"
                      : "No"}
                  </div>
                </StyledPopup>
              </Popup>
            )}

            {orangePoi.length > 0 && (
              <Popup
                coordinates={[
                  orangePoi[0].access_long,
                  orangePoi[0].access_lat,
                ]}
              >
                <StyledPopup>
                  <div className="cross">
                    {/* <div style={{ fontSize: 14 }}>{orangePoi[0].description}</div> */}
                    <CloseOutlined
                      onClick={() =>
                        this.setState({ orangePoi: [], what3words: "" })
                      }
                    />
                  </div>
                  <div style={{ textTransform: "capitalize" }}>
                    {orangePoi[0].category === "undefined"
                      ? "Not available"
                      : orangePoi[0].category}
                  </div>
                  <div>What3Words : {this.state.what3words}</div>

                  <div>
                    Wheelchair Accessibility:{" "}
                    {orangePoi[0].accessibility_cat &&
                    orangePoi[0].accessibility_cat === "wheelchair" &&
                    orangePoi[0].accessibility_value === true
                      ? "Yes"
                      : orangePoi[0].accessibility_cat ===
                          "partial_wheelchair" &&
                        orangePoi[0].accessibility_value === true
                      ? "Partially"
                      : "No"}
                  </div>
                </StyledPopup>
              </Popup>
            )}

            {greenPoi.length > 0 && (
              <Popup
                coordinates={[greenPoi[0].access_long, greenPoi[0].access_lat]}
              >
                <StyledPopup>
                  <div className="cross">
                    {/* <div style={{ fontSize: 14 }}>{greenPoi[0].description}</div> */}
                    <CloseOutlined
                      onClick={() =>
                        this.setState({ greenPoi: [], what3words: "" })
                      }
                    />
                  </div>
                  <div style={{ textTransform: "capitalize" }}>
                    {greenPoi[0].category === "undefined"
                      ? "Not available"
                      : greenPoi[0].category}
                  </div>
                  <div>What3Words : {this.state.what3words}</div>

                  <div>
                    Wheelchair Accessibility:{" "}
                    {greenPoi[0].accessibility_cat &&
                    greenPoi[0].accessibility_cat === "wheelchair" &&
                    greenPoi[0].accessibility_value === true
                      ? "Yes"
                      : greenPoi[0].accessibility_cat ===
                          "partial_wheelchair" &&
                        greenPoi[0].accessibility_value === true
                      ? "Partially"
                      : "No"}
                  </div>
                </StyledPopup>
              </Popup>
            )}

            {poiBus.length > 0 && (
              <Popup
                coordinates={[
                  poiBus[0].business_location_longitude,
                  poiBus[0].business_location_latitude,
                ]}
              >
                <StyledPopup>
                  <div className="cross">
                    <div>
                      Name:{" "}
                      {poiBus[0].business_name === "undefined"
                        ? "Not available"
                        : poiBus[0].business_name}
                    </div>
                    <CloseOutlined
                      onClick={() =>
                        this.setState({ poiBus: [], what3words: "" })
                      }
                    />
                  </div>
                  <div>What3Words : {this.state.what3words}</div>
                  <div>
                    Website:{" "}
                    <Button
                      type="link"
                      onClick={() =>
                        window.open(
                          `https://${poiBus[0].business_website_link}`
                        )
                      }
                    >
                      {poiBus[0].business_website_link}
                    </Button>
                  </div>
                  <div>
                    <br />
                    <b>Accessibility:</b>{" "}
                    {poiBus[0].business_questionaire &&
                      poiBus[0].business_questionaire.accessible_entrance.map(
                        (ae) => (
                          <div className="too-tip">
                            <p className="too-tex">
                              {ae.question.replace(/Do you have/g, "Have")}
                            </p>
                            : <p className="too-res">{ae.response}</p>{" "}
                          </div>
                        )
                      )}
                    {poiBus[0].business_questionaire &&
                      poiBus[0].business_questionaire.accessible_parking.map(
                        (ae) => (
                          <div className="too-tip">
                            <p className="too-tex">
                              {ae.question.replace(/Do you have/g, "Have")}
                            </p>
                            :<p className="too-res">{ae.response}</p>{" "}
                          </div>
                        )
                      )}
                    {poiBus[0].business_questionaire &&
                      poiBus[0].business_questionaire.goods_services_access.map(
                        (ae) => (
                          <div className="too-tip">
                            <p className="too-tex">
                              {ae.question.replace(/Do you have/g, "Have")}
                            </p>
                            :<p className="too-res">{ae.response}</p>{" "}
                          </div>
                        )
                      )}
                    {poiBus[0].business_questionaire &&
                      poiBus[0].business_questionaire.rest_room_usability.map(
                        (ae) => (
                          <div className="too-tip">
                            <p className="too-tex">
                              {ae.question.replace(/Do you have/g, "Have")}
                            </p>
                            :<p className="too-res">{ae.response}</p>{" "}
                          </div>
                        )
                      )}
                  </div>

                  <div className="cong-b-d">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button-tooltip"
                      onClick={() => this.handleBusinesPoiFeedback(poiBus[0])}
                    >
                      Leave Feedback &nbsp;
                      <img src={Coin} className="coin-div" /> 100
                    </Button>
                  </div>
                </StyledPopup>
              </Popup>
            )}

            {pPoi.length > 0 && validatePopup && (
              <Popup coordinates={[pPoi[0].longitude, pPoi[0].latitude]}>
                <StyledDragPopup>
                  <div className="cross">
                    <div style={{ width: "100%" }}>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 0",
                        }}
                      >
                        <div className="loc-div">
                          <img src={ReportPin} className="loc-pin" /> &nbsp;{" "}
                          <span className="report-loc">
                            Validate Our Accessibility info!
                          </span>
                        </div>

                        <CloseOutlined
                          style={{
                            color: "#fff",
                            padding: "2px 12px",
                            marginBottom: 12,
                          }}
                          onClick={() =>
                            this.setState({ pPoi: [], what3words: "" })
                          }
                        />
                      </div>
                      <div style={{ color: "#fff" }}>
                        {" "}
                        <strong>Category: </strong>{" "}
                        {pPoi[0].place_category === "undefined"
                          ? "Not available"
                          : pPoi[0].place_category}
                      </div>
                      <div style={{ color: "#fff" }}>
                        {/* <strong> Description: </strong> {pPoi[0].description} */}
                      </div>
                      <div style={{ color: "#fff" }}>
                        {" "}
                        <strong>What3Words :</strong> {this.state.what3words}
                      </div>

                      <div style={{ color: "#fff" }}>
                        <strong>
                          Assist Prediction (80% accurate on avg):{" "}
                        </strong>{" "}
                        {pPoi[0].accessibility_category &&
                        pPoi[0].accessibility_category === "wheelchair" &&
                        pPoi[0].accessibility_value === "True"
                          ? "Wheelchair Accessible"
                          : pPoi[0].accessibility_category ===
                              "partial_wheelchair" &&
                            pPoi[0].accessibility_value === "True"
                          ? "Partially Wheelchair Accessible"
                          : "Not Wheelchair Accessible"}
                      </div>

                      <Radio.Group
                        options={validate_options}
                        defaultValue={["accessible"]}
                        onChange={this.onValidatePoi}
                        className="chck-tool-ppoi"
                      />
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-tooltip"
                          onClick={() => this.handleValidate(pPoi[0])}
                        >
                          Confirm &nbsp;
                          <img src={Coin} className="coin-div" /> 50
                        </Button>
                      </div>
                    </div>
                  </div>
                </StyledDragPopup>
              </Popup>
            )}

            <Layer
              type="symbol"
              id="train"
              layout={layoutLayerTrain}
              images={[imagesTrain]}
            >
              {Object.keys(this.props.trainData && this.props.trainData).map(
                (val, index) => (
                  <Feature
                    key={val}
                    coordinates={[
                      this.props.trainData[val].longitude,
                      this.props.trainData[val].latitude,
                    ]}
                    onClick={this.markerTrainClick.bind(
                      this,
                      this.props.trainData[val]
                    )}
                    // onMouseEnter={this.markerTrainClick.bind(this, this.props.trainData[val])}
                    // onMouseLeave={() => this.setState({ train: [] })}
                  />
                )
              )}
            </Layer>

            {train.length > 0 && (
              <Popup coordinates={[train[0].longitude, train[0].latitude]}>
                <StyledPopup>
                  <div className="cross">
                    <div>Category: {train[0].station_name}</div>

                    <CloseOutlined
                      onClick={() =>
                        this.setState({ train: [], what3words: "" })
                      }
                    />
                  </div>
                  <div>
                    <div>What3Words : {this.state.what3words}</div>

                    {train[0].facilities.map((fac) => (
                      <div>
                        <div>Type: {fac.accessbility_type}</div>
                        <div style={{ paddingBottom: "6px" }}>
                          Status: {fac.accessibility_status}
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="cong-b-d">
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button-tooltip"
                      onClick={() => this.handleTrainFeedback(train[0])}
                    >
                      Leave Feedback &nbsp;
                      <img src={Coin} className="coin-div" /> 100
                    </Button>
                  </div>
                </StyledPopup>
              </Popup>
            )}

            <Layer
              type="symbol"
              id="dlr"
              layout={layoutLayerTrain}
              images={[imagesTrain]}
            >
              <Feature
                key={this.props.DLRData.id}
                coordinates={[
                  this.props.DLRData.longitude,
                  this.props.DLRData.latitude,
                ]}
                onClick={this.markerDLRClick.bind(this, this.props.DLRData)}
                // onMouseEnter={this.markerDLRClick.bind(this, this.props.DLRData)}
                // onMouseLeave={() => this.setState({ dlr: [] })}
              />
            </Layer>

            {dlr.length > 0 &&
              dlr.map((d) => {
                return (
                  <Popup coordinates={[d.longitude, d.latitude]}>
                    <StyledPopup>
                      <div className="cross">
                        {/* <div>Description: {d.description}</div> */}
                        <CloseOutlined
                          onClick={() =>
                            this.setState({ dlr: [], what3words: "" })
                          }
                        />
                      </div>
                      <div>
                        <div>What3Words : {this.state.what3words}</div>

                        <div>Distance: {d.distance}</div>
                        <div>Ref Station Name: {d.ref_station_name}</div>
                        <div>Public Toilets: {d.public_toilet_exists}</div>
                        <div>
                          Public Toilet Payment: {d.public_toilet_payment_req}
                        </div>
                        <div>Tram link: {d.tram_link_interchange}</div>
                      </div>
                      <div className="cong-b-d">
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="button-tooltip"
                          onClick={() => this.handleDLRFeedback(d)}
                        >
                          Leave Feedback &nbsp;
                          <img src={Coin} className="coin-div" /> 100
                        </Button>
                      </div>
                    </StyledPopup>
                  </Popup>
                );
              })}

            <Layer
              type="symbol"
              id="busMarker"
              layout={layoutBusLayer}
              images={[imagesBus]}
            >
              {Object.keys(this.props.busData && this.props.busData).map(
                (val, index) => (
                  <Feature
                    key={val}
                    coordinates={[
                      this.props.busData[val].longitude,
                      this.props.busData[val].latitude,
                    ]}
                    onClick={this.markerBusClick.bind(
                      this,
                      this.props.busData[val]
                    )}
                    // onMouseEnter={this.markerBusClick.bind(this, this.props.busData[val])}
                    // onMouseLeave={() => this.setState({ bus: [] })}
                  />
                )
              )}
            </Layer>

            {bus.length > 0 && (
              <Popup coordinates={[bus[0].longitude, bus[0].latitude]}>
                <StyledPopup>
                  <div className="cross">
                    <div>Category: {bus[0].station_name}</div>
                    <CloseOutlined
                      onClick={() => this.setState({ bus: [], what3words: "" })}
                    />
                  </div>
                  <div className="cong-b-d">
                    <div>What3Words : {this.state.what3words}</div>

                    <Button
                      type="primary"
                      htmlType="submit"
                      className="button-tooltip"
                      onClick={() => this.handleBusFeedback(bus[0])}
                    >
                      Leave Feedback &nbsp;
                      <img src={Coin} className="coin-div" /> 100
                    </Button>
                  </div>
                </StyledPopup>
              </Popup>
            )}

            <ZoomControl position="bottom-left" />
            {/* <ScaleControl position='top-left' measurement='km' style={{ color: 'black' }} /> */}
          </Map>
          <div>
            <CardInfo {...this.props} />
            {sessionStorage.getItem("role") === "business" && (
              <DisabilityInfo
                disability={this.state.disability}
                poiData={this.props.poiData}
                {...this.props}
                handlePoi={this.handlePoi}
                value={this.props.value}
                selectedTags={this.props.selectedTags}
              />
            )}
            {!this.props.isAuthenticated && (
              <DisabilityInfo
                disability={this.state.disability}
                {...this.props}
                poiData={this.props.poiData}
                handlePoi={this.handlePoi}
                value={this.props.value}
                selectedTags={this.props.selectedTags}
              />
            )}
          </div>
          <Modal
            visible={
              this.props.welcome &&
              !this.props.isAuthenticated &&
              Cookies.get("Welcome") === undefined
            }
            footer={null}
            width="auto"
            centered
            closable={false}
          >
            <div className="welcom-up-div">
              <div className="pic-div">
                <div className="min-div">
                  <img src={accessRate} className="img" />
                </div>
                <p className="pic-text"> Personalised Accessibility Info</p>
              </div>

              <div className="pic-div">
                <div className="min-div">
                  <img src={planner} className="img" />
                </div>
                <p className="pic-text">Journey Planner</p>
              </div>

              <div className="pic-div">
                <div className="min-div">
                  <img src={Aida} className="img2" />
                </div>
                <p className="pic-text pd-20">Voice Assistance</p>
              </div>
            </div>

            <div
              className="button-div"
              onClick={(event) =>
                this.handleEvent(event, "accesibility welcome next button")
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                className="next-button"
                onClick={() => {
                  this.props.noWelcome();
                  this.setState({ welcome: false, navigate: true });
                  Cookies.set("Welcome", "Accept", { expires: 30 });
                }}
              >
                Next
              </Button>
            </div>
          </Modal>

          <Modal
            visible={this.state.navigate && !this.props.isAuthenticated && !sessionStorage.getItem('disabilitiesUnAuth')}
            footer={null}
            width="auto"
            centered
            closable={false}
          >
            <div className="navigate-up-div">
              <h1 className="header-acc"></h1>
              <p className="sub-acc">
                Please select the filter so that we can display personalised
                Information
              </p>
              <div className="form-div">
                <Checkbox.Group onChange={this.onChange}>
                  <Row>
                    <Col
                      span={24}
                      onClick={(event) =>
                        this.handleEvent(event, "wheelchair users checkbox")
                      }
                    >
                      <span className="check-bx-sp">
                        <img
                          src={wheelchairPic}
                          alt=""
                          className="check-bx-img"
                        />
                        &nbsp;&nbsp;
                        <Checkbox value="wheelchair" className="check-bx">
                          Wheelchair Users
                        </Checkbox>
                      </span>
                    </Col>
                    <Col
                      span={24}
                      onClick={(event) =>
                        this.handleEvent(event, "Visually Impaired checkbox")
                      }
                    >
                      <span className="check-bx-sp">
                        <img src={blind} alt="" className="check-bx-img" />
                        &nbsp;&nbsp;
                        <Checkbox disabled value="visual" className="check-bx">
                          Visually Impaired
                        </Checkbox>
                      </span>
                    </Col>
                    <Col
                      span={24}
                      onClick={(event) =>
                        this.handleEvent(event, "Hearing Impaired checkbox")
                      }
                    >
                      <span className="check-bx-sp">
                        <img src={hear} alt="" className="check-bx-img" />
                        &nbsp;&nbsp;
                        <Checkbox disabled value="hear" className="check-bx">
                          Hearing Impaired
                        </Checkbox>
                      </span>
                    </Col>
                    <Col
                      span={24}
                      onClick={(event) =>
                        this.handleEvent(event, "Autism checkbox")
                      }
                    >
                      <span className="check-bx-sp">
                        <img src={brain} alt="" className="check-bx-img" />
                        &nbsp;&nbsp;
                        <Checkbox disabled value="autism" className="check-bx">
                          Autism
                        </Checkbox>
                      </span>
                    </Col>
                    <Col
                      span={24}
                      onClick={(event) =>
                        this.handleEvent(event, "None checkbox")
                      }
                    >
                      <span className="check-bx-sp">
                        <img src={block} alt="" className="check-bx-img" />
                        &nbsp;&nbsp;
                        <Checkbox disabled value="none" className="check-bx">
                          Carer
                        </Checkbox>
                      </span>
                    </Col>
                  </Row>
                </Checkbox.Group>
              </div>
            </div>

            <p className="sub-acc bg-red">
              <span style={{ color: "red" }}> * </span>Please note - Navigation
              is currently limited to London, other cities are coming soon
            </p>
            <div
              className="button-div"
              onClick={(event) =>
                this.handleEvent(event, "accesibility information next button")
              }
            >
              <Button
                type="primary"
                htmlType="submit"
                className="next-button"
                onClick={() => {
                  this.props.disableSearch();
                  this.setState({ navigate: false });
                }}
              >
                Next
              </Button>
            </div>
          </Modal>

          {!this.props.isAuthenticated && (
            <Modal
              visible={submit}
              footer={null}
              width="auto"
              centered
              closable={false}
            >
              <Result
                status="success"
                title="Sign up and earn reward points"
                // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
                extra={[
                  <Button type="primary" key="console" href="/sign-up">
                    Sign Up!
                  </Button>,
                  <Button
                    key="buy"
                    onClick={() => this.setState({ submit: false })}
                  >
                    Cancel
                  </Button>,
                ]}
              />
            </Modal>
          )}
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.email !== null,
    gamificationLoading: state.gamificationReducer.gamificationLoading,
    disability: state.dataCollectionReducer.disability,
    getProfileSuccess: state.profileReducer.getProfileSuccess,
    getProfileBusSuccess: state.profileReducer.getProfileBusSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDisability: (data) => dispatch(actions.setDisability(data)),
    gamification: (data, res) => dispatch(actions.gamification(data, res)),
    getProfileGamm: (res) => dispatch(actions.getProfileGamm(res)),
    setRecord: (data) => dispatch(actions.setRecord(data)),
    getCordToWords: (data, res) => dispatch(actions.getCordToWords(data, res)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ReactMapbox)
);
