export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SIGN_UP_START = 'SIGN_UP_START';
export const SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS';
export const SIGN_UP_FAIL = 'SIGN_UP_FAIL';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAIL = 'FORGOT_PASSWORD_FAIL';


export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const RESET_PROFILE_START = 'RESET_PROFILE_START';
export const RESET_PROFILE_SUCCESS = 'RESET_PROFILE_SUCCESS';
export const RESET_PROFILE_FAIL = 'RESET_PROFILE_FAIL';


//accessibilty

export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_FAILURE = 'GET_LOCATION_FAILURE';
export const GET_LOCATION_LOADING = 'GET_LOCATION_LOADING';

//cordtowords 

export const GET_CORDTOWORDS_SUCCESS = 'GET_CORDTOWORDS_SUCCESS';
export const GET_CORDTOWORDS_FAILURE = 'GET_CORDTOWORDS_FAILURE';
export const GET_CORDTOWORDS_LOADING = 'GET_CORDTOWORDS_LOADING';

export const GET_BUS_SUCCESS = 'GET_BUS_SUCCESS';
export const GET_BUS_FAILURE = 'GET_BUS_FAILURE';
export const GET_BUS_LOADING = 'GET_BUS_LOADING';

export const GET_TRAIN_SUCCESS = 'GET_TRAIN_SUCCESS';
export const GET_TRAIN_FAILURE = 'GET_TRAIN_FAILURE';
export const GET_TRAIN_LOADING = 'GET_TRAIN_LOADING';

export const GET_DLR_SUCCESS = 'GET_DLR_SUCCESS';
export const GET_DLR_FAILURE = 'GET_DLR_FAILURE';
export const GET_DLR_LOADING = 'GET_DLR_LOADING';

export const GET_SEARCH_CAT_SUCCESS = 'GET_SEARCH_CAT_SUCCESS';
export const GET_SEARCH_CAT_FAILURE = 'GET_SEARCH_CAT_FAILURE';
export const GET_SEARCH_CAT_LOADING = 'GET_SEARCH_CAT_LOADING';

export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAILURE = 'GET_BUSINESS_FAILURE';
export const GET_BUSINESS_LOADING = 'GET_BUSINESS_LOADING';

export const ADD_SAVED_SUCCESS = 'ADD_SAVED_SUCCESS';
export const ADD_SAVED_FAILURE = 'ADD_SAVED_FAILURE';
export const ADD_SAVED_LOADING = 'ADD_SAVED_LOADING';

export const ADD_WORKHOME_SUCCESS = 'ADD_WORKHOME_SUCCESS';
export const ADD_WORKHOME_FAILURE = 'ADD_WORKHOME_FAILURE';
export const ADD_WORKHOME_LOADING = 'ADD_WORKHOME_LOADING';

export const ADD_SEARCH_SUCCESS = 'ADD_SEARCH_SUCCESS';
export const ADD_SEARCH_FAILURE = 'ADD_SEARCH_FAILURE';
export const ADD_SEARCH_LOADING = 'ADD_SEARCH_LOADING';

export const SET_RECORD_SUCCESS = 'SET_RECORD_SUCCESS';
export const SET_RECORD_FAILURE = 'SET_RECORD_FAILURE';
export const SET_RECORD_LOADING = 'SET_RECORD_LOADING';



//POI
export const GET_POI_SUCCESS = 'GET_POI_SUCCESS';
export const GET_POI_FAILURE = 'GET_POI_FAILURE';
export const GET_POI_LOADING = 'GET_POI_LOADING';



//Profile

export const PROFILE_START = 'PROFILE_START';
export const PROFILE_SUCCESS = 'PROFILE_SUCCESS';
export const PROFILE_FAIL = 'PROFILE_FAIL';


export const GET_PROFILE_SUCCESS = 'GET_PROFILE_SUCCESS';
export const GET_PROFILE_FAILURE = 'GET_PROFILE_FAILURE';
export const GET_PROFILE_LOADING = 'GET_PROFILE_LOADING';

export const GET_PROFILE_BUS_SUCCESS = 'GET_PROFILE_BUS_SUCCESS';
export const GET_PROFILE_BUS_FAILURE = 'GET_PROFILE_BUS_FAILURE';
export const GET_PROFILE_BUS_LOADING = 'GET_PROFILE_BUS_LOADING';

export const GET_PROFILE_GAMM_SUCCESS = 'GET_PROFILE_GAMM_SUCCESS';
export const GET_PROFILE_GAMM_FAILURE = 'GET_PROFILE_GAMM_FAILURE';
export const GET_PROFILE_GAMM_LOADING = 'GET_PROFILE_GAMM_LOADING';


export const BUS_PROFILE_START = 'BUS_PROFILE_START';
export const BUS_PROFILE_SUCCESS = 'BUS_PROFILE_SUCCESS';
export const BUS_PROFILE_FAIL = 'BUS_PROFILE_FAIL';


//Get TFL routes

export const GET_TFL_SUCCESS = 'GET_TFL_SUCCESS';
export const GET_TFL_FAILURE = 'GET_TFL_FAILURE';
export const GET_TFL_LOADING = 'GET_TFL_LOADING';


//Get Google routes

export const GET_GOOGLE_SUCCESS = 'GET_GOOGLE_SUCCESS';
export const GET_GOOGLE_FAILURE = 'GET_GOOGLE_FAILURE';
export const GET_GOOGLE_LOADING = 'GET_GOOGLE_LOADING';


//
export const WELCOME = 'WELCOME';

export const SAVE_SEARCH = 'SAVE_SEARCH';

export const SAVE_SEARCH_A = 'SAVE_SEARCH_A';
export const SAVE_SEARCH_B = 'SAVE_SEARCH_B';




// data collection

export const DATA_COLLECTION_START = 'DATA_COLLECTION_START';
export const DATA_COLLECTION_SUCCESS = 'DATA_COLLECTION_SUCCESS';
export const DATA_COLLECTION_FAIL = 'DATA_COLLECTION_FAIL';

// Disability

export const SET_DISABILITY = 'SET_DISABILITY';

// gamification
export const GAMIFICATION_START = 'GAMIFICATION_START';
export const GAMIFICATION_SUCCESS = 'GAMIFICATION_SUCCESS';
export const GAMIFICATION_FAIL = 'GAMIFICATION_FAIL';

//Get chart routes

export const GET_CHART_SUCCESS = 'GET_CHART_SUCCESS';
export const GET_CHART_FAILURE = 'GET_CHART_FAILURE';
export const GET_CHART_LOADING = 'GET_CHART_LOADING';

export const GET_GBUS_SUCCESS = 'GET_GBUS_SUCCESS';
export const GET_GBUS_FAILURE = 'GET_GBUS_FAILURE';
export const GET_GBUS_LOADING = 'GET_GBUS_LOADING';

