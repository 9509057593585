import React, { useState, useEffect } from "react";
import { Card, Checkbox } from "antd";
import wheelchair from "../../assets/images/wheelchair.png";
import blind from "../../assets/images/blind.png";
import block from "../../assets/images/block.png";
import brain from "../../assets/images/brain.png";
import hear from "../../assets/images/hear.png";
import { withRouter } from "react-router-dom";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import "./ReactMapbox.css";

const DisabilityInfo = (props) => {
  useEffect(() => {
    props.handlePoi(props.disability);
  }, [props.disability]);

  useEffect(() => {
    props.handlePoi(props.disability);
  }, [props.poiData]);

  useEffect(() => {
    props.handlePoi(props.disability);
  }, [props.selectedTags]);

  const onChange = (checkedValues) => {
    let dis = [];
    dis.push(checkedValues);
    props.setDisability(dis);
    props.handlePoi(dis);
  };
  let show = "";
  if (props.searchCard && props.accessInfo && window.screen.width < 600) {
    return (
      <Card className="card-disability">
        <div>
          <Checkbox.Group
            onChange={onChange}
            value={
              sessionStorage.getItem("disabilitiesUnAuth")
                ? sessionStorage.getItem("disabilitiesUnAuth")
                : props.disability && props.disability[0]
            }
          >
            <span className="check-bx-sp">
              <img src={wheelchair} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox value="wheelchair" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={blind} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="visual" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={hear} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="hear" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={brain} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="autism" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={block} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="none" className="check-bx"></Checkbox>
            </span>
          </Checkbox.Group>
        </div>
      </Card>
    );
  } else if (props.searchCard && window.screen.width > 600) {
    return (
      <Card className="card-disability">
        <div>
          <Checkbox.Group
            onChange={onChange}
            value={
              sessionStorage.getItem("disabilitiesUnAuth")
                ? sessionStorage.getItem("disabilitiesUnAuth")
                : props.disability && props.disability[0]
            }
          >
            <span className="check-bx-sp">
              <img src={wheelchair} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox value="wheelchair" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={blind} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="visual" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={hear} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="hear" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={brain} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="autism" className="check-bx"></Checkbox>
            </span>
            <span className="check-bx-sp">
              <img src={block} alt="" className="check-bx-img" />
              &nbsp;&nbsp;
              <Checkbox disabled value="none" className="check-bx"></Checkbox>
            </span>
          </Checkbox.Group>
        </div>
      </Card>
    );
  }
  return show;
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.authReducer.email !== null,
    gamificationLoading: state.gamificationReducer.gamificationLoading,
    disability: state.dataCollectionReducer.disability,
    getProfileSuccess: state.profileReducer.getProfileSuccess,
    getProfileBusSuccess: state.profileReducer.getProfileBusSuccess,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setDisability: (data) => dispatch(actions.setDisability(data)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(DisabilityInfo)
);
