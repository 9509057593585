import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Card,
  Avatar,
  Form,
  Input,
  Button,
  Spin,
  Rate,
  Upload,
  Modal,
  Result,
} from "antd";
import {
  PictureOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "react-phone-input-2/lib/style.css";
import * as actions from "../../store/actions/index";
import { connect } from "react-redux";
import Notification from "../Resusable/Notification/index";
import { withRouter } from "react-router-dom";
import { Select } from "antd";
import { GApageView } from "../../utils/index";

import "./gamification.css";

var FormData = require("form-data");
const { Option } = Select;
const desc = ["terrible", "bad", "normal", "good", "wonderful"];
const { TextArea } = Input;
const POI = (props) => {
  const [phoneInput, setPhoneInput] = useState("");
  const [emerConInput, setEmerCon] = useState("");
  const [data, setData] = useState([]);
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [age, setAge] = useState("");
  const [dis, setDis] = useState([]);
  const [password, setpassword] = useState("");
  const [name, setname] = useState("");
  const [updateLoading, setUpdateLoading] = useState(false);
  //search
  const [value, setValue] = useState([]);
  const [fetching, setFetching] = useState(false);
  const [search, setSearch] = useState(false);
  const [rating, setRating] = useState(3);
  const [poiName, setPOIname] = useState("");
  const [previewVisible, setpreviewVisible] = useState(false);
  const [previewImage, setpreviewImage] = useState("");
  const [previewTitle, setpreviewTitle] = useState("");
  const [fileList, setfileList] = useState([]);
  const [textInput, setTextInput] = useState("");
  const [Lng, setLng] = useState("");
  const [Lat, setLat] = useState("");
  const [Loc_id, setLocId] = useState("");
  const [accessibility_category, setAccessibility_category] = useState("");
  const [accessibility_value, setAccessibility_value] = useState("");

  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    GApageView(props.location.pathname);

    if (props.location.state) {
      setLat(props.location.state.lat);
      setLng(props.location.state.lng);
      if (props.location.state.cat) {
        setAccessibility_category(props.location.state.cat);
      }
      if (props.location.state.acc_val) {
        setAccessibility_value(props.location.state.acc_val);
      }
      if (props.location.state.loc_id) {
        setLocId(props.location.state.loc_id);
      }
      if (props.location.state.name) {
        setPOIname(props.location.state.name);
      }
    }
  }, []);

  const formItemLayout = {
    // labelCol: { span: 24 , offset: 4 },
    wrapperCol: { span: 20, offset: 0 },
  };
  const tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0,
      },
      sm: {
        span: 20,
        offset: 10,
      },
    },
  };

  const onChange = (name, event) => {
    if (name === "textInput") {
      setTextInput(event.target.value);
    }
  };

  const checkHandler = (checkedValue) => {
    let disability = [];
    disability.push(checkedValue);
    setDis(disability);
  };

  const [form] = Form.useForm();

  //search
  const fetchUser = (value) => {
    setValue(value);
    setData([]);
    setFetching(true);

    props.getLocation(value, (res) => {
      if (!res) {
        setData([]);
        setFetching(false);
        setSearch(false);
      } else {
        const results = [];
        res.map((val) => {
          results.push({
            loc: val.center,
            title: val.place_name,
          });
        });
        setData(results);
        setFetching(false);
        setSearch(false);
      }
    });
  };
  const changeRating = (value) => {
    setRating(value);
  };

  const handleChange = (value) => {
    setSearch(true);

    setData([]);
    setFetching(false);

    setPOIname(value.label);
    let lat_lng = value && value.key.split(",");
    setLat(lat_lng[1]);
    setLng(lat_lng[0]);
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => {
    setpreviewVisible(false);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setpreviewImage(file.url || file.preview);
    setpreviewVisible(true);
    setpreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };

  const handleChangeImage = ({ fileList }) => {
    setfileList(fileList);
  };

  const getBinaryArray = (fileList) => {
    var binary_array = [];
    fileList.map(async (file) => {
      var data = await getBase64(file.originFileObj);
      binary_array.push(atob(data.split(",")[1]));
    });
    return binary_array;
  };

  const handleProfile = (values) => {
    console.log("Received values of form: ", values);

    var data = new FormData();
    data.append("update_mode", "location_accessibility_feedback");

    if (
      props.isAuthenticated &&
      sessionStorage.getItem("email") !== undefined
    ) {
      data.append("email", sessionStorage.getItem("email"));
    } else {
      data.append("email", "guest_user");
    }
    data.append("accessibility_category", accessibility_category);
    data.append("accessibility_value", accessibility_value);
    data.append("latitude", Lat);
    data.append("longitude", Lng);
    data.append("location_id", Loc_id);
    data.append("location_name", poiName);
    data.append("feedback_time_unix", Math.floor(Date.now() / 1000));
    data.append("source", "source_mapbox");
    data.append("feedback_text", textInput);
    data.append("feedback_rating", rating);
    fileList.map((file) => {
      data.append("file", file.originFileObj);
    });

    console.log(data);
    props.gamification(data, (res) => {
      if (!res) {
      } else {
        if (!props.isAuthenticated) {
          setSubmit(true);
        } else {
          Notification("success", "Congrats You earned 100 points !!");
        }
      }
    });
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className="ant-upload-text">Upload</div>
    </div>
  );

  return (
    <>
      <PageHeader className="Pageheader-prf" title={`Feedback POIs`} ghost />

      <Card className="card-style-prof">
        {loading ? (
          <div className="spining-pr">
            <Spin size="large" />
          </div>
        ) : (
          <div>
            <span>
              <strong>Search POI</strong>
            </span>
            <br />
            <Select
              showSearch
              labelInValue
              value={value}
              placeholder="Select exact poi location"
              notFoundContent={fetching ? <Spin size="small" /> : null}
              showArrow={false}
              filterOption={false}
              onSearch={fetchUser}
              onSelect={handleChange}
              //onChange={onChangeA}
              //onClick={() => setAutoSearchA(false)}
              className="search"
            >
              {data.map((d) => (
                <Option key={d.loc}>{d.title}</Option>
              ))}
            </Select>
            <Form
              form={form}
              name="validate_other"
              labelAlign="left"
              layout="vertical"
            >
              <div className="area-pic">
                <p>
                  {" "}
                  <strong>{poiName}</strong>
                </p>
                <div className="div_inline">
                  <Avatar
                    size={100}
                    shape="square"
                    icon={<PictureOutlined />}
                  />
                </div>

                <div className="div_inline">
                  <p className="rating">
                    {" "}
                    <strong>Accessibility Rating</strong>
                  </p>
                  <Rate
                    className="rating"
                    tooltips={desc}
                    onChange={changeRating}
                    value={rating}
                  />
                  {value ? (
                    <span className="ant-rate-text">{desc[value - 1]}</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <Form.Item name="name_of_user" label="Provide More Text Feedback">
                <TextArea
                  rows={4}
                  onChange={(event) => onChange("textInput", event)}
                />
              </Form.Item>
              <span>
                <strong>Upload Photos</strong>
              </span>

              <div className="clearfix">
                <Upload
                  action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                  listType="picture-card"
                  fileList={fileList}
                  onPreview={handlePreview}
                  onChange={handleChangeImage}
                >
                  {fileList.length >= 8 ? null : uploadButton}
                </Upload>
                <Modal
                  visible={previewVisible}
                  title={previewTitle}
                  footer={null}
                  onCancel={handleCancel}
                >
                  <img
                    alt="example"
                    style={{ width: "100%" }}
                    src={previewImage}
                  />
                </Modal>
              </div>

              <Form.Item {...tailFormItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="button-primary width-200"
                  loading={updateLoading}
                  onClick={() => handleProfile()}
                >
                  Submit feedback
                </Button>
              </Form.Item>
            </Form>
          </div>
        )}

        {!props.isAuthenticated && (
          <Modal
            visible={submit}
            footer={null}
            width="auto"
            centered
            closable={false}
          >
            <Result
              status="success"
              title="Sign up and earn reward points"
              // subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
              extra={[
                <Button type="primary" key="console" href="/sign-up">
                  Sign Up!
                </Button>,
                <Button onClick={() => setSubmit(false)}>Cancel</Button>,
              ]}
            />
          </Modal>
        )}
      </Card>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    getProfileLoading: state.profileReducer.getProfileLoading,
    getProfileSuccess: state.profileReducer.getProfileSuccess,
    profileLoading: state.profileReducer.profileLoading,
    gamificationLoading: state.gamificationReducer.gamificationLoading,
    getProfileSuccess: state.profileReducer.getProfileSuccess,
    isAuthenticated: state.authReducer.email !== null,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getProfile: (res) => dispatch(actions.getProfile(res)),
    profile: (data, res) => dispatch(actions.profile(data, res)),
    getLocation: (data, res) => dispatch(actions.getLocation(data, res)),
    gamification: (data, res) => dispatch(actions.gamification(data, res)),
  };
};
//export default connect(mapStateToProps, mapDispatchToProps)(Gamification)
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(POI));
