import React, { useState, useEffect } from 'react';
import { VerifiedOutlined, FieldBinaryOutlined, RiseOutlined } from '@ant-design/icons';





import './card.css';
const ColoredCard = (props) => {

    // useEffect(() => {

    //     if (props.level && props.level === 'Unsullied') {
    //         setImgSrc(unsullied)
    //     } else if (props.level && props.data === 'Nights Watch') {
    //         setImgSrc(nightswatch)

    //     }
    // })


    return (

        <div className='isoStickerWidget' style={{ backgroundColor: props.color }}>
            <div className='isoIconWrapper'>
                {props.icon === 'badge' && <VerifiedOutlined className='icon-wrapper' />}
                {props.icon === 'points' && <FieldBinaryOutlined className='icon-wrapper' />}
                {props.icon === 'level' && <RiseOutlined className='icon-wrapper' />}

            </div>
            <div className='isoContentWrapper'>
                <h3 className='isoStatNumber'>{props.header}</h3>
                <span className='isoLabel'>{props.data}</span>
            </div>
        </div>
    )
}


export default ColoredCard;