import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SpeechRecognition from "react-speech-recognition";
import {
  PageHeader,
  Card,
  Avatar,
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
} from 'antd';
import LogoAida from '../../assets/images/LogoAida.svg';
import Aida from '../../assets/images/AIDA-Logo.gif';
import listening from '../../assets/images/listening.gif';
import Xtrain from '../../assets/images/Xtrain.png';
import Xlocate from '../../assets/images/Xlocate.png';
import './voiceover.css'
const propTypes = {
  // Props injected by SpeechRecognition
  transcript: PropTypes.string,
  resetTranscript: PropTypes.func,
  browserSupportsSpeechRecognition: PropTypes.bool,
  stopListening: PropTypes.func,
  startListening: PropTypes.func,
  setTransript: PropTypes.func,
  aida: PropTypes.bool,
  results: PropTypes.bool,
  data: PropTypes.array,
  finding: PropTypes.func,
  showShowing: PropTypes.bool,
  first: PropTypes.bool,
  noResult: PropTypes.bool,
  handleAida: PropTypes.func,
};

const options = {
  autoStart: false,
  // continuous: false
}

const Voiceover = ({
  transcript,
  resetTranscript,
  setTransript,
  browserSupportsSpeechRecognition,
  stopListening,
  startListening,
  aida,
  results,
  data,
  finding,
  showShowing,
  first,
  noResult,
  handleAida,
}) => {

  useEffect(() => {
    startListening();
    let data = ''
    const timer = setTimeout(() => {
      stopListening();
      data = transcript
      if(first){
      search(data)

      } else if (results){
      searchLocation(data)

      }
    }, 6000);
    return () => {
      clearTimeout(timer);

    }
  }, [first,transcript])


  const search = () => {
    if (transcript.length > 0) {
      setTransript(transcript)
    }
    resetTranscript();

  }


  const searchLocation = () => {
    if (transcript.length > 0) {
      finding(transcript)
    }
    resetTranscript();


  }
  if (!browserSupportsSpeechRecognition) {
    return <p>Support not available</p>;
  }

  let aidaScreen = '';
  if (first) {
    aidaScreen = (<Card className='card-aida'>
      <div className='aida-lg'>
        {/* <img src={Aida} height={40} /> */}
      </div>
      <div className='aida-lg'>
        <p className='aida-text'>Intelligent Assistant</p>
      </div>
      <div className='aida-lg'>
        <img src={listening} height={50} />
      </div>
      <div className='aida-lg'>
        <p className='aida-text'>Tell me where you would like to go</p>
      </div>
      <div className='text'>{transcript ? <span>{transcript}</span> : <span> listening...</span>}</div>
    </Card>)
  } else if (results) {
    aidaScreen = (<Card className='card-aida'>
      <div className='aida-lg'>
        <img src={Aida} height={40} />
      </div>
      <div className='aida-lg'>
        <p className='aida-text'>Intelligent Assistant</p>
      </div>

      <p className='aida-text'>Please tell me which number is correct. By saying "Number ..."</p>

      <div className='res-scr'>
        {data && data.map((val, index) => {

          return <div className='img-text-re'>
            <div className='img-d'><img src={val.title.includes('Railway') || val.title.includes('Station') ? Xtrain : Xlocate} width={20} /></div>
            <p className='txt-d' key={val.loc}>{index + 1}. {val.title}</p>
          </div>
        })}
      </div>

      <div className='text'>{transcript ? <span>{transcript}</span> : <span> listening...</span>}</div>

    </Card>)
  } else if (showShowing) {
    aidaScreen = (<Card className='card-aida'>
      <div className='aida-lg'>
        <img src={Aida} height={40} />
      </div>
      <div className='aida-lg'>
        <p className='aida-text'>Intelligent Assistant</p>
      </div>
      <div className='aida-lg'>
        <img src={listening} height={50} />
      </div>
      <div className='aida-lg'>
         <p className='aida-text'>Showing results</p>
      </div>
    </Card>)
  }else if (noResult) {
    aidaScreen = (<Card className='card-aida'>
      <div className='aida-lg'>
        <img src={Aida} height={40} />
      </div>
      <div className='aida-lg'>
        <p className='aida-text'>Intelligent Assistant</p>
      </div>
      <div className='aida-lg'>
        <img src={listening} height={50} />
      </div>
      <div className='aida-lg'>
      <p className='aida-text'>No results found</p> 
      </div>
    </Card>)
  }
  return (
    <div>
      {aidaScreen}
    </div>
  );
};

Voiceover.propTypes = propTypes;

export default SpeechRecognition(options)(Voiceover);