import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {

    profileLoading: false,
    profile: null,
    profileError: false,

    getProfileLoading: false,
    getProfileSuccess: null,
    getProfileFailure: null,

    getProfileBusLoading: false,
    getProfileBusSuccess: null,
    getProfileBusFailure: null,


    getProfileGammLoading: false,
    getProfileGammSuccess: null,
    getProfileGammFailure: null,

    getChartLoading: false,
    getChartSuccess: null,
    getChartFailure: null,

    busProfileLoading: false,
    busProfile: null,
    busProfileError: false,

    getGBusLoading: false,
    getGBusSuccess: null,
    getGBusFailure: null,


}

//signup
const profileStart = (state, action) => {
    return updateObject(state, { profileError: false, profileLoading: true });

}

const profileSuccess = (state, action) => {

    return updateObject(state, { profile: action.data, profileLoading: false });

}

const profileFail = (state, action) => {
    return updateObject(state, { profileError: action.error });

}

//bus uopfayya

const busProfileStart = (state, action) => {
    return updateObject(state, { busProfileError: false, busProfileLoading: true });

}

const busProfileSuccess = (state, action) => {

    return updateObject(state, { busProfile: action.data, busProfileLoading: false });

}

const busProfileFail = (state, action) => {
    return updateObject(state, { busProfileError: action.error });

}


const getProfileFailure = (state, action) => {
    return updateObject(state, { getProfileFailure: action.data, getProfileLoading: false });

}
const getProfileSuccess = (state, action) => {
    return updateObject(state, { getProfileSuccess: action.data });

}

const getProfileLoading = (state, action) => {
    return updateObject(state, { getProfileLoading: action.data });

}

const getProfileBusFailure = (state, action) => {
    return updateObject(state, { getProfileBusFailure: action.data, getProfileBusLoading: false });

}
const getProfileBusSuccess = (state, action) => {
    return updateObject(state, { getProfileBusSuccess: action.data });

}

const getProfileBusLoading = (state, action) => {
    return updateObject(state, { getProfileBusLoading: action.data });

}


const getProfileGammFailure = (state, action) => {
    return updateObject(state, { getProfileGammFailure: action.data, getProfileGammLoading: false });

}
const getProfileGammSuccess = (state, action) => {
    return updateObject(state, { getProfileGammSuccess: action.data });

}

const getProfileGammLoading = (state, action) => {
    return updateObject(state, { getProfileGammLoading: action.data });

}


//chart

const getChartFailure = (state, action) => {
    return updateObject(state, { getChartFailure: action.data, getChartLoading: false });

}
const getChartSuccess = (state, action) => {
    return updateObject(state, { getChartSuccess: action.data });

}

const getChartLoading = (state, action) => {
    return updateObject(state, { getChartLoading: action.data });

}
//chart

const getGBusFailure = (state, action) => {
    return updateObject(state, { getGBusFailure: action.data, getGBusLoading: false });

}
const getGBusSuccess = (state, action) => {
    return updateObject(state, { getGBusSuccess: action.data });

}

const getGBusLoading = (state, action) => {
    return updateObject(state, { getGBusLoading: action.data });

}
const reducer = (state = initialState, action) => {
    switch (action.type) {

        case actionTypes.PROFILE_START:
            return profileStart(state, action)
        case actionTypes.PROFILE_SUCCESS:
            return profileSuccess(state, action)
        case actionTypes.PROFILE_FAIL:
            return profileFail(state, action)

        case actionTypes.GET_PROFILE_FAILURE:
            return getProfileFailure(state, action)
        case actionTypes.GET_PROFILE_SUCCESS:
            return getProfileSuccess(state, action)
        case actionTypes.GET_PROFILE_LOADING:
            return getProfileLoading(state, action)

        case actionTypes.GET_PROFILE_BUS_FAILURE:
            return getProfileBusFailure(state, action)
        case actionTypes.GET_PROFILE_BUS_SUCCESS:
            return getProfileBusSuccess(state, action)
        case actionTypes.GET_PROFILE_BUS_LOADING:
            return getProfileBusLoading(state, action)

        case actionTypes.GET_PROFILE_GAMM_FAILURE:
            return getProfileGammFailure(state, action)
        case actionTypes.GET_PROFILE_GAMM_SUCCESS:
            return getProfileGammSuccess(state, action)
        case actionTypes.GET_PROFILE_GAMM_LOADING:
            return getProfileGammLoading(state, action)

        case actionTypes.GET_CHART_FAILURE:
            return getChartFailure(state, action)
        case actionTypes.GET_CHART_SUCCESS:
            return getChartSuccess(state, action)
        case actionTypes.GET_CHART_LOADING:
            return getChartLoading(state, action)

        case actionTypes.BUS_PROFILE_START:
            return busProfileStart(state, action)
        case actionTypes.BUS_PROFILE_SUCCESS:
            return busProfileSuccess(state, action)
        case actionTypes.BUS_PROFILE_FAIL:
            return busProfileFail(state, action)

        case actionTypes.GET_GBUS_FAILURE:
            return getGBusFailure(state, action)
        case actionTypes.GET_GBUS_SUCCESS:
            return getGBusSuccess(state, action)
        case actionTypes.GET_GBUS_LOADING:
            return getGBusLoading(state, action)
        default:
            return state;
    }
}

export default reducer;