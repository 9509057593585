import React from 'react';
import {
    Card,
} from 'antd';
import {withRouter } from 'react-router-dom';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';
import './navigation.css';

const NavCardInfo = (props) => {
   

const routeInfo = props.routeInfo;
const routeType = props.routeType;
const train_arr = ['dlr','national-rail','overground','tflrail']
const google_train_arr = ['RAIL','METRO_RAIL','MONORAIL' , 'HEAVY_RAIL','COMMUTER_TRAIN','SPEED_TRAIN']
const google_bus_arr= ['BUS','INTERCITY_BUS','TROLLEYBUS']


    return (

        <Card className='card-leaflet'> 
        <div className='cross'>
        <CloseOutlined onClick={() => props.close()} />
        </div>
        {"   "}
        { routeType=="GOOGLE" && routeInfo && routeInfo.map(step=>{
            if (step.travel_mode === "WALKING") {
                return  <div className='walking'>
                            <div>
                                <p>{step.instructions}</p>
                                <p><strong>Duration:</strong> {step.duration.text}</p> 
                            </div>
                        </div>
                }
            else if (google_bus_arr.indexOf(step.transit.line.vehicle.type)>-1) {
                return  <div className='bus_line'>
                            <div>
                                <p>{step.instructions}</p> 
                                <p><strong>Dept Time:</strong> {step.transit.departure_time.text}</p>
                                <p><strong>Arrival Time:</strong> {step.transit.arrival_time.text}</p>  
                            </div>
                        </div>
                }
            else if (google_train_arr.indexOf(step.transit.line.vehicle.type)>-1) {
                return  <div className='train_line'>
                            <div>
                                <p>{step.instructions}</p> 
                                <p><strong>Dept Time:</strong> {step.transit.departure_time.text}</p>
                                <p><strong>Arrival Time:</strong> {step.transit.arrival_time.text}</p>  
                            </div>
                        </div>
                }
            else if (step.transit.line.vehicle.type=='SUBWAY') {
                return  <div className='tube_line'>
                            <div>
                                <p>{step.instructions}</p> 
                                <p><strong>Dept Time:</strong> {step.transit.departure_time.text}</p>
                                <p><strong>Arrival Time:</strong> {step.transit.arrival_time.text}</p>  
                            </div>
                        </div>
                }
            else if (step.transit.line.vehicle.type=='TRAM') {
                return  <div className='tram_line'>
                            <div>
                                 <p>{step.instructions}</p> 
                                 <p><strong>Dept Time:</strong> {step.transit.departure_time.text}</p>
                                 <p><strong>Arrival Time:</strong> {step.transit.arrival_time.text}</p>  
                            </div>
                        </div>
                    }
            else {
                        return  <div className='others'>
                                    <div>
                                         <p>{step.instructions}</p> 
                                         <p><strong>Dept Time:</strong> {step.departure_time.text}</p>
                                         <p><strong>Arrival Time:</strong> {step.arrival_time.text}</p>  
                                    </div>
                                </div>
                            }
            
                    

        })
}

         

        { routeType=="TFL" && routeInfo && routeInfo.map(leg=>{
            if (leg.mode.name === "walking") {
                return  <div className='walking'>
                            <div>
                                <p>{leg.instruction.summary}</p> 
                                <p><strong>Dept Time:</strong> {leg.departureTime.split("T")[1]} </p> 
                                <p><strong>Arrival Time:</strong> {leg.arrivalTime.split("T")[1]} </p>  
                            </div>
                            
                        </div>
                }
            else if (leg.mode.name === "bus") {
                return  <div className='bus_line'>
                            <div>
                                <p>{leg.instruction.summary}</p> 
                                <p><strong>Dept Time:</strong> {leg.departureTime.split("T")[1]}</p>
                                <p><strong>Arrival Time:</strong> {leg.arrivalTime.split("T")[1]}</p>  
                            </div>
                        </div>
                }
            else if (leg.mode.name === "tram") {
                return  <div className='tram_line'>
                            <div>
                                <p>{leg.instruction.summary}</p> 
                                <p><strong>Dept Time:</strong> {leg.departureTime.split("T")[1]}</p>
                                <p><strong>Arrival Time:</strong> {leg.arrivalTime.split("T")[1]}</p>   
                            </div>
                        </div>
                }
            else if (leg.mode.name === "tube") {
                return  <div className='tube_line'>
                            <div>
                                <p>{leg.instruction.summary}</p> 
                                <p><strong>Dept Time:</strong> {leg.departureTime.split("T")[1]}</p>
                                <p><strong>Arrival Time:</strong> {leg.arrivalTime.split("T")[1]}</p>   
                            </div>
                        </div>
                }
            else if (train_arr.indexOf(leg.mode.name)>-1) {
                return  <div className='train_line'>
                            <div>
                                 <p>{leg.instruction.summary}</p> 
                                 <p><strong>Dept Time:</strong> {leg.departureTime.split("T")[1]}</p> 
                                 <p><strong>Arrival Time:</strong> {leg.arrivalTime.split("T")[1]}</p>  
                            </div>
                        </div>
                    }

            else {
                return  <div className='others'>
                            <div>
                                 <p>{leg.instruction.summary}</p> 
                                 <p><strong>Dept Time:</strong> {leg.departureTime.split("T")[1]}</p> 
                                 <p><strong>Arrival Time:</strong> {leg.arrivalTime.split("T")[1]}</p>  
                            </div>
                        </div>
                    

        }
    })
}
      
        </Card>
    )
}

const mapStateToProps = state => {
    return {
        addSavedLoading: state.accessibilityReducer.addSavedLoading,
        addSavedSuccess: state.accessibilityReducer.addSavedSuccess,
        addWorkHomeLoading: state.accessibilityReducer.addWorkHomeLoading,
        addWorkHomeSuccess: state.accessibilityReducer.addWorkHomeSuccess,
    }
}
const mapDispatchToProps = dispatch => {
    return {
        addSaved: (data, res) => dispatch(actions.addSaved(data, res)),
        addWorkHome: (data, res) => dispatch(actions.addWorkHome(data, res)),


    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavCardInfo))