import  { combineReducers} from 'redux';
import authReducer from './auth';
import accessibilityReducer from './accessibility';
import profileReducer from './profile'
import navigationReducer from './navigation';
import dataCollectionReducer from './dataCollection';
import gamificationReducer from './gamification';


const rootReducer = combineReducers({
    authReducer,
    accessibilityReducer,
    profileReducer,
    navigationReducer,
    dataCollectionReducer,
    gamificationReducer
})

export default rootReducer